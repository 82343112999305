import React, { useState } from "react";
import { SlArrowUp, SlArrowDown } from "react-icons/sl";

const Accordion = ({ title, children, opened=false }) => {
  const [isOpen, setIsOpen] = useState(opened);

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="border-t-0 border-b-2 border-gray-300">
      <div
        className={`flex justify-between items-center cursor-pointer  ${
          isOpen ? "p-4 transition-p duration-300 ease-in-out" : "p-4"
        } `}
        onClick={toggleAccordion}
      >
        <h2 className="text-[16px] font-normal">{title}</h2>
        <span className="p-2 text-xs">
          {isOpen ? <SlArrowUp /> : <SlArrowDown />}
        </span>
      </div>
      <div
        className={`ml-4 flex flex-col justify-start items-start gap-2 text-[13px]  transition-max-h transition-p transition-mb duration-300 ease-in-out ${
          isOpen ? " max-h-screen opacity-100 mb-4 visible" : "max-h-0 opacity-0 invisible"
        }`}
      >
        {children}
      </div>
    </div>
  );
};

export default Accordion;
