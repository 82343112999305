import _ from "lodash";
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  doc: null,
  zoomLevel: 1,
  movingElement: {},
  activeIndex: null,
  elementToAdd: null,
  modalSignType: null,
  builderStatus: "edit", // edit, view, fill
  activeRecipient: null
};

export const signSlice = createSlice({
  name: "sign",
  initialState,
  reducers: {
    setDocument: (state, action) => {
      state.doc = action.payload;
    },
    updateElements: (state, action) => {
      state.doc.elements = action.payload;
    },
    setElements: (state, action) => {
      const { index, element } = action.payload;
      let elements = _.cloneDeep(state.doc.elements);
      if(!element) elements.splice(index, 1);
      else {
        if(index < 0) elements.push(element)
        else elements[index] = element
      }
      state.doc.elements = elements
    },
    setMovingElement: (state, action) => {
      let newMovingElement = _.cloneDeep(state.movingElement);
      if(!action.payload) state.movingElement = {};
      else {
        state.movingElement = {
          ...newMovingElement,
          ...action.payload
        }
      }
    },
    setZoomLevel: (state, action) => {
      state.zoomLevel = action.payload
    },
    setElementResponses: (state, action) => { // if response is null delete response
      const { index, response, singleElement } = action.payload;
      if(index === -1) return;
      let elements = _.cloneDeep(state.doc.elements);
      
      // Update all elements with the same signature
      elements.forEach((element) => {
        if (element.type === singleElement.type && element.recipient === singleElement.recipient) {
          if (!response) {
            // element.responses = [];
          } else {
            if(!element.responses[0]?.signedAt && response != null) {
              element.responses[0] = {'value': response.value ?? null,'type': response.type ?? null};
            } else {
              if(element.responses[0]?.value == null) {
                element.responses[0] = response;
              }
            }
          }
        }
      });
      
      if(!response) elements[index].responses = [];
      else elements[index].responses[0] = response;
      state.doc.elements = elements;
    },
    setActiveIndex: (state, action) => {
      state.activeIndex = action.payload
    },
    setElementToAdd: (state, action) => {
      if(state.elementToAdd?.type === action.payload?.type){
        state.movingElement = {};
        state.elementToAdd = null;
      } else state.elementToAdd = action.payload
    },
    setModalSignType: (state, action) => {
      state.modalSignType = action.payload
    },
    setBuilderStatus: (state, action) => {
      state.builderStatus = action.payload
    },
    updateRecipients: (state, action) => {
      state.doc.recipients = action.payload
    },
    setDocumentReminder: (state, action) => {
      state.doc.reminder = action.payload
    },
    setActiveRecipient: (state, action) => {
      state.activeRecipient = action.payload
    }
  }
});

export const { 
  setElements, 
  setDocument, 
  setZoomLevel,
  updateElements,
  setActiveIndex,
  setElementToAdd,
  setMovingElement,
  setModalSignType,
  setBuilderStatus,
  setElementResponses,
  updateRecipients,
  setDocumentReminder,
  setActiveRecipient
} = signSlice.actions
export default signSlice.reducer;