import React, { useState } from "react";
import DynamicSvg from "../DynamicsSvg/DynamicsSvg";
import Input from "./Input";
import SignerType from "../Dropdowns/SignerType";
import IconButton from "../Buttons/IconButton";
import { PiTrashLight } from "react-icons/pi"

const SelectInput = ({ 
  number, 
  length, 
  recipient, 
  updateRecipient, 
  isDisabled,
  isWebForm 
}) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const icons = { signer: 12, filler: 29 };
  return (
    <div className="flex justify-center items-center w-full relative py-1">
      <div className="flex justify-center items-center border-gray-300 border-[1px] w-full rounded-lg">
        <span className="flex justify-center items-center gap-2 ">
          <div className="px-4 opacity-[1] text-[14px] border-r border-gray-400 text-gray-400">
            {number+1}
          </div>
          <div
            className="flex justify-center items-center ml-3"
            onClick={toggleDropdown}
          >
            <div>
              <DynamicSvg
                name={`icon${icons[recipient.type]}`}
                className="Pen cursor-pointer"
              />
            </div>
            <div className="ml-2 w-6 h-6 opacity-[10] cursor-pointer">
              <div className="pt-3 pl-1 opacity-[20]">
                <DynamicSvg
                  name="icon27"
                  className={`SmallerDownArrow ${
                    isDropdownOpen ? "rotate-180" : ""
                  }`}
                />
              </div>
            </div>
          </div>
        </span>

        <Input 
          type="text"
          name={`recipients[${number}].email`}
          classes="border-0 !my-0"
          placeholder="Enter recipient email"
          hideError={true}
          isReadonly={recipient.systemRecipient}
        />
      </div>
      {((isWebForm && number > 0) || (!isWebForm && length > 1 && !isDisabled)) && (
        <IconButton 
          type="button"
          classes="ml-2"
          onClick={() => updateRecipient(number)}
        >
          <PiTrashLight />
        </IconButton>
      )}

      <div className="absolute top-[100%] left-[10px]">
        <SignerType 
          value={recipient.type} 
          visible={isDropdownOpen}
          setVisibility={setIsDropdownOpen}
          handleOptionClick={(value) => {
            updateRecipient(number, "type", value)
            setIsDropdownOpen(false);
          }} 
        />
      </div>
    </div>
  );
};

export default SelectInput;
