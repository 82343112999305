import React from "react";
import DynamicSvg from "../DynamicsSvg/DynamicsSvg";
import Dropdown from "./Dropdown";

const SignerType = ({ value, visible, setVisibility, handleOptionClick }) => {
  return !visible?null:(
    <Dropdown setVisibility={setVisibility}>
      <div className="dropdown-content border-[1px] border-gray-300 rounded-lg absolute top-[100%] md:left-[0] z-10 bg-white">
        <div className="flex flex-col justify-center items-center">
          <div className="m-1 text-[12px] overflow-hidden ">
            <ul>
              <li
                className={`m-1 flex gap-2 p-1 cursor-pointer ${
                  value === "signer" ? "bg-gray-200" : ""
                }`}
                onClick={() => handleOptionClick("signer")}
              >
                <div>
                  <DynamicSvg name="icon12" className="Pen cursor-pointer" />
                </div>
                <div>Signer</div>
              </li>
              <li
                className={`m-1 flex gap-2 p-1 cursor-pointer ${
                  value === "filler" ? "bg-gray-200" : ""
                }`}
                onClick={() => handleOptionClick("filler")}
              >
                <div>
                  <DynamicSvg name="icon29" className="DocumentWithPen" />
                </div>
                <div>Form filler</div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </Dropdown>
  );
};

export default SignerType;
