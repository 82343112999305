import React from "react";
import Button from "../Buttons/Button";

export default function PdfFilesModal({
  label,
  labelParagraph,
  files,
  isOpen,
  onSubmit,
  onClose,
  onFileClick,
}) {
  return (
    <div
      className={`${
        isOpen ? "block" : "hidden"
      } fixed inset-0 overflow-y-auto z-50 flex justify-center items-center`}
    >
      <div className="modal-overlay fixed inset-0 bg-gray-500 opacity-50"></div>
      <div className="relative mx-auto w-[400px] px-12 py-4 bg-white rounded-xl shadow-lg">
        <h1 className="mb-0 text-center font-medium text-lg">{label}</h1>
        <p className="mb-4 text-sm text-center">{labelParagraph}</p>
        
        <ul className="mb-6">
          {files.map((file, index) => (
            <li key={index} className="cursor-pointer text-green-500 text-sm underline" onClick={() => onFileClick(index)}>
              {file}
            </li>
          ))}
        </ul>
        <div className="flex gap-2 justify-center">
          <Button
            type="button"
            title="Close"
            onClick={onClose}
            classes="!py-1.5 px-3 !text-sm bg-transparent !text-black !hover:bg-gray-200"
          />
        </div>
      </div>
    </div>
  );
}
