import { useMemo } from "react";
import { useSelector } from "react-redux";

const useRecipientFromElement = (recipientId) => {
  const { doc } = useSelector(state => state.sign);

  const recipient = useMemo(() => {
    return doc.recipients.find(recipient => recipient._id === recipientId);
  }, [doc?.recipients, recipientId]);

  return recipient;
};

export default useRecipientFromElement;