import React, { useEffect, useMemo, useState } from "react";
import SelectInput from "./SelectInput";
import Button from "../Buttons/Button";
import { useSelector } from "react-redux";
import { ReactMultiEmail, isEmail } from 'react-multi-email';
import 'react-multi-email/dist/style.css';
import { cloneDeep } from "lodash";
import IconButton from "../Buttons/IconButton";
import { CiCirclePlus } from "react-icons/ci";

const RecipientAndCCInput = ({ 
  title, 
  recipients, 
  setFieldValue,
  isDisabled,
  isBulk,
  isWebForm
}) => {
  const { user } = useSelector(state => state.auth)

  useEffect(() => {
    const newRecipients = [...recipients];
    const index = newRecipients.findIndex(item => item.email === user.email && !item.systemRecipient);
    if(index >= 0) {
      newRecipients[index].systemRecipient = true;
      if(isBulk) setFieldValue("needToSign", true);
      setFieldValue("recipients", newRecipients);
    }
  }, [recipients, user])

  const addRecipient = (me=false) => {
    if(me) if(recipients.some(item => item.systemRecipient)) me = false;
    if(!recipients[0].email && me) {
      const newRecipients = cloneDeep(recipients);
      newRecipients[0] = {
        systemRecipient: me,
        type: "signer",
        email: user.email,
      }
      setFieldValue("recipients", newRecipients);
    } else {
      setFieldValue("recipients", [
        ...recipients,
        ...[{
          systemRecipient: me,
          type: "signer",
          email: !me?"":user.email,
        }]
      ]);
    }
  };

  const updateRecipient = (index, key, value) => {
    const newRecipients = [...recipients];
    if(!key && !value) newRecipients.splice(index, 1);
    else newRecipients[index][key] = value;
    setFieldValue("recipients", newRecipients);
  }

  const handleBulkEmailsChange = (_emails) => {
    const emails = _emails.map(email => {
      const recipient = recipients.find(recipient => recipient.email === email)
      return { 
        type: "signer", 
        email, 
        systemRecipient: recipient?.systemRecipient
      }
    })
    setFieldValue("recipients", emails)
  }

  const hasMe = useMemo(() => {
    return recipients.some(item => item.systemRecipient);
  }, [recipients])

  return (
    <div className="flex flex-col ">
      <div className="flex justify-between w-full my-2">
        <p className="text-[18px]">{title}</p>
        {((!isBulk && !isDisabled) && !hasMe) && (
          <Button
            title="Add me"
            onClick={() => addRecipient(true)}
            classes="!text-green-500 bg-transparent py-0 tracking-wide hover:bg-transparent border-0 !py-0"
          />
        )}
      </div>
      {!isBulk ? 
        recipients.map((recipient, index) => (
          <SelectInput 
            key={index} 
            number={index} 
            length={recipients.length}
            recipient={recipient}
            updateRecipient={updateRecipient}
            isDisabled={isDisabled}
            isWebForm={isWebForm}
          />
        )) : (
          <ReactMultiEmail
            emails={recipients.map(recipient => recipient.email)}
            placeholder='Enter emails'
            onChange={handleBulkEmailsChange}
            className="!border-gray-300 !rounded-lg !focus:border-gray-300"
            getLabel={(email, index, removeEmail) => {
              return (
                <div data-tag key={index}>
                  <div data-tag-item>{email}</div>
                  {!recipients[index]?.systemRecipient && (
                    <span data-tag-handle onClick={() => removeEmail(index)}>
                      ×
                    </span>
                  )}
                </div>
              );
            }}
          />
        )
      }

      {(!isBulk && !isDisabled) && (
        <div className="flex items-center justify-center">
          <hr className="w-[25%]" />
          <IconButton classes="!py-1 !px-3" onClick={() => addRecipient(false)}>
            <CiCirclePlus color="rgb(107 114 128)" size={24} />
          </IconButton>
          <hr className="w-[25%]" />
        </div>
      )}
    </div>
  );
};

export default RecipientAndCCInput;
