import React from "react";
import Button from "../../Buttons/Button"
import Accordion from "./atoms/Accordion"
import useElementCustomize from "../../../hooks/useElementCustomize";
import Checkbox from "../../Inputs/Checkbox";
import UpdateRecipient from "./atoms/UpdateRecipient";
import ElementName from "./atoms/ElementName";
import ElementFooter from "./atoms/ElementFooter";

const FileAttachmentCustomize = ({ 
  index, 
  element
}) => {
  const {
    copyElement,
    cloneElement,
    updateElement, 
    deleteElement, 
    cancelCustomize,
    changeElementType,
    updateElementFields
  } = useElementCustomize(index, element);

  const onInputChange = (event, key) => {
    const { id, value } = event.target;
    updateElementFields(`${key}.${id}`, value);
  }
  
  const onCheckboxChange = (event) => {
    const { id, checked } = event.target;
    updateElementFields(`${id}`, checked);
  }

  return (
    <>
      <ElementName 
        element={element}
        updateElementFields={updateElementFields}
      />

      <UpdateRecipient 
        element={element} 
        updateElementFields={updateElementFields} 
      />

      <div className="my-2">
        <label htmlFor="label" className="block mb-1 text-xs font-normal text-gray-900">Display Label</label>
        <div className="relative">
          <input 
            id="label" 
            type="text" 
            onChange={(e) => onInputChange(e, "value")}
            value={element.value.label}
            className="bg-white border border-gray-400 text-gray-900 text-[12px] rounded-lg block w-full p-1" 
          />
        </div>
      </div>

      <div className="my-2 flex justify-between">
        <Checkbox 
          id="isRequired"
          name="isRequired"
          label="Required"
          checked={element.isRequired}
          onChange={onCheckboxChange}
        />
        <Checkbox 
          id="isReadonly"
          name="isReadonly"
          label="Readonly"
          checked={element.isReadonly}
          onChange={onCheckboxChange}
        />
      </div>
      
      <div className="my-2">
        <label htmlFor="tooltip" className="block mb-1 text-xs font-normal text-gray-900">Tooltip</label>
        <div className="relative">
          <input 
            id="tooltip" 
            type="text" 
            onChange={(e) => onInputChange(e, "value")}
            value={element.value.tooltip}
            className="bg-white border border-gray-400 text-gray-900 text-[12px] rounded-lg block w-full p-1" 
          />
        </div>
      </div>

      <hr className="mt-0.5 mb-0 w-2/3" />

      <Accordion title={"Tools"}>
        <div className="mb-2">
          <Button 
            title="Clone Field"
            onClick={cloneElement} 
            classes="!p-0 text-[12px] bg-transparent border-0 !text-[#3CA970] hover:bg-transparent" 
          />
          <Button 
            title="Copy Field"
            onClick={copyElement} 
            classes="!p-0 text-[12px] bg-transparent border-0 !text-[#3CA970] hover:bg-transparent" 
          />
        </div>
      </Accordion>

      <ElementFooter 
        deleteElement={deleteElement}
        updateElement={updateElement}
        cancelCustomize={cancelCustomize}
      />
    </>
  );
};

export default FileAttachmentCustomize;
