import React, { useState } from "react";
import { FileImport, RecipientAndCCInput } from "../../components";
import { Formik } from "formik";
import * as Yup from "yup";
import { checkIfFilesAreCorrectType, checkIfFilesAreTooBig } from "../../utils/functions";
import { useDispatch } from "react-redux";
import { setLoader } from "../../redux/app.slice";
import toast from "react-hot-toast";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import Button from "../../components/Buttons/Button";
import Input from "../../components/Inputs/Input";
import api from "../../utils/api";
import CancelAgreementModal from "../../components/Modals/CancelAgreementModal";
import _ from "lodash";
import { useNavigate } from "react-router-dom";

export default function CreateWebForm() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [cancelModalVisibility, setCancelModalVisibility] = useState(false);

  const SendSchema = Yup.object().shape({
    agreementName: Yup.string()
      .min(2, 'Document name too short!')
      .max(50, 'Document name to long!')
      .required('Document name is required!'),
    files: Yup.array()
      .min(1, "At least one file is required!")
      .required("At least one file is required!")
      .test('is-big-file', 'PDF file size is to big.', checkIfFilesAreTooBig)
      .test(
        'is-correct-file',
        'Only PDF formats are accepted.',
        checkIfFilesAreCorrectType
      ),
    recipients: Yup.array()
      .of(
        Yup.object().shape({
          email: Yup.string().required("Email is required!"),
        })
      ),
  });

  return (
    <div className="sendcontainer">
      <div className="py-4 mb-4 pt-8">
        <h1 className="text-[30px]">Create a web form</h1>
        <p className="text-sm text-gray-500">
          You can create reusable web forms that you share or embed on your
          website for visitors to easily fill and sign.
        </p>
      </div>
      <Formik
        initialValues={{ 
          type: "webform",
          agreementName: "",
          files: [],
          recipients: [{ type: "signer", email: "Participant", systemRecipient: true }], 
          previewAddSignatureFields: false,
          message: "Please review and complete this document."
        }}
        enableReinitialize={true}
        validationSchema={SendSchema}
        onSubmit={async (values) => {
          try {
            if(!values.previewAddSignatureFields && !cancelModalVisibility) {
              setCancelModalVisibility(true);
              return;
            }
            dispatch(setLoader(true));
            const formData = new FormData();
            values.files.forEach(file => formData.append("files", file));
            formData.append("data", JSON.stringify(_.omit(values, 'files')));
            const { data } = await api.post("/document", formData, {
              headers: { 'Content-Type': 'multipart/form-data' }
            });
            if(values.previewAddSignatureFields) navigate(`/webform/${data._id}`);
            else navigate(`/webform/view/${data._id}`);
          }catch(error){
            toast.error("Unable to create the document. Please try again.");
          }
          dispatch(setLoader(false));
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleSubmit,
          setFieldValue,
        }) => (
          <form onSubmit={handleSubmit}>
            <div className="mt-8">
              <div className="">
                <h1 className="text-[22px] mb-1 font-normal">Document Name</h1>
                <Input 
                  name="agreementName"
                  placeholder="Document Name"
                />

                <RecipientAndCCInput 
                  title="Recipients" 
                  recipients={values.recipients}
                  setFieldValue={setFieldValue}
                  isBulk={false}
                  isWebForm={true}
                />

                <DndProvider backend={HTML5Backend}>
                  <FileImport 
                    files={values.files} 
                    setFiles={setFieldValue}  
                    error={errors.files&&touched.files?errors.files:""}
                  />
                </DndProvider>

                <div className="flex gap-2 text-sm py-3 text-gray-400">
                  <input 
                    type="checkbox" 
                    id="previewAddSignatureFields" 
                    name="previewAddSignatureFields"
                    onChange={handleChange}
                  />
                  <label htmlFor="previewAddSignatureFields">Preview & Add Signature Fields</label>
                </div>

                <div className="flex justify-center mt-3">
                  <Button 
                    type="submit"
                    title={values.previewAddSignatureFields?"Next":"Send"}
                  />
                </div>
              </div>
            </div>
            <CancelAgreementModal
              isOpen={cancelModalVisibility}
              label="Add a signature field"
              labelParagraph={`
                Go back to show recipients where to sign by adding signature fields. 
                Select Continue to automatically place a signature field for each recipient to the end of your document.
              `}
              onClose={() => setCancelModalVisibility(false)}
              onSubmit={() => console.log("test")}
              yesLabel="Continue"
              noLabel="Go back"
            />
          </form>
        )}
      </Formik>
    </div>
  );
}
