import { useRef, useEffect } from 'react';

function Dropdown({ children, setVisibility }) {
  const ref = useRef();

  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) 
        setVisibility(false);
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);

  return (
    <div className={`dropdown relative`} ref={ref}>
      {children}
    </div>
  );
}
export default Dropdown;
