import React from "react";
import Button from "../Buttons/Button";

export default function CancelAgreementModal({
  label,
  labelParagraph,
  isOpen,
  onSubmit,
  onClose,
  yesLabel="Yes",
  noLabel="No",
}) {
  return (
    <div
      className={`${
        isOpen ? "block" : "hidden"
      } fixed inset-0 overflow-y-auto z-50 flex justify-center items-center`}
    >
      <div className="modal-overlay fixed inset-0 bg-gray-500 opacity-50"></div>
      <div className="relative mx-auto w-[450px] px-12 py-6 bg-white rounded-lg shadow-lg">
        <h3 className="mb-4 text-center font-medium text-lg">{label}</h3>
        <p className="mb-6 text-center text-sm">{labelParagraph}</p>
        <div className="flex gap-2 justify-center">
          <Button type="submit" title={yesLabel} onClick={onSubmit} classes="text-sm px-4 !py-1.5" />
          <Button
            type="button"
            title={noLabel}
            onClick={onClose}
            classes="text-sm bg-white px-4 border-gray-400 !hover:bg-gray-200 !text-black !py-1.5"
          />
        </div>
      </div>
    </div>
  );
}
