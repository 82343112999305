import React from "react";
import Button from "./Button";

const OptionsBtn = ({ title, onClick }) => (
  <Button 
    title={title}
    onClick={onClick}
    classes="w-full rounded-none text-[13px] bg-transparent !text-black border-0 !hover:bg-gray-hover !justify-start" 
  />
);

export default OptionsBtn;
