import React, { useEffect } from "react";
import Path from "./routes";
import { Navbar } from "./layouts";
import AOS from "aos";
import "aos/dist/aos.css";
import { useLocation, useSearchParams } from "react-router-dom";
import { useSelector } from "react-redux";
import Loader from "./components/General/Loader/Loader";
import { Toaster } from "react-hot-toast";
import { ReminderModal } from "./components";

function App() {
  const location = useLocation();
  const [search] = useSearchParams();
  const { isAuthLoading } = useSelector(state => state.auth)

  useEffect(() => {
    AOS.init();
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return (
    <>
      {isAuthLoading ? (
        <Loader loading={true} />
      ):(
        <>
          <Navbar />
          <Path />
          <Loader />
          <Toaster
            position="top-center"
            reverseOrder={true}
          />
          {search.get("reminder") && <ReminderModal />}
        </>
      )}
    </>
  );
}

export default App;