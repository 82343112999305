import React from "react";
import Button from "../../Buttons/Button"
import Accordion from "./atoms/Accordion"
import useElementCustomize from "../../../hooks/useElementCustomize";
import UpdateRecipient from "./atoms/UpdateRecipient";
import ElementName from "./atoms/ElementName";
import ElementFooter from "./atoms/ElementFooter";

const StampCustomize = ({ 
  index, 
  element
}) => {
  const {
    cloneElement,
    updateElement, 
    deleteElement, 
    cancelCustomize, 
    updateElementFields
  } = useElementCustomize(index, element);

  const onInputChange = (event, key) => {
    const { id, value } = event.target;
    updateElementFields(`${key}.${id}`, value);
  }

  return (
    <>
      <ElementName 
        element={element}
        updateElementFields={updateElementFields}
      />

      <UpdateRecipient 
        element={element} 
        updateElementFields={updateElementFields} 
      />

      <div className="my-2">
        <label htmlFor="tooltip" className="block mb-1 text-xs font-normal text-gray-900">Tooltip</label>
        <div className="relative">
          <input 
            id="tooltip" 
            type="text" 
            onChange={(e) => onInputChange(e, "value")}
            value={element.value.tooltip}
            className="bg-white border border-gray-400 text-gray-900 text-[12px] rounded-lg block w-full p-1" 
          />
        </div>
      </div>

      <hr className="mt-2 mb-1.5 w-2/3" />

      <Accordion title={"Tools"}>
        <Button 
          title="Clone Field"
          onClick={cloneElement} 
          classes="!p-0 text-[12px] bg-transparent border-0 !text-[#3CA970] hover:bg-transparent" 
        />
      </Accordion>

      <ElementFooter 
        deleteElement={deleteElement}
        updateElement={updateElement}
        cancelCustomize={cancelCustomize}
      />
    </>
  );
};

export default StampCustomize;
