import React from "react";
import Dropdown from "./Dropdown";
import { AiFillMinusCircle } from "react-icons/ai";
import useLastSignatureElement from "../../hooks/useLastSigantureElement";

const SignType = ({ 
  setVisibility,
  handleSignTypeClick
}) => {
  const [lastInitialElement, lastSignatureElement] = useLastSignatureElement();

  return (
    <Dropdown setVisibility={setVisibility}>
      <div className="absolute top-[40px] left-0 w-[180px] bg-white  border border-gray-300 rounded-lg z-10">
        <div 
          onClick={() => handleSignTypeClick("signature", lastSignatureElement)}
          className="flex items-center justify-between hover:bg-gray-300 p-2"
        >
          {lastSignatureElement?(
            lastSignatureElement?.responses[0]?.type !== "type" ? (
              <img 
                src={lastSignatureElement.responses[0]?.value}
                alt="Signature" 
                className="pointer-events-none h-[25px] max-w-full" 
              /> 
            ) : (
              <p className="font-[Allura]">{lastSignatureElement.responses[0]?.value}</p>
            )
          ) : <p>Sign documents</p>}
          {lastSignatureElement && (
            <span onClick={(e) => handleSignTypeClick("signature", lastSignatureElement, e)}>
              <AiFillMinusCircle color="#000" className="opacity-50" />
            </span>
          )}
        </div>

        <div 
          onClick={() => handleSignTypeClick("initials", lastInitialElement)}
          className="flex items-center justify-between hover:bg-gray-300 p-2"
        >
          {lastInitialElement?(
            lastInitialElement?.responses[0]?.type !== "type" ? (
              <img 
                src={lastInitialElement.responses[0]?.value}
                alt="Signature" 
                className="pointer-events-none h-[25px] max-w-full" 
              /> 
            ) : (
              <p className="font-[Allura]">{lastInitialElement.responses[0]?.value}</p>
            )
          ) : <p>Add Initials</p>}
          {lastInitialElement && (
            <span onClick={(e) => handleSignTypeClick("initials", lastInitialElement, e)}>
              <AiFillMinusCircle color="#000" className="opacity-50" />
            </span>
          )}
        </div>
      </div>
    </Dropdown>
  );
};

export default SignType;