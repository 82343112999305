export const WEB_URL = window.location.protocol + "//" + window.location.hostname;

export const FREQUENCYOPTIONS = {
  "dayUntilCompleted": "Day until completed",
  "weeklyUntilCompleted": "Weekly until completed",
  "businessDayUntilCompleted": "Every business day until completed", 
  "otherDayUntilCompleted": "Every other day until completed",
  "thirdDayUntilCompleted": "Every third day until completed",
  "fifthDayUntilCompleted": "Every fifth day until completed"
};
