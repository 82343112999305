
import React, { useState } from "react";
import { GoPencil } from "react-icons/go"
import { VscSaveAll } from "react-icons/vsc";
import IconButton from "../../../Buttons/IconButton"

const ElementName = ({ element, updateElementFields }) => {
  const [isEditingName, setEditingName] = useState(false);

  const onNameChange = (event) => {
    const { id, value } = event.target;
    updateElementFields(`${id}`, value);
  }

  return (
    <div>
      <div className="flex items-center justify-between">
        {!isEditingName ? (
          <>
            <p className="font-light text-[12px] capitalize">{element.name}</p>
            <IconButton 
              type="button"
              classes="!p-1" 
              onClick={() => setEditingName(true)}
            ><GoPencil color="#727272" size={12} /></IconButton>
          </>
        ) : (
          <>
            <input 
              id="name"
              type="text" 
              autoFocus={true}
              value={element.name}
              onChange={onNameChange}
              onBlur={() => setEditingName(false)}
              className="w-full text-[12px]" 
            />
            <IconButton 
              type="button"
              classes="!p-1" 
              onClick={() => setEditingName(false)}
            ><VscSaveAll color="#727272" size={12} /></IconButton>
          </>
        )}
      </div>
      <hr className="my-1 w-2/3" />
    </div>
  );
};

export default ElementName;
