import { Link } from "react-router-dom";
import ElementsContainer from "../ElementsContainer";
import style from "../style.module.css";
import { useSelector } from "react-redux";
import { GrAttachment } from "react-icons/gr";
import { useRef } from "react";
import toast from "react-hot-toast";
import api from "../../../utils/api";
import useSetElementResponse from "../../../hooks/useSetElementResponse";

export default function FileAttachment({ index, element }) {
  const hiddenFileInput = useRef(null);
  const { builderStatus, activeIndex, zoomLevel } = useSelector((state) => state.sign);
  const isActive = activeIndex === index && builderStatus === "edit";
  const { setResponse } = useSetElementResponse(index, element);
  
  const handleClick = event => {
    if(builderStatus !== "fill" || element.isReadonly) return;
    event.target.value = "";
    hiddenFileInput.current.click();
  };

  const handleChange = async (event) => {
    try {
      const file = event.target.files[0];
      if(!file) return;

      const MAX_FILE_SIZE = 5120;
      const ALLOWED_TYPES = ["image/jpeg", "image/png", "application/pdf"];

      const fileSizeKiloBytes = file.size / 1024
      if(fileSizeKiloBytes > MAX_FILE_SIZE){
        throw "File size is greater than maximum limit!";
      }
      if(!ALLOWED_TYPES.includes(file.type)) {
        throw "Invalid file type!";
      }

      const formData = new FormData();
      formData.append("files", file);
      const { data } = await api.post(`/media/tmp/upload`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' }
      });
      setResponse({ type: file.type, value: data.filename });
    } catch(error){
      toast.error(error.message);
    }
  };
  
  return (
    <ElementsContainer 
      index={index}
      element={element}
      lockAspectRatio={true}
      initialResizeHandleClasses={{ 
        right: isActive ? style.resizeDotRightCenter : null, 
        bottomRight: "pointer-events-none" 
      }}
    >
      <div className="h-full">
        <div className="flex items-center h-full cursor-pointer" onClick={handleClick}>
          <GrAttachment size={18*zoomLevel} className="mr-2" />
          <input 
            type="file" 
            className="hidden" 
            ref={hiddenFileInput}
            onChange={handleChange}
            accept="application/pdf,image/jpeg,image/jpg,image/png"
          />
          { element?.responses.length <= 0 ? (
            <label className="pointer-events-none" style={{
              color: element.style.color,
              fontSize: element.style.fontSize * zoomLevel,
              textAlign: element.style.textAlign
            }}>{element.value?.label}</label>
          ) : (
            <Link 
              target="_blank"
              className="truncate"
              to={`http://localhost:8000/api/v1/media/download/${element.responses[0].value}`}
              style={{
                color: "#3CA970",
                fontSize: element.style.fontSize * zoomLevel,
                textAlign: element.style.textAlign
              }}
            >{element.responses[0].value}</Link>
          )} 
        </div>
      </div>
    </ElementsContainer>
  );
}
