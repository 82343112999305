import React, { useState } from "react";
import { SlArrowUp, SlArrowDown } from "react-icons/sl";

export default function Accordion ({ title, children }) {
  const [isOpen, setIsOpen] = useState(false);

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="border-t-0 mt-2">
      <div
        className={`flex justify-between items-center cursor-pointer w-full ${
          isOpen ? "transition-p duration-300 ease-in-out" : ""
        } `}
        onClick={toggleAccordion}
      >
        <label className="text-[12px] font-400 inline-block">{title}</label>
        <span className="p-1 text-xs">{isOpen ? <SlArrowUp size={9} /> : <SlArrowDown size={9} />}</span>
      </div>
      <div
        className={`mt-2 flex flex-col justify-start items-start gap-2 text-[13px]  transition-max-h transition-p transition-mb duration-300 ease-in-out ${
          isOpen ? " max-h-screen opacity-100 visible" : "max-h-0 opacity-0 invisible"
        }`}
      >
        {children}
      </div>
    </div>
  );
}