import React, { useState } from "react";
import { FiFilter } from "react-icons/fi";
import { AiOutlineUp, AiOutlineDown } from "react-icons/ai";

export default function FilterButton() {
  const [isDropdownVisible, setDropdownVisible] = useState(false);
  const [isModifiedDateOpen, setModifiedDateOpen] = useState(false);
  const [isSearchWithinOpen, setSearchWithinOpen] = useState(false);

  const toggleDropdown = () => {
    setDropdownVisible(!isDropdownVisible);
  };

  const toggleModifiedDateDropdown = () => {
    setModifiedDateOpen(!isModifiedDateOpen);
    setSearchWithinOpen(false);
  };

  const toggleSearchWithinDropdown = () => {
    setSearchWithinOpen(!isSearchWithinOpen);
    setModifiedDateOpen(false);
  };

  return (
    <div className="relative">
      <button
        className="bg-green text-white px-2 py-1 ml-2 rounded-lg flex items-center "
        onClick={toggleDropdown}
      >
        <FiFilter className="mr-2" /> Filters
        {isDropdownVisible ? (
          <AiOutlineUp className="ml-2 w-2 h-2" />
        ) : (
          <AiOutlineDown className="ml-2 w-2 h-2" />
        )}
      </button>
      {isDropdownVisible && (
        <div
          className="mt-2 absolute top-[100%] right-0 w-60 bg-white border rounded-lg p-2 z-10"
          data-aos="zoom-in"
        >
          <p>Modified Date:</p>
          <button
            className="p-2 border w-full rounded-lg my-2 outline-none text-start text-gray-400 cursor-pointer"
            onClick={toggleModifiedDateDropdown}
          >
            All dates
          </button>
          {isModifiedDateOpen === true && (
            <div
              className="border rounded-lg cursor-pointer overflow-y-auto max-h-60"
              data-aos="zoom-in"
            >
              <div className="hover:bg-gray-300">
                <p className="ml-3 py-2">Option 1</p>
              </div>
              <div className="hover:bg-gray-300">
                <p className="ml-3 py-2">Option 2</p>
              </div>
              <div className="hover:bg-gray-300">
                <p className="ml-3 py-2">Option 3</p>
              </div>
              <div className="hover:bg-gray-300">
                <p className="ml-3 py-2">Option 4</p>
              </div>
            </div>
          )}

          <p className="mt-2">Search within</p>
          <button
            className="p-2 border w-full rounded-lg my-2 outline-none text-start text-gray-400 cursor-pointer"
            onClick={toggleSearchWithinDropdown}
          >
            Search within
          </button>
          {isSearchWithinOpen && (
            <div
              className="border rounded-lg cursor-pointer overflow-y-auto max-h-60"
              data-aos="zoom-in"
            >
              <div className="hover:bg-gray-300">
                <p className="ml-3 py-2">All fields</p>
              </div>
              <div className="hover:bg-gray-300">
                <p className="ml-3 py-2">All fields</p>
              </div>
              <div className="hover:bg-gray-300">
                <p className="ml-3 py-2">All fields</p>
              </div>
              <div className="hover:bg-gray-300">
                <p className="ml-3 py-2">All fields</p>
              </div>
              <div className="hover:bg-gray-300">
                <p className="ml-3 py-2">All fields</p>
              </div>
              <div className="hover:bg-gray-300">
                <p className="ml-3 py-2">All fields</p>
              </div>
              <div className="hover:bg-gray-300">
                <p className="ml-3 py-2">All fields</p>
              </div>
              <div className="hover:bg-gray-300">
                <p className="ml-3 py-2">All fields</p>
              </div>
              <div className="hover:bg-gray-300">
                <p className="ml-3 py-2">All fields</p>
              </div>
            </div>
          )}
          <div className="flex justify-end mt-4">
            <button className="px-4 py-2 bg-green text-white rounded-lg cursor-pointer">
              Apply
            </button>
            <button
              className="border ml-2 px-4 py-2 bg-red text-black rounded-lg cursor-pointer"
              onClick={() => {
                setDropdownVisible(false);
              }}
            >
              Cancel
            </button>
          </div>
        </div>
      )}
    </div>
  );
}
