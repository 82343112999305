import React, { useEffect, useState } from "react";
import {
  FileImport,
  RecipientAndCCInput,
  ReminderModalForm,
} from "../../components";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { Formik } from "formik";
import * as Yup from "yup";
import { checkIfFilesAreCorrectType, checkIfFilesAreTooBig } from "../../utils/functions";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import Input from "../../components/Inputs/Input";
import Button from "../../components/Buttons/Button";
import api from "../../utils/api";
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { setLoader } from "../../redux/app.slice";
import CancelAgreementModal from "../../components/Modals/CancelAgreementModal";
import toast from "react-hot-toast";
import ReminderItems from "../../components/General/Reminder/ReminderItems";

export default function SendPage() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user } = useSelector(state => state.auth)
  const [cancelModalVisibility, setCancelModalVisibility] = useState(false);
  const { pathname } = useLocation();
  const isBulk = pathname === "/send/bulk";
  const [params] = useSearchParams();
  const [template, setTemplate] = useState(null);
  const [reminder, setReminder] = useState(false);

  const getTemplate = async () => {
    try{
      const templateId = params.get("templateId");
      if(!templateId) return;
      dispatch(setLoader(true));
      const { data } = await api.get(`/template/${templateId}`);
      setTemplate(data);
    }catch(error) {
      toast.error("Unable to locate the specified template.");
      navigate(pathname);
    }
    dispatch(setLoader(false));
  }

  useEffect(() => {
    getTemplate();
  }, [])

  const SendSchema = Yup.object().shape({
    agreementName: Yup.string()
      .min(2, 'Document name too short!')
      .max(50, 'Document name to long!')
      .required('Document name is required!'),
    message: Yup.string()
      .min(5, 'Message too short!')
      .max(150, 'Message to long!')
      .required('Message is required!'),
    files: Yup.array()
      .min(1, "At least one file is required!")
      .required("At least one file is required!")
      .test('is-big-file', 'PDF file size is to big.', checkIfFilesAreTooBig)
      .test(
        'is-correct-file',
        'Only PDF formats are accepted.',
        checkIfFilesAreCorrectType
      ),
    recipients: Yup.array()
      .of(
        Yup.object().shape({
          email: Yup.string().email("Invalid email!").required("Email is required!"),
        })
      ),
  });

  const handleNeedToSign = (checked, values, setFieldValue) => {
    let newRecipients = [...values.recipients];
    if(checked) {
      newRecipients.push({ type: "signer", email: user.email, systemRecipient: true });
    } else {
      newRecipients = newRecipients.filter(recipient => recipient.email !== user.email);
    } 
    setFieldValue("recipients", newRecipients);
    setFieldValue("needToSign", checked);
  }

  const handleReminderSubmit = (values, setFieldValue) => {
    setReminder(false);
    setFieldValue("reminder", [values]);
  }

  return (
    <div className="sendcontainer">
      <div className="py-4 mb-4 pt-8">
        <h1 className="text-[30px]">{isBulk?"Send in bulk":"Sign a New Document"}</h1>
        <p className="text-sm text-gray-500">
          { isBulk ? `
              Send the same document to many recipients. 
              Type in their email adresses or upload a CSV file with signer-specific data. 
              Every signer will recieve their own copy of the document.
            ` : `Share documents with others for e-signatures, approval, or other processing.`
          }
        </p>
      </div>
      <Formik
        initialValues={{ 
          type: isBulk?"bulk":"single",
          agreementName: template?.agreementName || "",
          message: "Please review and complete this document.",
          files: template?.files || [],
          needToSign: false,
          recipients: template?.recipients || (isBulk?[]:[{ type: "signer", email: ""}]), 
          previewAddSignatureFields: false,
          reminder: []
        }}
        enableReinitialize={true}
        validationSchema={SendSchema}
        onSubmit={async (values) => {
          try {
            if(!values.previewAddSignatureFields && !cancelModalVisibility) {
              setCancelModalVisibility(true);
              return;
            }
            dispatch(setLoader(true));
            const formData = new FormData();
            values.files.forEach(file => formData.append("files", file));
            values.templateId = params.get("templateId");
            formData.append("data", JSON.stringify(_.omit(values, 'files')));
            const { data } = await api.post("/document", formData, {
              headers: { 'Content-Type': 'multipart/form-data' }
            });
            if(values.previewAddSignatureFields) {
              navigate(`${pathname === "/send/bulk" ? "/send/bulk":"/send/single"}/${data._id}`);
            } else navigate(`/send/view/${data._id}`);
          }catch(error){
            toast.error("Unable to create the document. Please try again.");
          }
          dispatch(setLoader(false));
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleSubmit,
          setFieldValue,
        }) => (
          <>
            <form onSubmit={handleSubmit}>
              <RecipientAndCCInput 
                title="Recipients" 
                recipients={values.recipients}
                setFieldValue={setFieldValue}
                isDisabled={template&&true}
                isBulk={isBulk}
              />

              {isBulk && (
                <div className="flex gap-2 text-sm text-gray-400 items-center py-4">
                  <input
                    type="checkbox"
                    id="needToSign"
                    name="needToSign"
                    checked={values.needToSign}
                    onChange={(e) => {
                      handleNeedToSign(e.target.checked, values, setFieldValue);
                    }}
                  />
                  <label htmlFor="needToSign" className="cursor-pointer">I need to sign</label>
                </div>
              )}
        
              <DndProvider backend={HTML5Backend}>
                <FileImport 
                  files={values.files} 
                  setFiles={setFieldValue}  
                  isDisabled={template&&true}
                  error={errors.files&&touched.files?errors.files:""}
                />
              </DndProvider>

              {!isBulk && (
                <div className="flex gap-2 text-sm py-3 text-gray-400">
                  <input 
                    type="checkbox" 
                    id="previewAddSignatureFields" 
                    name="previewAddSignatureFields"
                    onChange={handleChange}
                  />
                  <label htmlFor="previewAddSignatureFields">Preview & Add Signature Fields</label>
                </div>
              )}

              <div className={`mt-2 ${isBulk && "mt-6"}`}>
                <h1 className="text-[22px] mb-1 font-normal">Document Name</h1>
                <Input 
                  name="agreementName"
                  placeholder="Document Name"
                />
                <h1 className="text-[22px] mt-3 mb-1 font-normal">Message</h1>
                <Input 
                  as="textarea"
                  name="message"
                  value={values.message}
                  placeholder="Please review and complete this document."
                />
                {isBulk && (
                  <div className="flex gap-2 text-sm py-3 pt-0 text-gray-400">
                    <input 
                      type="checkbox" 
                      id="previewAddSignatureFields" 
                      name="previewAddSignatureFields"
                      onChange={handleChange}
                    />
                    <label htmlFor="previewAddSignatureFields">Preview & Add Signature Fields</label>
                  </div>
                )}
                {!isBulk && (
                  <>
                    <div className="flex gap-2 text-sm text-gray-400">
                      <input type="checkbox" id="reminderState" checked={reminder || values.reminder?.length > 0} onChange={() => setReminder(!reminder)} />
                      <label htmlFor="reminderState">Set Reminder</label>
                    </div>
                    {values.reminder?.length > 0 && (
                      <ReminderItems 
                        reminder={values.reminder[0]}
                        recipients={values.recipients}
                      />
                    )}
                  </>
                )}
                <div className="flex justify-center mt-3">
                  <Button 
                    type="submit"
                    title={values.previewAddSignatureFields?"Next":"Send"}
                  />
                </div>
              </div>
              
              <CancelAgreementModal
                isOpen={cancelModalVisibility}
                label="Add a signature field"
                labelParagraph={`
                  Go back to show recipients where to sign by adding signature fields. 
                  Select Continue to automatically place a signature field for each recipient to the end of your document.
                `}
                onClose={() => setCancelModalVisibility(false)}
                onSubmit={() => console.log("test")}
                yesLabel="Continue"
                noLabel="Go back"
              />
            </form>
            {reminder && (
              <ReminderModalForm 
                values={values.reminder[0]}
                onClose={() => {
                  setReminder(false);
                  setFieldValue("reminder", []);
                }}
                handleFormSubmit={(values) => handleReminderSubmit(values, setFieldValue)}
              />
            )}
          </>
        )}
      </Formik>
    </div>
  );
}
