import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import api from "../../utils/api";
import toast from "react-hot-toast";

export default function ManageSidebar({}) {
  const navigate = useNavigate();
  const [search] = useSearchParams();
  const activeTab = search.get("type");
  const activeTable = search.get("status");
  const [count, setCount] = useState({ inprogress: 0, waiting: 0 });

  const handleClick = (element) => {
    navigate(`/manage?type=document&status=${element}&page=${1}`);
  };

  const countDocuments = async () => {
    try {
      const count = await api.get("/document/count/all");
      setCount(count.data);
    } catch(error){
      toast.error("Unable to retrieve the number of documents at the moment.");
    }
  }

  useEffect(() => {
    countDocuments();
  }, [])

  const handleOutside = (element) => {
    navigate(`/manage?type=${element}&page=${1}`);
  };

  return (
    <div className="text-black w-96 p-4 fixed h-screen">
      <h1 className="text-[30px] font-semibold">Your agreements</h1>
      <div className="mt-4">
        <p className="text-xs text-gray-400 p-2">STATUS</p>
        <div className="border rounded-lg p-2 flex flex-col gap-2 ">
          <p
            className={`cursor-pointer mb-2 ${
              activeTable === "inprogress" ? "font-semibold" : ""
            }`}
            onClick={() => handleClick("inprogress")}
          >
            In progress ({count.inprogress})
          </p>
          <p
            className={`cursor-pointer mb-2 ${
              activeTable === "waiting-for-you" ? "font-semibold" : ""
            }`}
            onClick={() => handleClick("waiting-for-you")}
          >
            Waiting for you ({count.waiting})
          </p>
          <p
            className={`cursor-pointer mb-2 ${
              activeTable === "completed" ? "font-semibold" : ""
            }`}
            onClick={() => handleClick("completed")}
          >
            Completed
          </p>
          <p
            className={`cursor-pointer mb-2 ${
              activeTable === "canceled" ? "font-semibold" : ""
            }`}
            onClick={() => handleClick("canceled")}
          >
            Canceled
          </p>
          <p
            className={`cursor-pointer  ${
              activeTable === "draft" ? "font-semibold" : ""
            }`}
            onClick={() => handleClick("draft")}
          >
            Draft
          </p>
        </div>
      </div>
      <div className="flex flex-col gap-4 mt-4">
        <p
          className={`cursor-pointer border rounded-lg p-2 transition  ${
            activeTab === "template" ? "font-semibold bg-gray-100" : ""
          }`}
          onClick={() => handleOutside("template")}
        >
          Template
        </p>
        <p
          className={`cursor-pointer border rounded-lg p-2 transition  ${
            activeTab === "webform" ? "font-semibold bg-gray-100" : ""
          }`}
          onClick={() => handleOutside("webform")}
        >
          Web forms
        </p>
        <p
          className={`cursor-pointer border rounded-lg p-2  ${
            activeTab === "bulk"
              ? "font-semibold bg-gray-100 transition"
              : ""
          }`}
          onClick={() => handleOutside("bulk")}
        >
          Bulk sends
        </p>
      </div>
    </div>
  );
}
