
import React from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

const UpdateRecipient = ({ element, updateElementFields }) => {
  const { doc } = useSelector(state => state.sign);
  const { user } = useSelector(state => state.auth);
  const { pathname } = useLocation();
  const basePath = pathname.replace(`/${doc._id}`, '');

  const updateRecipient = (event) => {
    updateElementFields(`recipient`, event.target.value);
  }

  return (
    <div>
      <label className="text-[12px] font-400 inline-block mb-1">Assigned To</label>
      <select className="rounded-md p-1 border border-gray-400 font-300 text-[12px] w-full" onChange={updateRecipient}>
        {basePath==="/send/bulk"?(
          <>
            <option value="" selected={!element.recipient}>Send in Bulk Signer</option>
            {doc.needToSign && doc.recipients.filter(recipient => recipient.email === user.email).map(item => (
              <option key={item._id} value={item._id} selected={element.recipient === item._id}>{item.email}</option>
            ))}
          </>
        ):(
          doc.recipients.map(item => <option key={item._id} value={item._id} selected={element.recipient === item._id}>{item.email}</option>)
        )}
      </select>
    </div>
  );
};

export default UpdateRecipient;
