import React from "react";
import Button from "../../Buttons/Button"
import Accordion from "./atoms/Accordion"
import useElementCustomize from "../../../hooks/useElementCustomize";
import UpdateRecipient from "./atoms/UpdateRecipient";
import ElementName from "./atoms/ElementName";
import ElementFooter from "./atoms/ElementFooter";
import FieldType from "./atoms/FieldType";

const SignatureCustomize = ({ 
  index, 
  element
}) => {

  const {
    cloneElement,
    updateElement, 
    deleteElement, 
    cancelCustomize, 
    changeElementType,
    updateElementFields
  } = useElementCustomize(index, element);

  return (
    <>
      <ElementName 
        element={element}
        updateElementFields={updateElementFields}
      />

      <UpdateRecipient 
        element={element} 
        updateElementFields={updateElementFields} 
      />

      <FieldType 
        element={element} 
        changeElementType={changeElementType} 
      />

      <hr className="mt-3.5 mb-0.5 w-2/3" />

      <Accordion title={"Tools"}>
        <Button 
          title="Clone Field"
          onClick={cloneElement} 
          classes="!p-0 text-[12px] bg-transparent border-0 !text-[#3CA970] hover:bg-transparent" 
        />
      </Accordion>

      <ElementFooter 
        deleteElement={deleteElement}
        updateElement={updateElement}
        cancelCustomize={cancelCustomize}
      />
    </>
  );
};

export default SignatureCustomize;
