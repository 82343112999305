import ElementsContainer from "../ElementsContainer";
import { AutoTextSize } from 'auto-text-size'
import { dateFormat } from "../../../utils/functions"
import style from "../style.module.css";
import { useDispatch, useSelector } from "react-redux";
import Button from "../../Buttons/Button"
import { setActiveIndex, setModalSignType } from "../../../redux/sign.slice";
import { useParams } from "react-router-dom";
import ScaleText from "react-scale-text";
import ScalableText from "../../ScalableText/ScalableText";

export default function Signature({ index, element }) {
  const dispatch = useDispatch();
  const { recipientId } = useParams(); 
  const { builderStatus, activeIndex, zoomLevel } = useSelector((state) => state.sign);
  const isActive = activeIndex === index && builderStatus === "edit";
  
  return (
    <ElementsContainer 
      index={index}
      element={element}
      lockAspectRatio={true}
      initialResizeHandleClasses={{ 
        right: isActive ? style.resizeDotRightCenter : null, 
        bottomRight: "pointer-events-none" 
      }}
    >
      <div className="flex items-center h-full overflow-hidden cursor-pointer"
        onClick={() => {
          if (recipientId === element?.recipient) {
            dispatch(setActiveIndex(index));
            dispatch(setModalSignType("signature"));
          }
        }}
      >
        <span style={{
          position: "absolute",
          width: "15px",
          height: "2px", 
          backgroundColor: "#3CA970",
          right: "-22px",
        }}></span>
        <span style={{
          position: "absolute",
          top: "50%",
          right: "-10px",
          transform: "translateY(-50%)",
          width: "10px",
          height: "15px",
          backgroundColor: "#3CA970",
          clipPath: "polygon(0% 50%, 100% 0%, 100% 100%)"
        }}></span>
        {builderStatus === "edit" ? (
          <div className="flex w-full flex-col gap-1.5 h-full">
            <div className="h-full">
              <ScalableText 
                minFontSize={8}
                maxFontSize={14}
                text={"Signature"} 
              />
            </div>
            <div className="border-t-1 border-gray-400 pt-[2px]"></div>
            <div className="h-full">
              <ScalableText 
                minFontSize={8}
                maxFontSize={12}
                classes="whitespace-nowrap font-light"
                text={"Full Name - Siganture Date"}
              />
            </div>
          </div>
        ) : (
          element?.responses.length > 0 && element?.responses[0]?.signedAt ?(
            <div onClick={() => {
              if(recipientId === element.recipient) {
                dispatch(setActiveIndex(index))
                dispatch(setModalSignType("signature"));
              }
            }} className="cursor-pointer h-full w-full">
              {element?.responses[0]?.type === "type" && (
                <div className="max-w-full mx-auto my-0 select-none h-full flex flex-col">
                  <div style={{height: element.style.height * zoomLevel * 0.6}}>
                    <ScalableText 
                      text={element.responses[0]?.value} 
                      classes="whitespace-nowrap font-[Allura]" 
                    />
                  </div>
                  <div 
                    style={{height: element.style.height * zoomLevel * 0.4}}
                    className="border-t border-t-1 border-green-500 flex flex-nowrap justify-between overflow-hidden"
                  >
                    <ScalableText 
                      minFontSize={4} 
                      maxFontSize={10}
                      classes="whitespace-nowrap text-green-400" 
                      text={`${element.responses[0]?.fullName} (${dateFormat(element.responses[0]?.signedAt)})`}
                    />
                  </div>
                </div>
                
              )}
              { element?.responses[0]?.type !== "type"  && (
                <div className="h-full flex flex-col">
                  <div style={{height: element.style.height * zoomLevel * 0.6}}>
                    <img 
                      src={element.responses[0]?.value}
                      alt="Signature" 
                      className={`pointer-events-none max-w-[100%] max-h-full`}
                    /> 
                  </div>
                  <div 
                    style={{height: element.style.height * zoomLevel * 0.4}}
                    className="border-t border-t-1 border-green-500 flex flex-nowrap justify-between overflow-hidden"
                  >
                    <ScalableText 
                      minFontSize={4} 
                      maxFontSize={10}
                      classes="whitespace-nowrap text-green-400" 
                      text={`${element.responses[0]?.fullName} (${dateFormat(element.responses[0]?.signedAt)})`}
                    />
                  </div>
                </div>
              )}
            </div>
          ) : (
            recipientId === element.recipient && (
              <Button 
                title={
                  <ScalableText 
                    minFontSize={4} 
                    maxFontSize={14}
                    text="Click here to sign"
                    classes="whitespace-nowrap text-green-400" 
                  />
                }
                onClick={() => {
                  dispatch(setActiveIndex(index))
                  dispatch(setModalSignType("signature"))
                }}
                style={{ fontSize: 14 * zoomLevel }}
                classes="bg-transparent border-0 !text-[#3CA970] hover:bg-transparent !p-0 text-lg"
              />
            )
          )
        )}
      </div>
    </ElementsContainer>
  );
}
