export function animateCount(element, targetNumber, duration) {
  let currentNumber = 0;
  const increment = targetNumber / (duration / 1000);

  const interval = setInterval(() => {
    if (currentNumber < targetNumber) {
      currentNumber += increment;
      element.textContent = Math.round(currentNumber);
    } else {
      clearInterval(interval);
    }
  }, 10);

  return interval;
}
