
import React, { useState, useEffect, useRef } from "react";
import IconButton from "../../../Buttons/IconButton"
import Accordion from "./Accordion"
import { FiAlignLeft, FiAlignCenter, FiAlignRight } from "react-icons/fi";
import { ChromePicker } from 'react-color';

const ElementAppearance = ({ element, updateElementFields }) => {
  const ref = useRef(null);
  const [isPickerVisible, setPickerVisible] = useState(false);

  useEffect(() => {
    function handleClickOutside(event) {
      console.log("TEST", ref.current)
      if (ref.current && !ref.current.contains(event.target)) {
        setPickerVisible(false);
      }
    }
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [ref]);

  const onInputChange = (event, key) => {
    const { id, value } = event.target;
    updateElementFields(`${key}.${id}`, value);
  }

  const onColorChange = (hex, key) => {
    updateElementFields(`style.${key}`, hex);
  }

  const onAlignmentChange = (align) => {
    updateElementFields(`style.textAlign`, align);
  }

  return (
    <Accordion title={"Appearance"}>
      <div className="w-full grid grid-cols-2">
        <span>Font</span>
        <input 
          id="fontSize" 
          type="number" 
          onChange={(e) => onInputChange(e, "style")}
          value={element.style.fontSize}
          className="bg-white border border-gray-400 text-gray-900 text-[10px] rounded-lg block w-full p-1" 
        />
      </div>
      <div className="w-full grid grid-cols-2">
        <span>Alignment</span>
        <div className="">
          <IconButton 
            type="button"
            classes="!p-1" 
            onClick={() => onAlignmentChange("left")}
          ><FiAlignLeft size={16} color={element.style.textAlign==="left"?"#000":"#D9D9D9"} /></IconButton>
          <IconButton 
            type="button"
            classes="!p-1" 
            onClick={() => onAlignmentChange("center")}
          ><FiAlignCenter size={16} color={element.style.textAlign==="center"?"#000":"#D9D9D9"} /></IconButton>
          <IconButton 
            type="button"
            classes="!p-1" 
            onClick={() => onAlignmentChange("right")}
          ><FiAlignRight size={16} color={element.style.textAlign==="right"?"#000":"#D9D9D9"} /></IconButton>
        </div>
      </div>
      <div className="w-full grid grid-cols-2">
        <span>Color</span>
        <div className="relative">
          <span 
            onClick={() => setPickerVisible(true)} 
            style={{ background: element.style.color }}
            className={`cursor-pointer w-[16px] h-[16px] border border-gray-200 inline-block`}
          ></span>
          {isPickerVisible && (
            <div ref={ref} className="absolute left-[22px] bottom-0">
              <ChromePicker 
                color={element.style.color}
                onChange={ ({ hex }) => onColorChange(hex, "color")} 
              />
            </div>
          )}
        </div>
      </div>
    </Accordion>
  );
};

export default ElementAppearance;
