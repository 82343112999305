import { useParams } from "react-router-dom";
import { setActiveIndex, setModalSignType } from "../../../redux/sign.slice";
import ElementsContainer from "../ElementsContainer";
import style from "../style.module.css";
import { useDispatch, useSelector } from "react-redux";
import Button from "../../Buttons/Button"

export default function Signature({ index, element }) {
  const { recipientId } = useParams(); 
  const dispatch = useDispatch();
  const { builderStatus, activeIndex, zoomLevel } = useSelector((state) => state.sign);
  const isActive = activeIndex === index && builderStatus === "edit";
  
  return (
    <ElementsContainer 
      index={index}
      element={element}
      lockAspectRatio={true}
      initialResizeHandleClasses={{ 
        right: isActive ? style.resizeDotRightCenter : null, 
        bottomRight: "pointer-events-none" 
      }}
    >
      <div className="h-full cursor-pointer"
        onClick={() => {
          if (recipientId === element?.recipient) {
            dispatch(setActiveIndex(index));
            dispatch(setModalSignType("stamp"));
          }
        }}
      >
        <span style={{
          position: "absolute",
          width: "15px",
          height: "2px", 
          backgroundColor: "#3CA970",
          right: "-22px",
          top: "50%",
        }}></span>
        <span style={{
          position: "absolute",
          top: "50%",  
          right: "-10px",  
          transform: "translateY(-50%)",
          width: "10px",
          height: "15px",
          backgroundColor: "#3CA970",
          clipPath: "polygon(0% 50%, 100% 0%, 100% 100%)"
        }}></span>
        <div className="flex flex-col items-center h-full">
          { builderStatus === "edit" ? (
            <>
              <p style={{fontSize: 14*zoomLevel}}>Stamp</p>
              <div className="mt-2 border-t-1 border-gray-400 w-full pt-2">
                <span style={{fontSize: 14*zoomLevel}} className="block text-center font-light">W. 150px</span>
                <span style={{fontSize: 14*zoomLevel}} className="block text-center font-light">H. 150px</span>
              </div>
            </>
          ): (
            element?.responses.length > 0 && element?.responses[0]?.signedAt ? (
              <div onClick={() => {
                if(recipientId === element.recipient) {
                  dispatch(setActiveIndex(index))
                  dispatch(setModalSignType("stamp"));
                }
              }} className="cursor-pointer h-full w-full">
                <div className={`h-full flex items-center justify-center`}>
                  <img 
                    alt="Signature" 
                    src={element.responses[0]?.value}
                    className={`pointer-events-none max-w-full max-h-full`}
                  /> 
                </div>
              </div>
            ) : (
              recipientId === element.recipient && (
                <Button 
                  title="Click here to upload stamp"
                  onClick={() => {
                    dispatch(setActiveIndex(index))
                    dispatch(setModalSignType("stamp"))
                  }}
                  style={{ fontSize: 14 * zoomLevel }}
                  classes="bg-transparent border-0 !text-[#3CA970] hover:bg-transparent !p-0 text-lg"
                />
              )
            )
          )} 
        </div>
      </div>
    </ElementsContainer>
  );
}
