import React, { useEffect, useState } from "react";
import { FilterButton, ManageBar, SearchManageInput } from "../../components";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import api from "../../utils/api";
import { useDispatch } from "react-redux";
import { setLoader } from "../../redux/app.slice";
import { FiChevronLeft, FiChevronRight } from "react-icons/fi";
import toast from "react-hot-toast";

export default function ManageMainContent() {
  const dispatch = useDispatch(); 
  const navigate = useNavigate();
  const [search, setSearchParams] = useSearchParams();
  const activeTable = search.get("status");
  const [currentPage, setCurrentPage] = useState(1);
  const [documents, setDocuments] = useState([]);
  const location = useLocation();

  async function fetchCompletedDocuments() {
    try {
      dispatch(setLoader(true));
      const response = await api.get(`/document${location.search}`);
      setDocuments(response.data);
    } catch (error) {
      toast.error("Unable to fetch documents at the moment. Please try again.");
    }
    dispatch(setLoader(false));
  }

  useEffect(() => {
    fetchCompletedDocuments();
    search.delete("reload");
    setSearchParams(search);
  }, [location.search]);

  const handlePageChange = (page) => {
    setCurrentPage(page);
    navigate(`/manage?type=document&status=${activeTable}&page=${page}`);
  };

  useEffect(() => {
    setCurrentPage(1);
  }, [activeTable]);

  return (
    <>
      <div className="w-3/4 p-4  ml-[30%] mt-4">
        <div>
          <div className="flex justify-between text-sm">
            <p>&nbsp;</p>
            <div className="relative flex items-center">
              <SearchManageInput />
              <FilterButton />
            </div>
          </div>
          <div>
            <div className="flex  flex-wrap justify-between text-xs text-gray-400 pt-6 pb-2 px-4 items-center">
              <div className="flex w-1/2">
                <p className="w-[60%]">RECIPIENTS</p>
                <p className="w-[40%]">TITLE</p>
              </div>
              <div className="flex  w-1/2">
                <p className="w-[40%]"></p>
                <p className="w-[34%]">STATUS</p>
                <p className="w-[26%]">MODIFIED</p>
              </div>
            </div>
            {documents?.length < 1 && (
              <h1 className="text-center p-12">
                No {search.get("status")} documents to show!
              </h1>
            )}
            {documents.map((document) => (
              <div key={document._id}>
                <p>{document.name}</p>
              </div>
            ))}
            {documents.map((document, index) => (
              <ManageBar key={index} data={document} />
            ))}
          </div>
        </div>
        <div className="mt-3">
          <div className="rounded-lg p-2 flex gap-2 items-center justify-end">
            <button
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={currentPage === 1}
              className="border-1 w-[35px] h-[35px] flex items-center justify-center rounded-sm"
            ><FiChevronLeft /></button>
            <span className="px-3">Page {currentPage}</span>
            <button
              onClick={() => handlePageChange(currentPage + 1)}
              className={`border-1 w-[35px] h-[35px] flex items-center justify-center rounded-sm ${
                documents?.length < 10 ? "cursor-not-allowed" : ""
              }`}
              disabled={documents?.length < 10}
            ><FiChevronRight /></button>
          </div>
        </div>
      </div>
    </>
  );
}
