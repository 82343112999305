import React, { useState, useEffect, useRef } from "react";
import {
  BsAlignBottom,
  BsAlignCenter,
  BsAlignEnd,
  BsAlignMiddle,
  BsAlignStart,
  BsAlignTop,
} from "react-icons/bs";
import { Accordion, ManageBar, ReminderModalForm } from "../../components";
import DocumentBuilder from "../../components/Builder/DocumentBuilder";
import {
  setActiveIndex,
  setBuilderStatus,
  setDocument,
  setElementToAdd,
  setElements,
  setDocumentReminder,
  setActiveRecipient
} from "../../redux/sign.slice";
import { setLoader } from "../../redux/app.slice";
import { useDispatch, useSelector } from "react-redux";
import { useParams, Link, useNavigate, useLocation } from "react-router-dom";
import api from "../../utils/api";
import IconButton from "../../components/Buttons/IconButton";
import _, { cloneDeep } from "lodash";
import Button from "../../components/Buttons/Button";
import OptionsBtn from "../../components/Buttons/OptionsBtn";
import CancelAgreementModal from "../../components/Modals/CancelAgreementModal";
import { COLORS } from "../../utils/colors";
import DocumentESign from "../../components/Dropdowns/DocumentESign";
import toast from "react-hot-toast";

export default function SignAndSend() {
  const pagesRef = useRef({});
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { documentId } = useParams();
  const [reminder, setReminder] = useState(false);
  const [relativePosition, setRelativePosition] = useState(false);
  const [cancelModalVisibility, setCancelModalVisibility] = useState(false);
  const { user } = useSelector(state => state.auth);
  const { doc, builderStatus, activeRecipient, activeIndex, movingElement } = useSelector(state => state.sign);
  const { pathname } = useLocation();
  const basePath = pathname.replace(`/${documentId}`, '');

  useEffect(() => {
    getDocument();
  }, []);

  const getDocument = async () => {
    try {
      dispatch(setLoader(true));
      const { data } = await api.get(`/document/${documentId}`);
      dispatch(setDocument(data));
      dispatch(setBuilderStatus(data.status==="draft"?"edit":"view")); // qitu
    } catch (error) {
      toast.error("Unable to locate the specified document.");
      navigate("/manage");
    }
    dispatch(setLoader(null));
  };

  const recipientSignatureField = () => {
    const recipients = doc.recipients.filter(recipient => recipient.type === "signer");
    const elements = doc.elements.filter(element => element.type === "signature" || element.type === "signatureBlock");
    const uniqueElements = _.uniq(_.map(elements, 'recipient'));

    if(recipients?.length > uniqueElements?.length){
      setCancelModalVisibility(true);
    } else updateDocument(false);
  }

  const updateDocument = async (saveProgress) => {
    try {
      dispatch(setLoader({ loading: true, message: "Submitting..." }));
      await api.post(`/document/${doc._id}`, {
        ...doc,
        saveProgress: saveProgress?true:false 
      });
      if(!saveProgress) {
        if(doc.type === "webform") navigate(`/webform/view/${doc._id}`);
        else navigate(`/send/view/${doc._id}`);
      }
    } catch (error) {
      toast.error("Unable to update the document. Please try again.");
    }
    dispatch(setLoader(null));
  }

  const ElementBtn = ({ type, title }) => {
    const handleElementAdd = () => {
      dispatch(setElementToAdd({ 
        type, 
        data: { 
          recipient: activeRecipient?activeRecipient:basePath==="/send/bulk"?null:doc.recipients[0]._id
        } 
      }));
    }

    return (
      <span
        onClick={handleElementAdd}
        className={`${type === movingElement.type && "border-1 border-gray-300 py-[3px] px-2 rounded-lg font-medium"} py-1 cursor-pointer font-light`}
      >{title}</span>
    )
  } 

  const ACCORDIONS = [
    {
      title: "Signature Fields",
      elements: [
        { type: "signature", title: "Signature" },
        { type: "initials", title: "Initials" },
        { type: "signatureBlock", title: "Signature Block" },
        { type: "stamp", title: "Stamp" }
      ]
    },
    {
      title: "Signature Info Fields",
      elements: [
        { type: "title", title: "Title" },
        { type: "company", title: "Company" },
        { type: "name", title: "Name" },
        { type: "email", title: "Email" },
        { type: "date", title: "Date" },
      ]
    },
    {
      title: "Data Fields",
      elements: [
        { type: "textInput", title: "Text Input" },
        { type: "dropdown", title: "Dropdown" },
        { type: "checkbox", title: "Checkbox" },
        { type: "radio", title: "Radio Button" },
        { type: "image", title: "Image" },
      ]
    },
    {
      title: "More Fields",
      elements: [
        { type: "fileAttachment", title: "File Attachment" },
        { type: "hyperlink", title: "Hyperlink" }
      ]
    }
  ];

  const navigateToElement = (index) => {
    if(index === "none") return;
    const element = doc.elements[index];
    pagesRef.current[element.page].scrollIntoView();
    dispatch(setActiveIndex(index));
  }

  const updateElementPosition = (type) => {
    if(activeIndex < 0 || activeIndex === null || !relativePosition) return;
    const element = cloneDeep(doc.elements[activeIndex]);
    let position = element.position;
    const pageHeight = pagesRef.current[element.page].clientHeight;
    const pageWidth = pagesRef.current[element.page].clientWidth;
    switch(type) {
      case "bottom":
        position.y = pageHeight - element.style.height;
        break;
      case "center-ver":
        position.y = (pageHeight / 2) - (element.style.height / 2);
        break;
      case "top":
        position.y = 0;
        break;
      case "left":
        position.x = 0;
        break;
      case "right":
        position.x = pageWidth - element.style.width;
        break;
      case "center-hor":
        position.x = (pageWidth / 2) - (element.style.width / 2);
        break;
    }
    element.position = position;
    dispatch(setElements({ index: activeIndex, element }))
  }

  const handleReminderSubmit = (values) => {
    setReminder(false);
    dispatch(setDocumentReminder([values]))
  }

  return (
    <div className="container py-4 pt-8">
      { builderStatus !== "edit" && builderStatus !== "draft" && (
        <ManageBar data={doc} classes="!rounded-2xl border-1.5 border-gray-300" />
      )}
      <div className={`border-2 border-gray-300 rounded-2xl w-full overflow-hidden ${builderStatus==="edit"&&"pt-4"}`}>
        { builderStatus === "edit" && (
          <div className="flex justify-between text-gray-400 mx-2">
            <div className="flex gap-4 w-1/2">
              <select className="outline-none px-2">
                <option>Field Templates</option>
              </select>
              <div className="flex items-center gap-2 ">
                <IconButton onClick={() => updateElementPosition("top")}>
                  <BsAlignTop size={22} color={relativePosition&&"black"} />
                </IconButton>
                <IconButton onClick={() => updateElementPosition("center-ver")}>
                  <BsAlignMiddle size={22} color={relativePosition&&"black"} />
                </IconButton>
                <IconButton onClick={() => updateElementPosition("bottom")}>
                  <BsAlignBottom size={22} color={relativePosition&&"black"}/>
                </IconButton>
                <IconButton onClick={() => updateElementPosition("left")}>
                  <BsAlignStart size={22} color={relativePosition&&"black"} />
                </IconButton>
                <IconButton onClick={() => updateElementPosition("center-hor")}>
                  <BsAlignCenter size={22} color={relativePosition&&"black"} />
                </IconButton>
                <IconButton onClick={() => updateElementPosition("right")}>
                  <BsAlignEnd size={22} color={relativePosition&&"black"} />
                </IconButton>
              </div>
            </div>
            <div className="flex gap-4 items-center p-2 w-1/2 justify-end ">
              <div className="flex items-center gap-2">
                <input
                  id="relativeTo"
                  type="checkbox"
                  onChange={() => setRelativePosition(!relativePosition)}
                />
                <label htmlFor="relativeTo" className="select-none">Relative to page</label>
              </div>
              <DocumentESign 
                title="Navigate to" 
                iconColor={"#9CA3AF"}
                btnClasses="border-1 border-gray-300 !py-2 !px-2 !rounded-lg text-sm !text-gray-400 min-w-[180px] !justify-between"
              >
                {doc?.elements?.map((item, index) => <OptionsBtn key={index} title={item.name} onClick={() => navigateToElement(index)} />)}
              </DocumentESign>
            </div>
          </div>
        )}

        <div className={`border-gray-300 h-[700px] flex justify-between w-full rounded-lg ${builderStatus==="edit"&&"border-t-2 mt-4"}`}>
          <div className="w-[100%] bg-gray-200">
            <DocumentBuilder 
              isSend={true} 
              classes="!w-full" 
              handleApply={() => {}} 
              pagesRef={pagesRef}
            />
          </div>
          { builderStatus === "edit" && (
            <div className="w-[20%] border-l-2 border-gray-300">
              <div className="max-h-[70%] h-[65%] overflow-scroll scrollbar-hide">
                <Accordion title="Recipients" opened={true}>
                  {basePath === "/send/bulk" ? (
                    <>
                      <div 
                        onClick={() => dispatch(setActiveRecipient(null))}
                        className={`flex cursor-pointer items-center p-1 border border-white rounded-lg gap-2 mr-2 ${!activeRecipient&&"!border-gray-300"}`}
                      >
                        <span className="w-[8px] min-w-[8px] h-[8px] rounded-full" style={{ background: COLORS[0] }}></span>
                        <p className={!activeRecipient?"font-medium":"font-light"}>Send in Bulk Signer</p>
                      </div>
                      { doc?.needToSign && doc.recipients.filter(recipient => recipient.email===user.email).map((item, index) => (
                        <div 
                          key={index} 
                          onClick={() => dispatch(setActiveRecipient(item._id))}
                          className={`flex cursor-pointer items-center p-1 border border-white rounded-lg gap-2 mr-2 ${activeRecipient===item._id&&"!border-gray-300"}`}
                        >
                          <span className="w-[8px] min-w-[8px] h-[8px] rounded-full" style={{ background: COLORS[1] }}></span>
                          <p className={activeRecipient===item._id?"font-medium":"font-light"}>
                            {item.email} (<span className="capitalize">{item.type}</span>)
                          </p>
                        </div>
                      ))}
                    </>
                  ) : (
                    doc?.recipients.map((item, index) => (
                      <div 
                        key={index} 
                        onClick={() => dispatch(setActiveRecipient(item._id))}
                        className={`flex cursor-pointer items-center p-1 border border-white rounded-lg gap-2 mr-2 ${activeRecipient===item._id&&"!border-gray-300"}`}
                      >
                        <span className="w-[8px] min-w-[8px] h-[8px] rounded-full" style={{ background: COLORS[index] }}></span>
                        <p className={activeRecipient===item._id?"font-medium":"font-light"}>
                          {item.email} (<span className="capitalize">{item.type}</span>)
                        </p>
                      </div>
                    ))
                  )}
                </Accordion>

                { ACCORDIONS.map(accordion => (
                  <Accordion title={accordion.title}>
                  { accordion.elements.map(element => (
                    <ElementBtn type={element.type} title={element.title} />
                  ))}
                  </Accordion>
                ))}
              </div>
              <div className="p-4 flex flex-col gap-6">
                <div className="flex flex-col gap-2 justify-start">
                  <Button 
                    title={"Reset"}
                    onClick={getDocument}
                    classes="border-0 !text-green-400 bg-transparent text-sm hover:bg-white w-fit px-0"
                  />
                  {doc?.type !== "webform" && (
                    <div className="text-gray-300 text-sm ">
                      <div className="flex gap-2 text-sm text-gray-400">
                        <input type="checkbox" id="reminderState" checked={reminder || doc?.reminder?.length > 0} onChange={() => setReminder(!reminder)} />
                        <label htmlFor="reminderState">Set Reminder</label>
                      </div>
                    </div>
                  )}
                </div>
                <div className="flex flex-col items-center gap-4">
                  <div className="flex gap-2">
                    <Button 
                      title={"Send"}
                      onClick={recipientSignatureField}
                      classes="text-sm"
                    />
                    {/* <Button 
                      title={"Back"}
                      onClick={() => navigate(`/send/${documentId}`)}
                      classes="border border-black !text-black bg-white text-sm hover:bg-white"
                    /> */}
                  </div>
                  <Button 
                    title={"Save Progress"}
                    onClick={() => updateDocument(true)}
                    classes="border-0 !text-green-400 bg-transparent text-sm hover:bg-white"
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      {reminder && (
        <ReminderModalForm 
          values={doc.reminder[0]}
          onClose={() => {
            setReminder(false);
            dispatch(setDocumentReminder([]));
          }}
          handleFormSubmit={(values) => handleReminderSubmit(values)}
        />
      )}
      <CancelAgreementModal
        isOpen={cancelModalVisibility}
        label="Add a signature field"
        labelParagraph={`
          Go back to show recipients where to sign by adding signature fields. 
          Select Continue to automatically place a signature field for each recipient to the end of your document.
        `}
        onClose={() => setCancelModalVisibility(false)}
        onSubmit={() => updateDocument(false)}
        yesLabel="Continue"
        noLabel="Go back"
      />
    </div>
  );
}
