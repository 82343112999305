import { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import ElementsContainer from "../ElementsContainer";
import useSetElementResponse from '../../../hooks/useSetElementResponse';

export default function RadioField({ index, element }) {
  const { setResponse } = useSetElementResponse(index, element);
  const { builderStatus, zoomLevel } =  useSelector(state => state.sign);

  useEffect(() => {
    if(builderStatus !== "fill") return;
    setResponse({ value: element.value.defaultValue });
  }, [])

  const handleChange = (e) => {
    if(element.isReadonly || builderStatus !== "fill") return;
    const value = e.target.value;
    setResponse({ value });
  }

  const VALUE = useMemo(() => {
    if(builderStatus === "edit") return element.value.defaultValue;
    return element.responses[0]?.value;
  }, [element]);

  return element.value.options.map((item, key) => (
    <ElementsContainer 
      key={key}
      index={index}
      element={element}
      lockAspectRatio={false}
      resizable={false}
      radioIndex={key}
    >
      <div className='h-full w-full flex'>
        <input 
          type='radio'
          value={item}
          name={element.uuid}
          onChange={handleChange}
          checked={VALUE === item}
          readOnly={element.isReadonly || builderStatus !== "fill"}
          className='w-full h-full border-b-1 bg-transparent border-gray-500 px-1 focus:outline-none placeholder-black'
          style={{ 
            color: element.style.color,
            textAlign: element.style.textAlign,
            fontSize: element.style.fontSize*zoomLevel, 
          }}
        />
      </div>
    </ElementsContainer>
  ));
}
