import React, { useState } from "react";

const FileImport = ({ error, files = [], setFiles, isDisabled }) => {
  const getFileName = (name) => {
    return name.substring(0, name.lastIndexOf('.'))
  }

  const handleFileChange = (event) => {
    if(isDisabled) return;
    const selectedFiles = event.target.files;
    if (selectedFiles.length > 0) {
      setFiles("files", [...selectedFiles]);
      setFiles("agreementName", getFileName(selectedFiles[0].name))
    }
  };

  const handleDrop = (event) => {
    if(isDisabled) return;
    event.preventDefault();
    const droppedFiles = event.dataTransfer.files;

    if (droppedFiles.length > 0) {
      setFiles("files", [...droppedFiles]);
      setFiles("agreementName", getFileName(droppedFiles[0].name))
    }
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  return (
    <div className="mt-3 mb-1">
      <h1 className="text-[22px] mb-1 font-normal">Files</h1>
      <div
        className={`border flex items-center rounded-lg`}
        onDrop={handleDrop}
        onDragOver={handleDragOver}
      >
        <div className={`w-[200px] min-h-[120px] flex flex-col justify-center border m-4 p-3 rounded-lg ${error && 'bg-red-50 border-red-500'}`}>
          {files.length > 0 ? (
            <div>
              <ul>
                {files.map((file, index) => (
                  <li className="truncate" key={index}>{file.name || file }</li>
                ))}
              </ul>
            </div>
          ) : (
            <p className={`text-center ${error&&"text-red-900"}`}>No files selected</p>
          )}
        </div>
        <div className="flex flex-col gap-6 items-center mx-8 text-sm">
          <p className="text-gray-400">Drag & Drop Files Here</p>
          <div className="border-[0.5px] border-gray-400 w-[20%]"></div>
          <label className={`text-white py-2 px-4 rounded-lg cursor-pointer hover-bg-green-hover transition text-sm ${isDisabled?"bg-gray-400":"bg-green"}`}>
            <input type="file" disabled={isDisabled} className="hidden" onChange={handleFileChange} multiple accept="application/pdf" />
            Add Files
          </label>
        </div>
      </div>

      { error && <p className="text-sm text-red-600 mt-2">{error}</p>}
    </div>
  );
};

export default FileImport;
