import React from "react";
import Button from "../../Buttons/Button"
import Accordion from "./atoms/Accordion"
import useElementCustomize from "../../../hooks/useElementCustomize";
import UpdateRecipient from "./atoms/UpdateRecipient";
import ElementName from "./atoms/ElementName";
import ElementFooter from "./atoms/ElementFooter";
import FieldType from "./atoms/FieldType";
import Checkbox from "../../Inputs/Checkbox";
import ElementAppearance from "./atoms/ElementAppearance";

const CheckboxFieldCustomize = ({ 
  index, 
  element
}) => {
  const {
    copyElement,
    cloneElement,
    updateElement, 
    deleteElement, 
    cancelCustomize,
    changeElementType,
    updateElementFields
  } = useElementCustomize(index, element);
  
  const onCheckboxChange = (event, key) => {
    const { id, checked } = event.target;
    if(key) updateElementFields(`${key}.${id}`, checked);
    else updateElementFields(`${id}`, checked);
  }

  const onInputChange = (event, key) => {
    const { id, value } = event.target;
    console.log(id, value);
    updateElementFields(`${key}.${id}`, value);
  }

  return (
    <>
      <ElementName 
        element={element}
        updateElementFields={updateElementFields}
      />

      <UpdateRecipient 
        element={element} 
        updateElementFields={updateElementFields} 
      />

      <div className="my-2 mt-3 grid grid-cols-2 justify-between">
        <Checkbox 
          id="isRequired"
          name="isRequired"
          label="Required"
          checked={element.isRequired}
          onChange={(e) => onCheckboxChange(e)}
        />
        <Checkbox 
          id="isReadonly"
          name="isReadonly"
          label="Readonly"
          checked={element.isReadonly}
          onChange={(e) => onCheckboxChange(e)}
        />
      </div>

      <div className="my-2">
        <label htmlFor="defaultValue" className="block mb-1 text-xs font-normal text-gray-900">Default Value</label>
        <select 
          id="defaultValue" 
          className="rounded-md p-1 border border-gray-400 font-300 text-[12px] w-full" 
          onChange={(e) => onInputChange(e, "value")}>
          <option value={true} selected={element.value.defaultValue}>Checked</option>
          <option value={false} selected={!element.value.defaultValue}>Unchecked</option>
        </select>
      </div>

      <div className="my-2">
        <label htmlFor="tooltip" className="block mb-1 text-xs font-normal text-gray-900">Tooltip</label>
        <div className="relative">
          <input 
            id="tooltip" 
            type="text" 
            onChange={(e) => onInputChange(e, "value")}
            value={element.value.tooltip}
            className="bg-white border border-gray-400 text-gray-900 text-[12px] rounded-lg block w-full p-1" 
          />
        </div>
      </div>

      <hr className="mt-2 mb-0 w-2/3" />

      <ElementAppearance 
        element={element}
        updateElementFields={updateElementFields}
      />

      <hr className="mt-0.5 mb-0 w-2/3" />

      <Accordion title={"Tools"}>
        <div className="mb-2">
          <Button 
            title="Clone Field"
            onClick={cloneElement} 
            classes="!p-0 text-[12px] bg-transparent border-0 !text-[#3CA970] hover:bg-transparent" 
          />
          <Button 
            title="Copy Field"
            onClick={copyElement} 
            classes="!p-0 text-[12px] bg-transparent border-0 !text-[#3CA970] hover:bg-transparent" 
          />
        </div>
      </Accordion>

      <ElementFooter 
        deleteElement={deleteElement}
        updateElement={updateElement}
        cancelCustomize={cancelCustomize}
      />
    </>
  );
};

export default CheckboxFieldCustomize;
