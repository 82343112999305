import React from "react";
import { FilterButton, ManageBar, SearchManageInput } from "../../components";
// import ReactSpeedometer from "react-d3-speedometer";

export default function ReportsMainContainer() {
  return (
    <div className="w-3/4 p-4  ml-[30%] mt-4">
      <div>
        <div className="flex justify-between text-sm">
          <p>In progress (251)</p>
          <div className="relative flex items-center">
            <SearchManageInput />
            <FilterButton />
          </div>
        </div>
        <div className="flex justify-between">
          <div className="border rounded h-full w-[40%] mt-4 p-2">
            <div className="flex flex-col p-4 justify-center gap-6 ">
              <div className="flex items-center gap-6">
                <img src="/assets/images/reports.svg" alt="report" />
                <p className="text-[40px] font-semibold">12</p>
                <p>Total agreements</p>
              </div>
              <div className="flex items-center gap-6">
                <img src="/assets/images/reports.svg" alt="report" />
                <p className="text-[40px] font-semibold">12</p>
                <p>Total agreements</p>
              </div>
              <div className="flex items-center gap-6">
                <img src="/assets/images/reports.svg" alt="report" />
                <p className="text-[40px] font-semibold">12</p>
                <p>Total agreements</p>
              </div>
              <div className="flex items-center gap-6">
                <img src="/assets/images/reports.svg" alt="report" />
                <p className="text-[40px] font-semibold">12</p>
                <p>Total agreements</p>
              </div>
              <div className="flex items-center gap-6">
                <img src="/assets/images/reports.svg" alt="report" />
                <p className="text-[40px] font-semibold">12</p>
                <p>Total agreements</p>
              </div>
            </div>
          </div>
          <div className=" h-full w-[50%] mt-4 p-2">
            <div className="border rounded flex flex-col p-4 justify-center items-center gap-6" >
              <p>Agreement completed</p>
              {/* <ReactSpeedometer
                maxValue={100}
                value={100}
                valueFormat={"d"}
                customSegmentStops={[0, 25, 50, 75, 100]}
                segmentColors={["#3CA970", "#3CA970", "#3CA970", "#3CA970"]}
                currentValueText={"${value} %"}
                textColor={"black"}
                height={200}
                needleColor="gray"
              /> */}
            </div>
            <div className="border rounded flex flex-col p-4 justify-center items-center gap-6 mt-4 ">
              <p>Average time to complete</p>
              {/* <ReactSpeedometer
                maxValue={100}
                value={75}
                valueFormat={"d"}
                customSegmentStops={[0, 25, 50, 75, 100]}
                segmentColors={["#3CA970", "#3CA970", "#3CA970", "#3CA970"]}
                currentValueText={"${value} %"}
                textColor={"black"}
                height={200}
                needleColor="gray"
              /> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
