import ElementsContainer from "../ElementsContainer";
import { useDispatch, useSelector } from "react-redux";
import Button from "../../Buttons/Button"
import { setActiveIndex, setModalSignType } from "../../../redux/sign.slice";
import { useParams } from "react-router-dom";
import ScaleText from "react-scale-text";
import ScalableText from "../../ScalableText/ScalableText";

export default function Initials({ index, element }) {
  const dispatch = useDispatch();
  const { recipientId } = useParams(); 
  const { builderStatus, zoomLevel } = useSelector((state) => state.sign);
  
  return (
    <ElementsContainer 
      index={index}
      element={element}
      lockAspectRatio={false}
    >
      <div className={`flex items-center h-full overflow-hidden ${builderStatus === "edit" && "border-b-1 border-gray-400"} cursor-pointer`}
        onClick={() => {
          if (recipientId === element?.recipient) {
            dispatch(setActiveIndex(index));
            dispatch(setModalSignType("initials"));
          }
        }} 
      >
        <span style={{
          position: "absolute",
          width: "15px",
          height: "2px", 
          backgroundColor: "#3CA970",
          right: "-22px",
        }}></span>
        <span style={{
          position: "absolute",
          top: "50%",
          right: "-10px",
          transform: "translateY(-50%)",
          width: "10px",
          height: "15px",
          backgroundColor: "#3CA970",
          clipPath: "polygon(0% 50%, 100% 0%, 100% 100%)"
        }}></span>
        { builderStatus === "edit" && (
          <ScalableText 
            text="Initials"
            minFontSize={4} 
            maxFontSize={10}
            classes="whitespace-nowrap"
          />
        )}
        { element?.responses.length > 0 && element?.responses[0]?.signedAt ? (
          <div onClick={() => {
            if(recipientId === element.recipient) {
              dispatch(setActiveIndex(index))
              dispatch(setModalSignType("initials"));
            }
          }} className="cursor-pointer h-full w-full">
            {element?.responses[0]?.type === "type" && (
              <div className="max-w-full mx-auto my-0 select-none h-full flex flex-col">
                <div style={{height: element.style.height * zoomLevel * 0.6}}>
                  <ScalableText 
                    text={element.responses[0]?.value}
                    classes="whitespace-nowrap font-[Allura]"
                  />
                </div>
                <div 
                  style={{height: element.style.height * zoomLevel * 0.4}}
                  className="border-t border-t-1 border-green-500 flex flex-nowrap justify-between overflow-hidden"
                >
                  <ScalableText 
                    minFontSize={4} 
                    maxFontSize={10}
                    text={element.responses[0]?.fullName}
                    classes="whitespace-nowrap text-green-400" 
                  />
                </div>
              </div>
            )}
            { element?.responses[0]?.type !== "type" && (
              <div className="h-full flex flex-col">
                <div style={{height: element.style.height * zoomLevel * 0.6}}>
                  <img 
                    src={element.responses[0]?.value}
                    alt="Signature" 
                    className={`pointer-events-none max-w-[100%] max-h-full`}
                  /> 
                </div>
                <div 
                  style={{height: element.style.height * zoomLevel * 0.4}}
                  className="border-t border-t-1 border-green-500 flex flex-nowrap justify-between overflow-hidden"
                >
                  <ScalableText 
                    minFontSize={4} 
                    maxFontSize={10}
                    text={element.responses[0]?.fullName}
                    classes="whitespace-nowrap text-green-400" 
                  />
                </div>
              </div>
            )}
          </div>
        ) : (
          recipientId === element.recipient && (
            <Button 
              title={
                <ScalableText 
                  text="Initials"
                  minFontSize={4} 
                  maxFontSize={10}
                  classes="whitespace-nowrap text-green-400" 
                />
              }
              onClick={() => {
                dispatch(setActiveIndex(index))
                dispatch(setModalSignType("initials"))
              }}
              style={{ fontSize: 14 * zoomLevel }}
              classes="bg-transparent border-0 !text-[#3CA970] hover:bg-transparent !p-0 text-lg"
            />
          )
        )}  
      </div>
    </ElementsContainer>
  );
}
