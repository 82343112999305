import ElementsContainer from "../ElementsContainer";
import style from "../style.module.css";
import { useSelector } from "react-redux";
import { useRef } from "react";
import toast from "react-hot-toast";
import api, { API_URL } from "../../../utils/api";
import useSetElementResponse from "../../../hooks/useSetElementResponse";

export default function ImageField({ index, element }) {
  const hiddenFileInput = useRef(null);
  const { builderStatus, activeIndex, zoomLevel } = useSelector((state) => state.sign);
  const isActive = activeIndex === index && builderStatus === "edit";
  const { setResponse } = useSetElementResponse(index, element);

  const handleClick = () => {
    if(builderStatus !== "fill" || element.isReadonly) return;
    hiddenFileInput.current.click();
  };

  const handleChange = async (event) => {
    try {
      const file = event.target.files[0];
      if(!file) return;

      const MAX_FILE_SIZE = 5120;
      const ALLOWED_TYPES = ["image/jpeg", "image/png"];

      const fileSizeKiloBytes = file.size / 1024;
      if(fileSizeKiloBytes > MAX_FILE_SIZE){
        throw "File size is greater than maximum limit!";
      }
      if(!ALLOWED_TYPES.includes(file.type)) {
        throw "Invalid file type!";
      }

      const formData = new FormData();
      formData.append("files", file);
      const { data } = await api.post(`/media/tmp/upload`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' }
      });
      console.log(data.filename, "data.filename")
      setResponse({ type: file.type, value: data.filename });
    } catch(error){
      console.log(error)
      toast.error(error.message);
    }
  };
  
  return (
    <ElementsContainer 
      index={index}
      element={element}
      lockAspectRatio={true}
      initialResizeHandleClasses={{ 
        right: isActive ? style.resizeDotRightCenter : null, 
        bottomRight: "pointer-events-none" 
      }}
    >
      <div className="h-full">
        <div className="flex items-center h-full cursor-pointer" onClick={handleClick}>
          <input 
            type="file" 
            className="hidden" 
            ref={hiddenFileInput}
            onChange={handleChange}
            accept="image/jpeg,image/jpg,image/png"
          />
          { builderStatus === "edit" ? (
            <div className="flex flex-col justify-center w-full">
              <p style={{fontSize: 14*zoomLevel}} className="text-center">Image</p>
              <div className="mt-2 border-t-1 border-gray-400 w-full pt-2">
                <span style={{fontSize: 14*zoomLevel}} className="block text-center font-light">W. 150px</span>
                <span style={{fontSize: 14*zoomLevel}} className="block text-center font-light">H. 150px</span>
              </div>
            </div>
          ) : (
            element?.responses.length <= 0 ? (
              <div className="flex flex-col justify-center w-full">
                <p style={{fontSize: 14*zoomLevel}} className="text-center">Click to upload image</p>
                <div className="mt-2 border-t-1 border-gray-400 w-full pt-2">
                  <span style={{fontSize: 14*zoomLevel}} className="block text-center font-light">W. 150px</span>
                  <span style={{fontSize: 14*zoomLevel}} className="block text-center font-light">H. 150px</span>
                </div>
              </div>
            ) : (
              <img 
                src={`${API_URL}media/view/${element.responses[0].value}`}
                alt={element.name}
                className="w-full h-full object-cover"
              />
            )
          )}
        </div>
      </div>
    </ElementsContainer>
  );
}
