import { useSelector } from 'react-redux';
import ElementsContainer from "../ElementsContainer";
import useSetElementResponse from '../../../hooks/useSetElementResponse';
import { useMemo } from 'react';

export default function DropdownField({ index, element }) {
  const { builderStatus, zoomLevel } =  useSelector(state => state.sign);
  const { setResponse } = useSetElementResponse(index, element);

  const handleChange = (e) => {
    const value = e.target.value;
    if(!OPTIONS.includes(value) || value==="Select") return;
    setResponse({ value });
  }

  const OPTIONS = useMemo(() => {
    if(!element.value.options) return ["Select"];
    const lines = element.value.options.split('\n');
    const trimmedLines = lines.map(line => line.trim());
    const nonEmptyLines = trimmedLines.filter(line => line !== '');
    return [...["Select"], ...nonEmptyLines];
  }, [element.value.options])

  return (
    <ElementsContainer 
      index={index}
      element={element}
      lockAspectRatio={false}
      resizable={false}
    >
      <div className='h-full w-full flex'>
        <select
          style={{ 
            color: element.style.color,
            textAlign: element.style.textAlign,
            fontSize: element.style.fontSize*zoomLevel, 
          }}
          onChange={handleChange}
          disabled={builderStatus !== "fill"}
          className={`w-full h-full border-b-1 bg-transparent border-gray-500 px-1 placeholder-black ${builderStatus==="edit"&&"pointer-events-none"}`}
        >
          {OPTIONS.map(item => <option value={item} selected={element.value.defaultValue === item}>{item}</option>)}
        </select>
      </div>
    </ElementsContainer>
  );
}
