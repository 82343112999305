import moment from "moment/moment";

export function checkIfFilesAreTooBig(files) {
  let valid = true;
  if (!files || files.length < 1) return false;
  files.map(file => {
    const size = file.size / 1024 / 1024 // > 10MB
    if (size > 10) valid = false
  })
  return valid;
}

export function checkIfFilesAreCorrectType(files) {
  let valid = true;
  if (!files || files.length < 1) return false;
  files.map(file => {
    if (!['application/pdf'].includes(file.type) && typeof file !== 'string') valid = false
  })
  return valid
}

export function dateFormat(date, format="MM/DD/YYYY"){
  return moment(date).format(format)
}