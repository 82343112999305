import React from "react";
import Button from "../../Buttons/Button"
import Accordion from "./atoms/Accordion"
import useElementCustomize from "../../../hooks/useElementCustomize";
import UpdateRecipient from "./atoms/UpdateRecipient";
import ElementName from "./atoms/ElementName";
import ElementFooter from "./atoms/ElementFooter";
import Checkbox from "../../Inputs/Checkbox";
import IconButton from "../../Buttons/IconButton";
import { FaPlus } from "react-icons/fa6";
import { FaRegTrashAlt } from "react-icons/fa";

const RadioFieldCustomize = ({ 
  index, 
  element
}) => {
  const {
    copyElement,
    updateElement, 
    deleteElement, 
    cancelCustomize,
    updateElementFields
  } = useElementCustomize(index, element);
  
  const onCheckboxChange = (event, key) => {
    const { id, checked } = event.target;
    if(key) updateElementFields(`${key}.${id}`, checked);
    else updateElementFields(`${id}`, checked);
  }

  const onInputChange = (event, key) => {
    const { id, value } = event.target;
    updateElementFields(`${key}.${id}`, value);
  }

  const removeOption = (key) => {
    const newOptions = [...element.value.options];
    const newPosition = [...element.position];
    newOptions.splice(key, 1);
    newPosition.splice(key, 1);
    updateElementFields([`value.options`, `position`], [newOptions, newPosition]);
  }
  
  const addOption = () => {
    const newOptions = [...element.value.options];
    newOptions.push("");
    updateElementFields([`value.options`, `position.[${newOptions.length-1}]`], [
      newOptions, 
      { x: element.position[0].x + 20, y: element.position[0].y + 20 }
    ]);
  }

  return (
    <>
      <ElementName 
        element={element}
        updateElementFields={updateElementFields}
      />

      <UpdateRecipient 
        element={element} 
        updateElementFields={updateElementFields} 
      />

      <div className="my-2 mt-3 grid grid-cols-2 justify-between">
        <Checkbox 
          id="isRequired"
          name="isRequired"
          label="Required"
          checked={element.isRequired}
          onChange={(e) => onCheckboxChange(e)}
        />
        <Checkbox 
          id="isReadonly"
          name="isReadonly"
          label="Readonly"
          checked={element.isReadonly}
          onChange={(e) => onCheckboxChange(e)}
        />
      </div>

      <div className="my-2">
        <label htmlFor="defaultValue" className="block mb-1 text-xs font-normal text-gray-900">Default Value</label>
        <select 
          id="defaultValue" 
          onChange={(e) => onInputChange(e, "value")}
          className="rounded-md p-1 border border-gray-400 font-300 text-[12px] w-full" 
        >
          {element.value.options.map(item => <option value={item} selected={element.value.defaultValue === item}>{item}</option>)}
        </select>
      </div>

      <div className="my-2">
        <div className="flex justify-between items-center">
          <label className="block mb-1 text-xs font-normal text-gray-900">Options</label>
          <IconButton classes="!p-0" onClick={addOption}>
            <FaPlus size={12} />
          </IconButton>
        </div>
        <div className="relative">
          { element.value.options.map((option, key) => (
            <div className="flex items-center mb-1.5">
              <input 
                type="text" 
                value={option}
                id={`options[${key}]`}
                onChange={(e) => onInputChange(e, "value")}
                className="bg-white border border-gray-400 text-gray-900 text-[12px] rounded-lg block w-full p-1" 
              />
              {element.value.options.length > 2 && (
                <IconButton onClick={() => removeOption(key)} classes="!pr-0 py-1">
                  <FaRegTrashAlt size={12} />
                </IconButton>
              )}
            </div>
          ))}
        </div>
      </div>

      <div className="my-2">
        <label htmlFor="tooltip" className="block mb-1 text-xs font-normal text-gray-900">Tooltip</label>
        <div className="relative">
          <input 
            id="tooltip" 
            type="text" 
            onChange={(e) => onInputChange(e, "value")}
            value={element.value.tooltip}
            className="bg-white border border-gray-400 text-gray-900 text-[12px] rounded-lg block w-full p-1" 
          />
        </div>
      </div>

      <hr className="mt-2 mb-0 w-2/3" />

      <Accordion title={"Tools"}>
        <div className="mb-2">
          <Button 
            title="Copy Field"
            onClick={copyElement} 
            classes="!p-0 text-[12px] bg-transparent border-0 !text-[#3CA970] hover:bg-transparent" 
          />
        </div>
      </Accordion>

      <ElementFooter 
        deleteElement={deleteElement}
        updateElement={updateElement}
        cancelCustomize={cancelCustomize}
      />
    </>
  );
};

export default RadioFieldCustomize;
