import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { userLogin } from "../../redux/auth.slice";
import { Formik } from "formik";
import { Link } from "react-router-dom";
import * as Yup from "yup";
import Input from "../../components/Inputs/Input";
import Button from "../../components/Buttons/Button";

export default function Login() {
  const dispatch = useDispatch();
  const { isLoading, message } = useSelector((state) => state.auth);
  const LoginSchema = Yup.object().shape({
    email: Yup.string().email().required("Email is required."),
    password: Yup.string().required("Password is required."),
  });

  return (
    <div className="grid grid-cols-2 h-[100vh] items-center">
      <div className="w-full ">
        <div className="mx-auto px-8 bg-white max-w-md overflow-hidden">
          <div className="flex flex-col items-center">
            <h1 className="text-2xl font-semibold mb-4">Welcome back</h1>
            <p className="text-gray-600 mb-6">
              Welcome back! Please enter your details.
            </p>
          </div>
          <Formik
            initialValues={{ email: "", password: "" }}
            validationSchema={LoginSchema}
            onSubmit={(values) => {
              dispatch(userLogin(values));
            }}
          >
            {({ errors, touched, values, handleChange, handleSubmit }) => (
              <form onSubmit={handleSubmit} className="flex flex-col">
                {message && (
                  <div
                    className="p-4 mb-4 text-sm text-red-800 rounded-lg bg-red-50"
                    role="alert"
                  >
                    <span className="font-medium">Error!</span> {message}
                  </div>
                )}
                <div className="mb-4">
                  <Input
                    name="email"
                    label="Email"
                    handleChange={handleChange}
                    placeholder="Enter your email"
                    error={errors.email && touched.email ? errors.email : null}
                  />
                </div>
                <div className="mb-4">
                  <Input
                    type="password"
                    name="password"
                    label="Password"
                    handleChange={handleChange}
                    placeholder="Enter your password"
                    error={
                      errors.password && touched.password
                        ? errors.password
                        : null
                    }
                  />
                </div>
                <div className="flex justify-end items-center mb-4">
                  <Link
                    to="/forgot-password"
                    className="text-sm text-[#3CA970] hover:underline "
                  >
                    Forgot Password?
                  </Link>
                </div>
                <div className="flex justify-center">
                  <Button 
                    type="submit" 
                    title="Log In" 
                    classes="px-4" 
                    isLoading={isLoading} 
                  />
                </div>
              </form>
            )}
          </Formik>
        </div>
      </div>
      <div className="hidden lg:block overflow-hidden rounded rounded-l-[45px] h-[100%] w-[100%]">
        <img
          src="/assets/images/loginImage.png"
          alt="login"
          className="h-[100%] w-[100%] object-cover"
        />
      </div>
    </div>
  );
}
