import React, { useEffect, useRef, useState } from "react";

const ScalableText = ({ text, classes, initialFontSize=16, minFontSize=8, maxFontSize=30 }) => {
  function useScaleText(initialFontSize, minFontSize, maxFontSize) {
    const [fontSize, setFontSize] = useState(initialFontSize);
    const ref = useRef(null);
  
    useEffect(() => {
      const observer = new ResizeObserver((entries) => {
        if (entries && entries.length > 0) {
          const entry = entries[0];
          const parentWidth = entry.contentRect.width;
          const parentHeight = entry.contentRect.height;
          const scaleFactor = Math.min(parentWidth / 100, parentHeight / 100) * 2; // Adjust the multiplier as needed
          const newFontSize = Math.floor(initialFontSize * scaleFactor);
          console.log(newFontSize)
          if(newFontSize>maxFontSize) setFontSize(maxFontSize);
          else if(newFontSize<minFontSize) setFontSize(minFontSize);
          else setFontSize(newFontSize);
        }
      });
  
      if (ref.current) {
        observer.observe(ref.current.parentElement);
      }
  
      return () => {
        if (ref.current) {
          observer.unobserve(ref.current.parentElement);
        }
      };
    }, [initialFontSize]);
  
    return { fontSize, ref };
  }

  const { fontSize, ref } = useScaleText(initialFontSize, minFontSize, maxFontSize);

  return (
    <div ref={ref} className="w-full h-full flex items-center">
      <p style={{ fontSize }} className={`w-full ${classes}`}>{text}</p>
    </div>
  );
};

export default ScalableText;
