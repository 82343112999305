import React from "react";
import Button from "../Buttons/Button";
import Input from "../Inputs/Input";
import { Formik } from "formik";
import * as Yup from "yup";

export default function RequireEmailModal({
  isOpen,
  onSubmit,
  onClose
}) {
  const ModalSchema = Yup.object().shape({
    email: Yup.string().email("Enter a valid email.").required("Email is required."),
  });

  return (
    <div
      className={`${
        isOpen ? "block" : "hidden"
      } fixed inset-0 overflow-y-auto z-[99999] flex justify-center items-center`}
    >
      <div className="modal-overlay fixed inset-0 bg-gray-500 opacity-50"></div>
      <div className="relative mx-auto w-[450px] px-8 py-6 bg-white rounded-xl shadow-lg">
        <Formik
          initialValues={{ email: "" }}
          validationSchema={ModalSchema}
          onSubmit={onSubmit}
          enableReinitialize
        >
          {({ handleChange, handleSubmit, values }) => (
            <form onSubmit={handleSubmit} className="flex flex-col">
              <div className="mb-4">
                <Input
                  name="email"
                  label={"Please enter your email and then click to sign this document."}
                  handleChange={handleChange}
                  placeholder="Email"
                  labelClasses="text-base"
                  value={values.email}
                />
              </div>
              <div className="flex justify-center">
                <Button type="submit" title="Click to sign" classes="text-sm mr-3 !py-1.5" />
                <Button
                  title="Cancel"
                  onClick={onClose}
                  classes="bg-white border-gray-400 !hover:bg-gray-200 !text-black text-sm !py-1.5"
                />
              </div>
            </form>
          )}
        </Formik>
      </div>
    </div>
  );
}
