import React, { useEffect } from "react";
import { useParams, Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setDocument } from "../../redux/sign.slice";
import { setLoader } from "../../redux/app.slice";
import toast from "react-hot-toast";
import api from "../../utils/api";
import DocumentBuilder from "../../components/Builder/DocumentBuilder";
import { Document, Page } from "react-pdf";
import { API_URL } from "../../utils/api";

export default function SuccessfullyInfo() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { documentId } = useParams();
  const { doc } = useSelector((state) => state.sign);

  useEffect(() => {
    getDocument(documentId);
  }, [documentId]);

  const getDocument = async () => {
    try {
      dispatch(setLoader(true));
      const { data } = await api.get(`/document/${documentId}`);
      dispatch(setDocument(data));
    } catch (error) {}
    dispatch(setLoader(null));
  };

  return (
    <div className="container pt-12">
      <div className="flex w-full gap-12">
        <div className="w-1/2">
          <h1 className="text-[30px]">
            Document {`"${doc?.agreementName}"`} has been succesfully sent for
            signature
          </h1>
          <p className="py-4">
            A copy has also been sent to at all the <b>system users</b> for their
            record. Document was sent for signature to{" "}
            <b>
            {doc?.recipients
              ? doc?.recipients?.map((recipient, index) => (
                  <span key={index}>
                    {recipient.email}
                    {index < doc?.recipients?.length - 1 ? ", " : ""}{" "}
                  </span>
                ))
              : `-`}
            </b>. As soon as the document is completed will be sent to all parties via email.
          </p>
          <p className="text-[20px] pt-4">Reminders</p>
          <p className="my-2">There are no reminders set for this document</p>
          <p className="text-[20px] pt-4">You will be alerted:</p>
          <p className="my-2">
            If the document you sent has not been viewed for 8 hours, all
            recipients will be notified by email!{" "}
          </p>
          <p className="text-[20px] pt-4">What's next</p>
          <div className="flex flex-col justify-start items-start pt-2">
            <button className="text-green-500" onClick={() => navigate("/")}>
              Send Another Document
            </button>
            <button
              className="text-green-500"
              onClick={() => navigate(`/send/single/${documentId}`)}
            >
              Manage This Document
            </button>
          </div>
        </div>
        <div className="border">
          <Document file={`${API_URL}pdf/${doc?.files[0]}`}>
            <Page 
              renderTextLayer={false}
              renderAnnotationLayer={false}
              customTextRenderer={() => {}}
              pageNumber={1} 
              style={{height:"200px"}}
            />
          </Document>
        </div>
      </div>
    </div>
  );
}
