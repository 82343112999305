import React, { useEffect, useRef } from "react";
import SignatureCanvas from "react-signature-canvas";
import { useFormik } from "formik";
import DynamicSvg from "../DynamicsSvg/DynamicsSvg";
import * as Yup from "yup";
import Button from "../Buttons/Button";
import { useSelector } from "react-redux";

const SignatureModal = ({ 
  handleApply, 
  handleClose,
  element
}) => {
  const signatureCanvasRef = useRef();
  const { modalSignType } = useSelector(state => state.sign)

  useEffect(() => {
    if(element && element.responses[0]?.type === "draw" && signatureCanvasRef) {
      signatureCanvasRef.current.fromDataURL(element.responses[0]?.value, {width: 400, height: 80});
    }
  }, [signatureCanvasRef, element]);

  const handleImageUpload = async (e, setFieldValue) => {
    const file = e.target.files[0];
    const base64 = await fileBase64(file);

    const img = new Image();
    img.src = base64;

    img.onload = () => {
      const cropX = img.width / 2 - 150;
      const cropY = img.height / 2 - 60;
      const cropWidth = 300;
      const cropHeight = 120;

      const canvas = document.createElement("canvas");
      if(modalSignType === "stamp") {
        const max_size = 300;
        let width = img.width;
        let height = img.height;
        if (width > height) {
          if (width > max_size) {
            height *= max_size / width;
            width = max_size;
          }
        } else {
          if (height > max_size) {
            width *= max_size / height;
            height = max_size;
          }
        }
        canvas.width = width;
        canvas.height = height;
        console.log(width, height)
        canvas.getContext('2d').drawImage(img, 0, 0, width, height);
      }else {
        canvas.width = cropWidth;
        canvas.height = cropHeight;
        const ctx = canvas.getContext("2d");
        ctx.drawImage(
          img,
          cropX,
          cropY,
          cropWidth,
          cropHeight,
          0,
          0,
          cropWidth,
          cropHeight
        );
      }
      const croppedBase64 = canvas.toDataURL("image/png");
      setFieldValue("image", croppedBase64);
    };
  };

  const SignSchema = Yup.object().shape({
    type: Yup.string().when("input", {
      is: (value) => value === "type",
      then: (schema) => schema.required("Full Name or Initials is required!")
        .max(modalSignType==="initials"?4:1000000)
    }),
    fullName: Yup.string().when("input", {
      is: (value) => value === "draw" || value === "image", // type, draw, image
      then: (schema) => schema.required("Full Name is required!")
    }),
    draw: Yup.string().when("input", {
      is: (value) => value === "draw", // type, draw, image
      then: (schema) => schema.required("Signature is required!")
    }),
    image: Yup.string().when("input", {
      is: (value) => value === "image", // type, draw, image
      then: (schema) => schema.required("Image signature is required!")
    }),
  });

  const initialValues = {
    input: modalSignType === "stamp"?"image" : element?.responses[0]?.type || "type",
    type: element?element.responses[0]?.type==="type"?element.responses[0].value:"":"",
    fullName: element?element.responses[0]?.type!=="type"?element.responses[0]?.fullName:"":"",
    draw: element?element.responses[0]?.type==="draw"?element.responses[0].value:"":"",
    image: element?element.responses[0]?.type==="image"?element.responses[0].value:"":""
  }

  const {
    errors,
    values,
    touched,
    setFieldValue,
    handleChange,
    handleSubmit,
    setTouched,
    setErrors
  } = useFormik({
    initialValues,
    onSubmit: (values) => handleApply(values),
    validationSchema: SignSchema
  });

  useEffect(() => {
    if(values.input === "draw" && values.draw) {
      signatureCanvasRef?.current?.fromDataURL(values.draw, {width: 400, height: 80});
    }
  }, [values.input])

  const fileBase64 = async (img) => {
    return new Promise((resolve, reject) => {
      let fileReader = new FileReader();
      fileReader.onerror = reject;
      fileReader.onload = function () {
        resolve(fileReader.result);
      };
      fileReader.readAsDataURL(img);
    });
  };

  const changeInputType = (type) => {
    setFieldValue("input", type);
    setTouched({}, false);
    setErrors({});
  }

  const resetForm = () => {
    if(values.input === "type") setFieldValue("type", "");
    else {
      if(values.input === "draw") {
        setFieldValue("draw", "");
        signatureCanvasRef.current.clear();
      } else setFieldValue("image", "");
      setFieldValue("fullName", "");
    }
    setTouched({}, false);
    setErrors({});
  }

  return (
    <div className="fixed top-0 left-0 w-full w-100vw h-full flex justify-center items-center bg-gray-800 bg-opacity-50 z-[99999999]">
      <div className="bg-white rounded-2xl md:w-[45%] w-[90%]">
        <form onSubmit={handleSubmit}>
          <div className="rounded-2xl bg-[#F8F8F8] border-b-2 border-solid border-color-[#CCCCCC] p-2 py-5 flex justify-center items-center gap-8">
            {modalSignType !== "stamp" && (
              <>
                <div
                  onClick={() => changeInputType("type")}
                  className={`flex flex-col items-center cursor-pointer  ${
                    values.input === "type" && "text-green-500 "
                  }`}
                >
                  <DynamicSvg
                    name="keyboard"
                    className={`cursor-pointer  ${
                      values.input === "type" ? "opacity-1 " : "opacity-[0.5]"
                    } `}
                  />
                  <p className="mt-1">Type</p>
                </div>
                <div
                  onClick={() => changeInputType("draw")}
                  className={`flex flex-col items-center cursor-pointer ${
                    values.input === "draw" && "text-green-500"
                  }`}
                >
                  <DynamicSvg
                    name="icon32"
                    className={`cursor-pointer  ${
                      values.input === "draw" ? "opacity-1 " : "opacity-[0.5]"
                    } `}
                  />
                  <p className="mt-1">Draw</p>
                </div>
              </>
            )}
            <div
              onClick={() => changeInputType("image")}
              className={`flex flex-col items-center cursor-pointer  ${
                values.input === "image" && "text-green-500"
              }`}
            >
              <DynamicSvg
                name="imageImport"
                className={`cursor-pointer  ${
                  values?.input === "image" ? "opacity-1 " : "opacity-[0.5]"
                } `}
              />
              <p className="mt-1">Image</p>
            </div>
          </div>
          { errors[values.input] && touched[values.input] && (
            <div className="p-4 mx-6 my-3 text-sm text-red-800 rounded-lg bg-red-50" role="alert">
              <span className="font-medium">Error!</span> {errors[values.input]}
            </div>
          )}
          <div className="flex flex-col md:flex-row items-center px-12 ">
            {values.input !== "image" && (
              <h1 className="text-[30px] whitespace-nowrap">
                {values.input === "draw"
                  ? "Sign Here  → "
                  : values.input === "type"
                  ? "Type Here  → "
                  : ""}
              </h1>
            )}

            {values.input === "draw" && (
              <SignatureCanvas
                penColor="black"
                canvasProps={{
                  width: 400,
                  height: 80,
                  className: "m-6 ml-12",
                }}
                ref={signatureCanvasRef}
                onEnd={() => {
                  setFieldValue("draw", signatureCanvasRef.current.getTrimmedCanvas().toDataURL());
                }}
                velocityFilterWeight={0}
              />
            )}

            {values.input === "type" && (
              <div className="m-6 my-2 flex-grow">
                <input
                  id="type"
                  name="type"
                  autoComplete="false"
                  onChange={handleChange}
                  className="outline-none py-4 px-8 w-[100%] text-[38px] font-[Allura]"
                  placeholder={`Type your ${modalSignType} here`}
                  value={values.type}
                />
              </div>
            )}

            {values.input === "image" && (
              <div className="m-6 w-[100%] flex justify-center">
                {!values.image ? (
                  <>
                    <label
                      htmlFor="imageInput"
                      className="cursor-pointer text-green-500 text-[30px] ml-2"
                    >{modalSignType==="stamp"?"Select your stamp":"Select Image"}</label>
                    <input
                      type="file"
                      id="imageInput"
                      accept="image/jpeg,image/jpg,image/png"
                      onChange={(e) => handleImageUpload(e, setFieldValue)}
                      className="hidden"
                    />
                  </>
                ) : (
                  <div className="max-w-full h-auto">
                    <img
                      src={values.image}
                      className="object-cover"
                    />
                  </div>
                )}
              </div>
            )}
          </div>
          <hr className="w-[90%] mx-auto h-0.5 bg-black border-0 rounded dark:bg-gray-700" />
          <div className="flex justify-end items-center px-12">
            <p
              onClick={resetForm}
              className="text-green-500 mt-3 cursor-pointer"
            >Clear</p>
          </div>
          <div
            className={`flex ${
              values.input !== "type" ? "justify-between" : "justify-end"
            } px-12 py-6`}
          >
            {values.input !== "type" && (
              <div className="w-[250px] mr-2">
                <input 
                  type="text"
                  id="fullName"
                  name="fullName"
                  value={values.fullName}
                  placeholder="Enter your name"
                  onChange={handleChange}
                  className={`
                    border border-gray-300 p-2.5 rounded-lg outline-none w-full my-0 text-sm 
                    ${errors.fullName && touched.fullName && 'text-red-900 focus:ring-red-500 bg-red-50 border-red-500 placeholder-red-700 focus:border-red-500'}
                  `}
                />
                { errors.fullName && touched.fullName && (
                  <p className="mt-1 text-sm text-red-600">{errors.fullName}</p>
                )}
              </div>
            )}
            <div className="flex items-center">
              <Button
                type="submit"
                title="Apply"
              />
              <Button 
                type="button"
                title="Cancel"
                onClick={handleClose}
                classes="border !border-black !bg-white !text-black ml-2"
              />
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default SignatureModal;
