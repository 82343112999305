import React,{useState} from "react";
import { CiSearch } from "react-icons/ci";


export default function SearchManageInput() {
  const [isFocused, setIsFocused] = useState(false);

  return (
    <div className="relative">
      <div className="absolute inset-y-0 left-0 flex items-center pl-2">
        {isFocused ? (
          <CiSearch className="w-4 h-4 text-gray-400" />
        ) : (
          <div className="w-4 h-4 text-gray-400"></div>
        )}
      </div>
      <input
        className="border pl-4 pr-2 py-1 rounded-lg outline-none transition-all duration-300 focus:pl-8 focus:pr-2"
        placeholder="Search for label"
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
      />
    </div>
  );
}
