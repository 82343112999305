import React from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import DocumentESign from "../Dropdowns/DocumentESign";
import Button from "../Buttons/Button";
import { useFormik } from "formik";
import moment from "moment";
import * as Yup from "yup";

export default function ReminderModalForm({ values, handleFormSubmit, onClose }) {
  const ReminderSchema = Yup.object().shape({
    type: Yup.string().required("One of reminder frequencies is required!."),
    frequency: Yup.string().when("type", {
      is: (value) => value === "frequency",
      then: (schema) => schema.required("Reminder frequency is required!")
    }),
    selectedFrom: Yup.string().when("type", {
      is: (value) => value === "date",
      then: (schema) => schema.required("Date from is required!")
    }),
    selectedTo: Yup.string().when("type", {
      is: (value) => value === "date",
      then: (schema) => schema.required("Date to is required!")
    }),
    selectedTime: Yup.string().when("type", {
      is: (value) => value !== "rightNow",
      then: (schema) => schema.required("Time is required!")
    }),
  });

  const formik = useFormik({
    initialValues: {
      type: values?.type || "frequency",
      frequency: values?.frequency || "",
      selectedFrom: values?.selectedFrom || moment().toDate(),
      selectedTo: values?.selectedTo || moment().add(7, 'days').toDate(),
      selectedTime: values?.selectedTime ||  moment().set({ hour: 8, minute: 0, second: 0, millisecond: 0 }).toDate(),
      message: values?.message || "",
    },
    onSubmit: handleFormSubmit,
    validationSchema: ReminderSchema
  });

  const options = {
    "dayUntilCompleted": "Day until completed",
    "weeklyUntilCompleted": "Weekly until completed",
    "businessDayUntilCompleted": "Every business day until completed", 
    "otherDayUntilCompleted": "Every other day until completed",
    "thirdDayUntilCompleted": "Every third day until completed",
    "fifthDayUntilCompleted": "Every fifth day until completed"
  };

  return (
    <div className={`fixed inset-0 overflow-y-auto z-50 flex justify-center items-center`}>
      <div
        className="modal-overlay fixed inset-0 bg-gray-500 opacity-50"
        onClick={onClose}
      ></div>
      <div className="relative mx-auto max-w-lg w-[350px] max-h-[600px] overflow-y-scroll px-8 py-4 bg-white rounded-lg shadow-lg border">
        <div className="flex justify-between items-center">
          <h1 className="text-[20px]">Set reminder</h1>
        </div>
        <form onSubmit={formik.handleSubmit}>
          <label className="block text-xs font-normal text-gray-700 pt-4">
            Reminder Frequency:
          </label>
          <div>
            <div className="flex">
              <input
                type="radio"
                value="reminder-timeline"
                checked={formik.values.type === "frequency"}
                onChange={() => formik.setFieldValue("type", "frequency")}
                className="mr-2"
              />
              <div className="border w-full rounded-lg text-[13px] whitespace-none my-1">
                <label className="block">
                  <DocumentESign
                    isDisabled={formik.values.type !== "frequency"}
                    title={formik.values.frequency?options[formik.values.frequency]:"Reminder Frequency"}
                  >
                    {Object.keys(options).map((option, index) => (
                      <Button
                        key={index}
                        title={options[option]}
                        onClick={() => {
                          formik.setFieldValue("frequency", option);
                        }}
                        classes={`w-full rounded-none text-[13px] bg-transparent !text-black border-0 hover:bg-[#f0f0f0] !justify-between p-0 ${
                          formik.values.frequency === option
                            ? "!bg-[#f0f0f0]"
                            : ""
                        }`}
                      />
                    ))}
                  </DocumentESign>
                </label>
              </div>
            </div>
            {formik.errors.frequency && formik.touched.frequency && <p className="text-xs text-red-700">{formik.errors.frequency}</p>}
          </div>

          <div className="">
            <label className="block text-xs font-normal text-gray-700 py-2 pb-1">
              Select Date:
            </label>
            <label className="flex">
              <input
                type="radio"
                checked={formik.values.type === "date"}
                onChange={() => formik.setFieldValue("type", "date")}
                className="mr-2"
              />
              <div className="flex  gap-1 w-full">
                <DatePicker
                  selectsStart
                  selected={formik.values.selectedFrom}
                  minDate={moment().toDate()}
                  startDate={formik.values.selectedFrom}
                  endDate={formik.values.selectedTo}
                  onChange={(date) => formik.setFieldValue("selectedFrom", date)}
                  className="border-1 w-full rounded-lg px-2 py-1.5 outline-none text-sm"
                />
                <DatePicker
                  selectsEnd
                  selected={formik.values.selectedTo}
                  startDate={formik.values.selectedFrom}
                  endDate={formik.values.selectedTo}
                  onChange={(date) => formik.setFieldValue("selectedTo", date)}
                  className="border-1 w-full rounded-lg px-2 py-1.5 outline-none text-sm"
                />
              </div>
            </label>
            {formik.errors.selectedFrom && formik.touched.selectedFrom && <p className="text-xs text-red-700 mt-1">{formik.errors.selectedFrom}</p>}
            {formik.errors.selectedTo && formik.touched.selectedTo && <p className="text-xs text-red-700 mt-1">{formik.errors.selectedTo}</p>}
          </div>

          {formik.values.type !== "rightNow" && (
            <div className="mb-1">
              <div>
                <label className="block text-xs font-normal text-gray-700 py-2.5 pb-1">
                  Set your time:
                </label>
                <label className="block">
                  <DatePicker
                    selected={formik.values.selectedTime}
                    onChange={(time) => formik.setFieldValue("selectedTime", time)}
                    showTimeSelect
                    showTimeSelectOnly
                    timeIntervals={15}
                    timeCaption="Time"
                    dateFormat="h:mm aa"
                    className="border w-full p-2 rounded-lg text-sm"
                  />
                </label>
              </div>
              {formik.errors.selectedTime && formik.touched.selectedTime && <p className="text-xs text-red-700 mt-1">{formik.errors.selectedTime}</p>}
            </div>
          )}

          <div className="w-full my-2 mt-4 pt-0 p-1 px-0 cursor-pointer">
            <label className="flex items-center">
              <input
                type="radio"
                checked={formik.values.type === "rightNow"}
                onChange={() => formik.setFieldValue("type", "rightNow")}
                className="mr-2"
              />
              <span className="text-sm font-normal text-gray-700">Set Right Now</span>
            </label>
          </div>

          <div>
            <label className="block text-xs text-gray-700 pb-1">Include a message with your reminder?</label>
            <textarea 
              rows={3}
              name="message"
              onChange={formik.handleChange}
              defaultValue={formik.values.message}
              className="border w-full p-2 rounded-lg text-sm resize-none"
            ></textarea>
          </div>

          <div className="flex justify-center gap-2 mt-4">
            <Button 
              type="submit"
              title={"Create"}
              classes="!py-1.5 px-3 !text-sm"
            />
            <Button 
              type="button"
              title={"Cancel"}
              onClick={onClose}
              classes="!py-1.5 px-3 !text-sm bg-transparent !text-black"
            />
          </div>
        </form>
      </div>
    </div>
  );
}