import ScalableText from "../../ScalableText/ScalableText";
import ElementsContainer from "../ElementsContainer";
import ScaleText from "react-scale-text";

export default function FillSignatureNInitials({ index, element }) {
  return (
    <ElementsContainer 
      index={index}
      element={element}
      lockAspectRatio={true}
    >
      <>
        { element?.responses[0]?.type === "type" && (
          <div className="max-w-full mx-auto my-0 select-none flex items-center h-full">
            <ScalableText text={element.responses[0]?.value} classes="font-[Allura]" />
          </div>
        ) }
        { element?.responses[0]?.type !== "type"  && (
          <div className={`h-full`}>
            <img 
              src={element.responses[0]?.value}
              alt="Signature" 
              className={`pointer-events-none w-full h-full`}
            />
          </div>
        )}
      </>
    </ElementsContainer>
  );
}
