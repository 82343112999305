import React from "react";
import Button from "../../Buttons/Button"
import Accordion from "./atoms/Accordion"
import useElementCustomize from "../../../hooks/useElementCustomize";
import UpdateRecipient from "./atoms/UpdateRecipient";
import ElementName from "./atoms/ElementName";
import ElementFooter from "./atoms/ElementFooter";
import FieldType from "./atoms/FieldType";
import ElementAppearance from "./atoms/ElementAppearance";

const InfoFieldsCustomize = ({ 
  index, 
  element
}) => {
  const {
    cloneElement,
    updateElement, 
    deleteElement, 
    cancelCustomize,
    changeElementType,
    updateElementFields
  } = useElementCustomize(index, element);

  const onSelectChange = (event) => {
    const { id, value } = event.target;
    updateElementFields(`value.${id}`, value);
  }

  return (
    <>
      <ElementName 
        element={element}
        updateElementFields={updateElementFields}
      />

      <UpdateRecipient 
        element={element} 
        updateElementFields={updateElementFields} 
      />

      <FieldType 
        element={element} 
        changeElementType={changeElementType} 
      />

      {element.type === "name" && (
        <div className="my-2">
          <label htmlFor="label" className="block mb-1 text-xs font-normal text-gray-900">Name Style</label>
          <div className="relative">
            <select className="rounded-md p-1 border border-gray-400 font-300 text-[12px] w-full" id="format" onChange={onSelectChange}>
              <option>Formatet</option> {/* WORK */}
            </select>
          </div>
        </div>
      )}

      {element.type === "date" && (
        <div className="my-2">
          <label htmlFor="label" className="block mb-1 text-xs font-normal text-gray-900">Date Format</label>
          <div className="relative">
            <select className="rounded-md p-1 border border-gray-400 font-300 text-[12px] w-full" id="format" onChange={onSelectChange}>
              <option selected={element.value.format==="MM/DD/YYYY"} value="MM/DD/YYYY">MM/DD/YY</option>
              <option selected={element.value.format==="DD/MM/YYYY"} value="DD/MM/YYYY">DD/MM/YY</option>
              <option selected={element.value.format==="YYYY/MM/DD"} value="YYYY/MM/DD">YY/MM/DD</option>
            </select>
          </div>
        </div>
      )}

      <hr className="mt-2 mb-0 w-2/3" />

      <ElementAppearance 
        element={element}
        updateElementFields={updateElementFields}
      />

      <hr className="mt-0.5 mb-0 w-2/3" />

      <Accordion title={"Tools"}>
        <Button 
          title="Clone Field"
          onClick={cloneElement} 
          classes="!p-0 text-[12px] bg-transparent border-0 !text-[#3CA970] hover:bg-transparent" 
        />
      </Accordion>

      <ElementFooter 
        deleteElement={deleteElement}
        updateElement={updateElement}
        cancelCustomize={cancelCustomize}
      />
    </>
  );
};

export default InfoFieldsCustomize;
