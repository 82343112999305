import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../utils/api";

const initialState = {
  user: null,
  isLoading: false,
  isAuthLoading: true,
  message: null
};

export const userLogin = createAsyncThunk(
  "/user/userLogin",
  async (data, thunkAPI) => {
    try {
      const response = await api.post('/auth/login', data);
      if(response.data.user && response.data.token) {
        localStorage.setItem("token", response.data.token);
      }
      return response.data;
    } catch (error) {
      if (error.response) {
        return thunkAPI.rejectWithValue(error.response.data.message);
      }
    }
  }
);

export const isAuthenticated = createAsyncThunk("/auth/isAuthenticated", async (_, thunkAPI) => {
  try {
    const response = await api.get("/auth");
    if(!response.data.user) {
      localStorage.removeItem("token");
    }
    return response.data;
  } catch (error) {
    if (error.response) {
      return thunkAPI.rejectWithValue(error.response.data.message);
    }
  }
});

export const userLogout = createAsyncThunk("/auth/userLogout", (_, thunkAPI) => {
  try {
    localStorage.removeItem("token");
  } catch (error){
    return thunkAPI.rejectWithValue(error.message);
  }
});

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    resetAuth: (state) => initialState
  },
  extraReducers: (builder) => {
    builder.addCase(userLogin.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(userLogin.fulfilled, (state, action) => {
      state.isLoading = false;
      state.user = action.payload?.user;
      state.message = null;
    });
    builder.addCase(userLogin.rejected, (state, action) => {
      state.isLoading = false;
      state.message = action.payload;
      state.user = null;
    });

    builder.addCase(isAuthenticated.pending, (state) => {
      state.isAuthLoading = true;
    });
    builder.addCase(isAuthenticated.fulfilled, (state, action) => {
      state.isAuthLoading = false;
      state.user = action.payload?.user;
    });
    builder.addCase(isAuthenticated.rejected, (state, action) => {
      state.isAuthLoading = false;
      state.user = null;
      state.message = action.payload;
    });

    builder.addCase(userLogout.fulfilled, (state) => {
      state.user = null;
    });
  },
});

export const { resetAuth } = authSlice.actions;
export default authSlice.reducer;