import React from "react";
import Button from "../Buttons/Button";
import Input from "../Inputs/Input";
import { Formik } from "formik";
import * as Yup from "yup";

export default function DeclineNNote({
  label,
  isOpen,
  onSubmit,
  onClose,
  initialValues,
}) {
  const ModalSchema = Yup.object().shape({
    message: Yup.string().required("Message is required."),
  });

  return (
    <div
      className={`${
        isOpen ? "block" : "hidden"
      } fixed inset-0 overflow-y-auto z-50 flex justify-center items-center`}
    >
      <div className="modal-overlay fixed inset-0 bg-gray-500 opacity-50"></div>
      <div className="relative mx-auto w-[350px] px-8 py-4 bg-white rounded-xl shadow-lg">
        <Formik
          initialValues={{ message: initialValues || "" }}
          validationSchema={ModalSchema}
          onSubmit={onSubmit}
          enableReinitialize
        >
          {({ handleChange, handleSubmit, values }) => (
            <form onSubmit={handleSubmit} className="flex flex-col">
              <div className="mb-4">
                <Input
                  rows={7}
                  as="textarea"
                  name="message"
                  label={label}
                  handleChange={handleChange}
                  placeholder="Enter your message"
                  labelClasses="text-base"
                  value={values.message}
                />
              </div>
              <div className="flex justify-center">
                <Button type="submit" title="Confirm" classes="text-sm mr-3 !py-1.5" />
                <Button
                  title="Cancel"
                  onClick={onClose}
                  classes="bg-white border-gray-400 !hover:bg-gray-200 !text-black text-sm !py-1.5"
                />
              </div>
            </form>
          )}
        </Formik>
      </div>
    </div>
  );
}
