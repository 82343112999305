import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { animateCount } from "../../utils/countingAnimation";
import { SearchManageInput, StartFromLibraryModal } from "../../components";
import { useSelector } from "react-redux";
import api from "../../utils/api";
import toast from "react-hot-toast";

export default function Home() {
  const progressRef = useRef(null);
  const [count, setCount] = useState({ inprogress: 0, waiting: 0 });
  const { user } = useSelector(state => state.auth)

  const countDocuments = async () => {
    try {
      const count = await api.get("/document/count/all");
      setCount(count.data);
    } catch(error){
      toast.error("Unable to retrieve the number of documents at the moment.");
    }
  }

  useEffect(() => {
    countDocuments();
    const actualNumber = count.inprogress;
    const interval = animateCount(progressRef.current, actualNumber, 200000);
    return () => {
      clearInterval(interval);
    };
  }, []);
  const [showLibraryModal, setShowLibraryModal] = useState(false);

  return (
    <div className="container flex flex-col items-center">
      <div className="py-6">
        <h1 className="text-[78px] " data-aos="fade-down">
          Welcome to Eundo Docs
        </h1>
        <div className="flex gap-4 font-bold  justify-center py-6 text-xl cursor-pointer">
          <Link to="/manage?type=document&status=inprogress&page=1" className="flex items-center">
            <span ref={progressRef}>{count.inprogress} </span>
            <span className="text-xs font-normal ml-2 text-gray-500">
              IN PROGRESS
            </span>
          </Link>
          <div className="border-r border-black " />
          <Link to="/manage?type=document&status=waiting-for-you&page=1" className="flex items-center">
            {count.waiting}{" "}
            <span className="text-xs font-normal ml-2 text-gray-500">
              WAITING FOR YOU
            </span>
          </Link>
        </div>
      </div>
      <div className="flex flex-col items-center py-6 gap-2">
        <h1 className="text-[36px] font-semibold">
          Send a document for signature
        </h1>
        <p className="sm:text-center">
          Request e-signatures on a new document, or start from your library
          templates and workflows.
        </p>
        <div className="flex items-center py-6 gap-6 ">
          <img
            src="/assets/images/documentHome.svg"
            alt="documentHome"
            className="w-16"
          />
          <div className="flex gap-6">
            <Link to="/send">
              <button className="px-4 py-2 bg-green text-white text-xs rounded-lg hover:bg-green-hover transition duration-300 ease-in-out">
                Request e-signatures
              </button>
            </Link>
            <button
              className="px-4 py-2 bg-white border border-gray-400  text-xs rounded-lg hover:bg-green hover:text-white focus: transition duration-300 ease-in-out"
              onClick={() => setShowLibraryModal(true)}
            >
              Start from library
            </button>
          </div>
        </div>
      </div>
      <div className="flex flex-col justify-center items-center">
        <h1 className="text-[28px] font-semibold py-6">
          Do more with Euno Documents
        </h1>
        <ul className="flex flex-col pb-12 md:flex-row gap-4 cursor-pointer">
          <Link to="/fill-sign">
            <li className="border border-gray-400 rounded-lg p-4 md:p-6 flex flex-col items-center w-40 hover:bg-green hover:text-white transition duration-300 ease-in-out h-full">
              <img src="/assets/images/manage.svg" alt="fill" />

              <p className="text-center text-xs mt-3">
                Fill and sign a document
              </p>
            </li>
          </Link>
          <Link to="/webform">
            <li className="border border-gray-400  rounded-lg p-4 md:p-6 flex flex-col items-center w-40 hover:bg-green hover:text-white transition duration-300 ease-in-out h-full">
              <img
                src="/assets/images/fill.svg"
                alt="fill"
                className="color-white"
              />
              <p className="text-center text-xs mt-3">Publish a web form</p>
            </li>
          </Link>
          <Link to="/send/bulk">
            <li className="border border-gray-400  rounded-lg p-4 md:p-6 flex flex-col items-center w-40 hover:bg-green hover:text-white transition duration-300 ease-in-out h-full">
              <img src="/assets/images/bulk.svg" alt="fill" />
              <p className="text-center text-xs mt-3">Send in bulk</p>
            </li>
          </Link>
          <Link to="/template">
            <li className="border border-gray-400  rounded-lg p-4 md:p-6 flex flex-col items-center w-40 hover:bg-green hover:text-white transition duration-300 ease-in-out h-full">
              <img src="/assets/images/manage.svg" alt="fill" />
              <p className="text-center text-xs mt-3">
                Create a reusable template
              </p>
            </li>
          </Link>
          <Link to="/manage">
            <li className="border border-gray-400  rounded-lg p-4 md:p-6 flex flex-col items-center w-40 hover:bg-green hover:text-white transition duration-300 ease-in-out h-full">
              <img src="/assets/images/reuseableTemplate.svg" alt="fill" />
              <p className="text-center text-xs mt-3">
                Manage and track all documents
              </p>
            </li>
          </Link>
        </ul>
      </div>
      {showLibraryModal && (
        <StartFromLibraryModal
          handleCloseLibraryModal={() => setShowLibraryModal(false)}
        />
      )}
    </div>
  );
}
