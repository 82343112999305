import { useSelector } from 'react-redux';
import ElementsContainer from "../ElementsContainer";
import TextareaAutosize from 'react-textarea-autosize';
import style from "../style.module.css"
import useSetElementResponse from '../../../hooks/useSetElementResponse';

export default function Text({ index, element }) {
  const { builderStatus, zoomLevel } =  useSelector(state => state.sign)
  const { setResponse } = useSetElementResponse(index, element);

  const handleTextChange = (e) => {
    if(builderStatus !== "edit") return;
    setResponse({ value: e.target.value });
  }

  return (
    <ElementsContainer 
      index={index}
      element={element}
      lockAspectRatio={false}
      resizable={false}
    >
      <TextareaAutosize 
        className={style.textarea} 
        onChange={(e) => handleTextChange(e)}
        placeholder='Type text here' 
        value={element.responses[0]?.value}
        style={{
          fontSize: (element.style.fontSize*zoomLevel)
        }}
        readOnly={builderStatus!=="edit"}
      />
    </ElementsContainer>
  );
}
