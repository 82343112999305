import moment from "moment";
import { FREQUENCYOPTIONS } from "../../../utils/config";
import _ from "lodash";

const ReminderItems = ({ reminder, recipients }) => {
console.log(reminder);
  const getFrequency = () => {
    const opts = {"frequency": "Reminder frequency", "date": "Date", "rightNow": "Right now"}
    let text = opts[reminder.type];
    if(reminder.type === "frequency") text += `: ${FREQUENCYOPTIONS[reminder.frequency]}`;
    else if(reminder.type === "date") text += `: ${moment(reminder.selectedFrom).format("MM/DD/YYYY")} - ${moment(reminder.selectedTo).format("MM/DD/YYYY")}`;
    return text;
  }

  return (
    <div className="border border-gray-200 my-3 py-2 px-3">
      {reminder?.lastSent && <p className="mb-1 text-xs text-[#4b4b4b] font-semibold">Last sent on {moment(reminder.lastSent).format("MM/DD/YYYY HH:mm")}</p>}
      <div className="py-0.5">
        <p className="text-xs text-[#4b4b4b] font-semibold">Reminder To</p>
        {recipients.map((recipient) => (
          <p className="text-xs text-[#4b4b4b]">{_.has(recipient, "email")?recipient.email:recipient}</p>
        ))}
      </div>
      <div className="py-0.5">
        <p className="text-xs text-[#4b4b4b] font-semibold">Frequency</p>
        <p className="text-xs text-[#4b4b4b]">{getFrequency()}</p>
      </div>
      <div className="py-0.5">
        <p className="text-xs text-[#4b4b4b] font-semibold">Note</p>
        <p className="text-xs text-[#4b4b4b]">{reminder.message || "/"}</p>
      </div>
    </div>
  );
};

export default ReminderItems;