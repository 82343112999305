import React from "react";
import { FileImport } from "../../components";
import Input from "../../components/Inputs/Input";
import Button from "../../components/Buttons/Button";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { Formik } from "formik";
import * as Yup from "yup";
import { checkIfFilesAreCorrectType, checkIfFilesAreTooBig } from "../../utils/functions";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setLoader } from "../../redux/app.slice";
import api from "../../utils/api";
import _ from "lodash";
import toast from "react-hot-toast";

export default function CreateTemplate() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const TemplateSchema = Yup.object().shape({
    agreementName: Yup.string()
      .min(2, 'Document name too short!')
      .max(50, 'Document name to long!')
      .required('Document name is required!'),
    files: Yup.array()
      .min(1, "At least one file is required!")
      .required("At least one file is required!")
      .test('is-big-file', 'PDF file size is to big.', checkIfFilesAreTooBig)
      .test(
        'is-correct-file',
        'Only PDF formats are accepted.',
        checkIfFilesAreCorrectType
      ),
  });

  return (
    <div className="sendcontainer">
      <div className="py-4 mb-4">
        <h1 className="text-[30px]">Create a Library Tempate</h1>
        <p className="text-sm text-gray-500">
          Upload any document you send often, add form fields and create your
          template. It will be saved to your document library. Learn more about
          creating library templates.
        </p>
      </div>
      <Formik
        initialValues={{ 
          agreementName: "",
          files: [],
        }}
        validationSchema={TemplateSchema}
        onSubmit={async (values) => {
          try {
            dispatch(setLoader(true));
            const formData = new FormData();
            values.files.forEach(file => formData.append("files", file));
            formData.append("data", JSON.stringify(_.omit(values, 'files')));
            const { data } = await api.post("/template", formData, {
              headers: { 'Content-Type': 'multipart/form-data' }
            });
            navigate(`/template/${data._id}`);
          }catch(error){
            toast.error("Unable to create the template. Please try again.");
          }
          dispatch(setLoader(false));
        }}
      >
        {({
          values,
          errors,
          touched,
          handleSubmit,
          setFieldValue
        }) => (
          <form onSubmit={handleSubmit}>
            <div className="mt-12">
              <div className="mt-4">
                <p>Template Name</p>
                <Input 
                  name="agreementName"
                  placeholder="Enter Template Name"
                />
                <DndProvider backend={HTML5Backend}>
                  <FileImport 
                    files={values.files} 
                    setFiles={setFieldValue}  
                    error={errors.files&&touched.files?errors.files:""}
                  />
                </DndProvider>
                <div className="mt-5 flex justify-center">
                  <Button 
                    type="submit"
                    title="Preview & Add Fields"
                    classes="text-sm"
                  />
                </div>
              </div>
            </div>
          </form>
        )}
      </Formik>
    </div>
  );
}
