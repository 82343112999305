import React from "react";
import { useSelector } from "react-redux";
import { Routes, Route, Navigate, useParams } from "react-router-dom";
import {
  Home,
  Login,
  ForgotPassword,
  Manage,
  Reports,
  FillAndSignForm,
  CreateWebForm,
  SendPage,
  CreateTemplate,
  ViewTemplate,
  PreviewTemplate,
  FillAndSignDocument,
  SignAndSend,
  RecipientSign,
} from "../pages";
import FillAndSignInfo from "../pages/InfoPages/FillAndSignInfo";
import SuccessfullyInfo from "../pages/InfoPages/SuccessfullyInfo";
import WebFormSuccess from "../pages/InfoPages/WebFormSuccess";

const PrivateRoute = ({ children }) => {
  const { user } = useSelector((state) => state.auth);
  return user ? children : <Navigate to="/login" />;
};

const PublicRoute = ({ children }) => {
  const { user } = useSelector((state) => state.auth);
  return user ? <Navigate to="/" /> : children;

};

export default function Path() {
  return (  
    <Routes>
      <Route path="/login" element={<PublicRoute><Login /></PublicRoute>} />
      <Route path="/forgot-password" element={<PublicRoute><ForgotPassword /></PublicRoute>} />
      <Route path="/" element={<PrivateRoute><Home /></PrivateRoute>} />
      <Route path="/home" element={<PrivateRoute><Home /></PrivateRoute>} />
      <Route path="/manage" element={<PrivateRoute><Manage /></PrivateRoute>} />
      <Route path="/reports" element={<PrivateRoute><Reports /></PrivateRoute>} />
      
      <Route path="/send" element={<PrivateRoute><SendPage /></PrivateRoute>} />
      <Route path="/send/bulk" element={<PrivateRoute><SendPage /></PrivateRoute>} />
      <Route path="/send/single/:documentId" element={<PrivateRoute><SignAndSend/></PrivateRoute>} />
      <Route path="/send/bulk/:documentId" element={<PrivateRoute><SignAndSend/></PrivateRoute>} />
      <Route path="/send/view/:documentId" element={<SuccessfullyInfo/>}/>

      <Route path="/esign/:documentId/:recipientId" element={<RecipientSign />} />

      <Route path="/fill-sign" element={<PrivateRoute><FillAndSignForm /></PrivateRoute>} />
      <Route path="/fill-sign/:id" element={<FillAndSignDocument />} />
      <Route path="/fill-sign/:id/view" element={<FillAndSignInfo/>}/>

      <Route path="/webform" element={<PrivateRoute><CreateWebForm /></PrivateRoute>} />
      <Route path="/webform/:documentId" element={<PrivateRoute><SignAndSend /></PrivateRoute>} />
      <Route path="/webform/view/:documentId" element={<PrivateRoute><WebFormSuccess /></PrivateRoute>} />

      <Route path="/template" element={<PrivateRoute><CreateTemplate /></PrivateRoute>} />
      <Route path="/template/:templateId" element={<PrivateRoute><ViewTemplate /></PrivateRoute>} />
      <Route path="/template/view/:templateId" element={<PrivateRoute><PreviewTemplate /></PrivateRoute>}/>
    </Routes>
  );
}
