import { useSelector } from 'react-redux';
import ElementsContainer from "../ElementsContainer";
import { Link } from 'react-router-dom';

export default function Hyperlink({ index, element }) {
  const { builderStatus, zoomLevel } =  useSelector(state => state.sign);

  const goToPage = (e) => {
    e.preventDefault();
    e.stopPropagation();

    const pages = document.getElementsByClassName("react-pdf__Page");
    if(pages.length >= parseInt(element.value.page)) {
      pages[element.value.page-1].scrollIntoView();
    }
  }

  return (
    <ElementsContainer 
      index={index}
      element={element}
      lockAspectRatio={false}
      resizable={false}
    >
      <div className='w-full h-full flex items-center overflow-hidden'>
        <Link 
          to={element.value.href} 
          style={{ 
            color: element.style.color,
            textAlign: element.style.textAlign,
            fontSize: element.style.fontSize*zoomLevel, 
          }}
          target="_blank"
          onClick={element.value.page ? goToPage : null}
          className={`${builderStatus === "edit"?"pointer-events-none":"pointer-events-auto"} w-full`}
        >{element.value.label}</Link>
      </div>
    </ElementsContainer>
  );
}
