import { useMemo } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom"

const useLastSignatureElement = () => {
  const { recipientId } = useParams();
  const { doc } = useSelector(state => state.sign);
  const lastInitialElement = useMemo(() => {
    if(recipientId) return doc?.elements.filter(item => item.type === "initials" && item.recipient === recipientId).pop();
    return doc?.elements.filter(item => item.type === "initials").pop();
  }, [doc?.elements]);

  const lastSignatureElement = useMemo(() => {
    if(recipientId) return doc?.elements.filter(item => item.type === "signature" && item.recipient === recipientId).pop();
    return doc?.elements.filter(item => item.type === "signature").pop();
  }, [doc?.elements]);

  return [lastInitialElement, lastSignatureElement];
};

export default useLastSignatureElement;