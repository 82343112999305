import { useSelector } from 'react-redux';
import ElementsContainer from "../ElementsContainer";
import { useEffect, useMemo } from 'react';
import { dateFormat } from '../../../utils/functions';
import useRecipientFromElement from '../../../hooks/useRecipientFromElement';
import useSetElementResponse from '../../../hooks/useSetElementResponse';

export default function Title({ index, element, name }) {
  const recipient = useRecipientFromElement(element.recipient);
  const { builderStatus, zoomLevel } =  useSelector(state => state.sign);
  const { setResponse } = useSetElementResponse(index, element);

  useEffect(() => {
    if(builderStatus !== "fill") return;
    if(element.type === "date") {
      setResponse({ value: new Date() });
    } else if(element.type === "email") {
      setResponse({ value: recipient.email });
    }
  }, [])

  const handleChange = (e) => {
    if(element.isReadonly) return;
    const value = e.target.value;
    setResponse({ value });
  }

  const VALUE = useMemo(() => {
    if(builderStatus === "edit") return name;
    else {
      if(!element?.responses[0]?.value) return "";
      if(element.type === "date") return dateFormat(element?.responses[0]?.value, element?.value?.format);
      return element?.responses[0]?.value;
    }
  }, [element]);

  return (
    <ElementsContainer 
      index={index}
      element={element}
      lockAspectRatio={false}
      resizable={false}
    >
      <div className='h-full w-full flex'>
        <input 
          type='text' 
          placeholder={name} 
          onChange={handleChange}
          value={VALUE}
          readOnly={element.isReadonly || builderStatus !== "fill"}
          className='w-full h-full border-b-1 bg-transparent border-gray-500 px-1 focus:outline-none placeholder-black'
          style={{ 
            color: element.style.color,
            textAlign: element.style.textAlign,
            fontSize: element.style.fontSize*zoomLevel, 
          }}
        />
      </div>
    </ElementsContainer>
  );
}
