import React from "react";

const FieldType = ({ element, changeElementType }) => {

  const changeFieldType = (event) => {
    const value = event.target.value;
    changeElementType(value);
  }

  return (
    <div>
      <label className="text-[12px] font-400 inline-block mb-1">Field type</label>
      <select className="rounded-md p-1 border border-gray-400 font-300 text-[12px] w-full" onChange={changeFieldType}>
        <optgroup label="Signature Feilds">
          <option selected={element.type==="signature"} value="signature">Signature</option>
          <option selected={element.type==="signatureBlock"} value="signatureBlock">Signature Block</option>
          <option selected={element.type==="initials"} value="initials">Initials</option>
        </optgroup>
        <optgroup label="Signature Info Feilds">
          <option selected={element.type==="title"} value="title">Title</option>
          <option selected={element.type==="company"} value="company">Company</option>
          <option selected={element.type==="name"} value="name">Name</option>
          <option selected={element.type==="email"} value="email">Email</option>
          <option selected={element.type==="date"} value="date">Date</option>
        </optgroup>
      </select>
    </div>
  );
};

export default FieldType;
