import Checkmark from "../components/Builder/Fill/Checkmark"
import Error from "../components/Builder/Fill/Error"
import Bullet from "../components/Builder/Fill/Bullet"
import Text from "../components/Builder/Fill/Text"
import FillSignatureNInitials from "../components/Builder/Fill/FillSignatureNInitials"

import Signature from "../components/Builder/Send/Signature"
import SignatureBlock from "../components/Builder/Send/SignatureBlock"
import Stamp from "../components/Builder/Send/Stamp"
import Title from "../components/Builder/Send/Title"
import Initials from "../components/Builder/Send/Initials"
import Hyperlink from "../components/Builder/Send/Hyperlink"
import FileAttachment from "../components/Builder/Send/FileAttachment"
import TextInputField from "../components/Builder/Send/TextInputField"
import DropdownField from "../components/Builder/Send/DropdownField"
import CheckboxField from "../components/Builder/Send/CheckboxField"
import RadioField from "../components/Builder/Send/RadioField"
import ImageField from "../components/Builder/Send/ImageField"

export const ALLELEMENTS = {
  checkmark: {
    type: "checkmark",
    position: {
      x: 0,
      y: 0
    },
    style: {
      color: '#000',
      fontFamily: 'Arial',
      fontWegiht: 500,
      fontSize: 14,
      textAlign: 'left',
      width: 30,
      height: 30,
      variants: {
        big: { width: 40, height: 40 },
        small: { width: 20, height: 20 }
      }
    },
  },
  error: {
    type: "error",
    position: {
      x: 0,
      y: 0
    },
    style: {
      color: '#000',
      fontFamily: 'Arial',
      fontWegiht: 500,
      fontSize: 14,
      textAlign: 'left',
      width: 30,
      height: 30,
      variants: {
        big: { width: 40, height: 40 },
        small: { width: 20, height: 20 }
      }
    }
  },
  bullet: {
    type: "bullet",
    position: {
      x: 0,
      y: 0
    },
    style: {
      color: '#000',
      fontFamily: 'Arial',
      fontWegiht: 500,
      fontSize: 14,
      textAlign: 'left',
      width: 30,
      height: 30,
      variants: {
        big: { width: 40, height: 40 },
        small: { width: 20, height: 20 }
      }
    }
  },
  text: {
    type: "text",
    position: {
      x: 0,
      y: 0
    },
    style: {
      color: '#000',
      fontFamily: 'Arial',
      fontWegiht: 500,
      fontSize: 16,
      textAlign: 'left',
      width: 250,
      variants: {
        big: { fontSize: 20 },
        small: { fontSize: 12 }
      }
    },
    responses: []
  },

  
  signature: {
    type: "signature",
    name: "Signature",
    removeAfterAdd: true,
    isRequired: true,
    position: {
      x: 0,
      y: 0
    },
    style: {
      width: 200,
      height: 75,
      min: { width: 100, height: 50 },
      max: { width: 350, height: 120 },
      variants: {
        big: { width: 250, height: 90 },
        small: { width: 150, height: 60 }
      }
    },
    responses: []
  },
  initials: {
    type: "initials",
    name: "Initials",
    isRequired: true,
    removeAfterAdd: true,
    position: {
      x: 0,
      y: 0
    },
    style: {
      width: 100,
      height: 50,
      variants: {
        big: { width: 70, height: 60 },
        small: { width: 50, height: 35 }
      }
    },
    responses: []
  },


  signatureBlock: {
    type: "signatureBlock",
    name: "Signature block",
    removeAfterAdd: true,
    isRequired: true,
    position: {
      x: 0,
      y: 0
    },
    style: {
      width: 250,
      height: 80,
      min: { width: 100, height: 50 },
      max: { width: 400, height: 140 }
    },
    responses: []
  },
  stamp: {
    type: "stamp",
    name: "Stamp",
    isRequired: true,
    removeAfterAdd: true,
    position: { x: 0, y: 0 },
    value: { tooltip: "" },
    style: {
      width: 150,
      height: 150,
      min: { width: 100, height: 100 },
      max: { width: 250, height: 250 }
    },
    responses: []
  },

  title: {
    type: "title",
    name: "Title",
    isRequired: true,
    removeAfterAdd: true,
    position: { x: 0, y: 0 },
    style: {
      width: 200,
      height: 50,
      fontSize: 14,
      color: "#000",
      textAlign: "left"
    },
    responses: []
  },
  company: {
    type: "company",
    name: "Company",
    isRequired: true,
    removeAfterAdd: true,
    position: { x: 0, y: 0 },
    style: {
      width: 200,
      height: 50,
      fontSize: 14,
      color: "#000",
      textAlign: "left"
    },
    responses: []
  },
  name: {
    type: "name",
    name: "Name",
    isRequired: true,
    removeAfterAdd: true,
    position: { x: 0, y: 0 },
    value: { format: "" },
    style: {
      width: 200,
      height: 50,
      fontSize: 14,
      color: "#000",
      textAlign: "left"
    },
    responses: []
  },
  email: {
    type: "email",
    name: "Email",
    isRequired: true,
    removeAfterAdd: true,
    position: {
      x: 0,
      y: 0
    },
    style: {
      width: 200,
      height: 50,
      fontSize: 14,
      color: "#000",
      textAlign: "left"
    },
    responses: []
  },
  date: {
    type: "date",
    name: "Date",
    isReadonly: true,
    removeAfterAdd: true,
    isRequired: true,
    position: { x: 0, y: 0 },
    value: { format: "MM/DD/YYYY" },
    style: {
      width: 200,
      height: 50,
      fontSize: 14,
      color: "#000",
      textAlign: "left"
    },
    responses: []
  },
  hyperlink: {
    type: "hyperlink",
    name: "Hyperlink",
    removeAfterAdd: true,
    position: { x: 0, y: 0 },
    value: { href: "", page: "", label: "Hyperlink" },
    style: {
      width: 200,
      height: 40,
      fontSize: 14,
      color: "#3CA970",
      textAlign: "left"
    },
    responses: []
  },
  fileAttachment: {
    type: "fileAttachment",
    name: "File Attachment",
    removeAfterAdd: true,
    position: { x: 0, y: 0 },
    value: { label: "File Attachment", tooltip: "" },
    isRequired: false,
    isReadonly: false,
    style: {
      width: 250,
      height: 50,
      fontSize: 14,
      color: "#000",
      textAlign: "left"
    },
    responses: []
  },

  textInput: {
    type: "textInput",
    name: "Text Input",
    isRequired: false,
    isReadonly: false,
    removeAfterAdd: true,
    position: { x: 0, y: 0 },
    value: {
      defaultValue: "",
      isTextArea: false,
      maskField: false,
      tooltip: ""
    },
    style: {
      width: 250,
      height: 50,
      fontSize: 14,
      color: "#000",
      textAlign: "left"
    },
    responses: []
  },
  dropdown: {
    type: "dropdown",
    name: "Dropdown",
    isRequired: false,
    removeAfterAdd: true,
    position: { x: 0, y: 0 },
    value: {
      defaultValue: "",
      maskField: false,
      options: ""
    },
    style: {
      width: 250,
      height: 50,
      fontSize: 14,
      color: "#000",
      textAlign: "left"
    },
    responses: []
  },
  checkbox: {
    type: "checkbox",
    name: "Checkbox",
    isRequired: false,
    isReadonly: false,
    removeAfterAdd: true,
    position: { x: 0, y: 0 },
    value: {
      defaultValue: false,
      tooltip: ""
    },
    style: {
      width: 30,
      height: 30,
      fontSize: 14,
      color: "#000",
      textAlign: "left"
    },
    responses: []
  },
  radio: {
    type: "radio",
    name: "Radio button",
    isRequired: false,
    isReadonly: false,
    removeAfterAdd: true,
    position: [
      { x: 0, y: 0 },
      { x: 0, y: 0 }
    ],
    value: {
      defaultValue: false,
      tooltip: "",
      options: ['Radio button 1', 'Radio button 2']
    },
    style: {
      width: 30,
      height: 30,
      fontSize: 14,
      color: "#000",
      textAlign: "left"
    },
    responses: []
  },

  image: {
    type: "image",
    name: "image",
    isRequired: false,
    removeAfterAdd: true,
    position: { x: 0, y: 0 },
    value: { tooltip: "" },
    style: {
      width: 150,
      height: 150,
      min: { width: 100, height: 100 },
      max: { width: 250, height: 250 }
    },
    responses: []
  },
}

export const getElement = (index, element, docType) => {
  if(docType === "fill") {
    switch(element.type) {
      case "checkmark": return <Checkmark key={index} index={index} element={element} />
      case "error": return <Error key={index} index={index} element={element} />
      case "bullet": return <Bullet key={index} index={index} element={element} />
      case "text": return <Text key={index} index={index} element={element} />
      case "signature":
      case "initials": return <FillSignatureNInitials key={index} index={index} element={element} />
    }
  }else {
    switch(element.type) {
      case "signature": return <Signature key={index} index={index} element={element} /> 
      case "initials": return <Initials key={index} index={index} element={element} />
      case "signatureBlock":  return <SignatureBlock key={index} index={index} element={element} />
      case "stamp": return <Stamp key={index} index={index} element={element} />
      case "title": return <Title key={index} index={index} element={element} name="Title" />
      case "company": return <Title key={index} index={index} element={element} name="Company" />
      case "name": return <Title key={index} index={index} element={element} name="Name" />
      case "email": return <Title key={index} index={index} element={element} name="Email" />
      case "date": return <Title key={index} index={index} element={element} name="Date" />
      case "hyperlink": return <Hyperlink key={index} index={index} element={element} />
      case "fileAttachment": return <FileAttachment key={index} index={index} element={element} />

      case "textInput": return <TextInputField key={index} index={index} element={element} />
      case "dropdown": return <DropdownField key={index} index={index} element={element} />
      case "checkbox": return <CheckboxField key={index} index={index} element={element} />
      case "radio": return <RadioField key={index} index={index} element={element} />
      case "image": return <ImageField key={index} index={index} element={element} />
    }
  }
} 