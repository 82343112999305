import React, { useEffect } from "react";
import { BsDownload, BsFillRocketTakeoffFill } from "react-icons/bs";
import { AiOutlineCopy } from "react-icons/ai";
import { BiSolidPen } from "react-icons/bi";
import { useDispatch, useSelector } from "react-redux";
import api from "../../utils/api";
import { useParams, Link, useNavigate } from "react-router-dom";
import {
  setDocument,
} from "../../redux/sign.slice";
import { setLoader } from "../../redux/app.slice";
import toast from "react-hot-toast";

export default function FillAndSignInfo() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();
  const { doc } = useSelector(
    (state) => state.sign
  );

  useEffect(() => {
    getDocument(id);
  }, [id]);

  const getDocument = async () => {
    try {
      dispatch(setLoader(true));
      const { data } = await api.get(`/document/${id}`);
      dispatch(setDocument(data));
    } catch (error) {
      toast.error("Unable to locate the specified document.");
      navigate("/manage");
    }
    dispatch(setLoader(null));
  };

  const downloadPdf = async () => {
    try {
      const response = await api.get(`/pdf/${id}/download`, {
        responseType: "blob",
      });
      const blob = new Blob([response.data]);
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = doc.agreementName + ".pdf";
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
    } catch (error) {
      toast.error("Unable to download the PDF file for the document.");
    }
  };

  return (
    <div className="container pt-4">
      <div className="p-4">
        <h1 className="text-[32px] font-semibold">
          You have successfully signed the document {`"${doc?.agreementName}"`}
        </h1>
        <h2 className="pt-12 text-[22px] font-semibold">
          Next steps for this document:
        </h2>
        <div className="flex flex-col items-start gap-4 pt-4">
          <button className="flex items-center gap-2" onClick={downloadPdf}>
            <BsDownload />
            Download a Copy
          </button>
          <Link to="/manage">
            <button className="flex items-center gap-2">
              <BsFillRocketTakeoffFill />
              Manage This Document
            </button>
          </Link>
          <Link to="/fill-sign">
            <button className="flex items-center gap-2">
              <BiSolidPen />
              Sign Another Document
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
}
