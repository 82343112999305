import { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import ElementsContainer from "../ElementsContainer";
import useSetElementResponse from '../../../hooks/useSetElementResponse';

export default function TextInputField({ index, element }) {
  const { setResponse } = useSetElementResponse(index, element);
  const { builderStatus, zoomLevel } =  useSelector(state => state.sign);

  useEffect(() => {
    if(builderStatus !== "fill") return;
    setResponse({ value: element.value.defaultValue });
  }, [])

  const handleChange = (e) => {
    if(element.isReadonly) return;
    const value = e.target.value;
    setResponse({ value });
  }

  const VALUE = useMemo(() => {
    if(builderStatus === "edit") {
      if(element.value.defaultValue) return element.value.defaultValue;
      else return "Text Input";
    }
    return element.responses[0]?.value;
  }, [element])

  return (
    <ElementsContainer 
      index={index}
      element={element}
      lockAspectRatio={false}
      resizable={false}
    >
      <div className='h-full w-full flex'>
        {element.value.isTextarea ? (
          <textarea 
            onChange={handleChange}
            placeholder="Text Input"
            readOnly={element.isReadonly || builderStatus !== "fill"}
            className='w-full h-full border-b-1 bg-transparent border-gray-500 px-1 focus:outline-none placeholder-black'
            style={{ 
              color: element.style.color,
              textAlign: element.style.textAlign,
              fontSize: element.style.fontSize*zoomLevel, 
            }}
            defaultValue={VALUE}
          ></textarea>
        ) : (
          <input 
            type='text' 
            value={VALUE}
            placeholder="Text Input"
            onChange={handleChange}
            readOnly={element.isReadonly || builderStatus !== "fill"}
            className='w-full h-full border-b-1 bg-transparent border-gray-500 px-1 focus:outline-none placeholder-black'
            style={{ 
              color: element.style.color,
              textAlign: element.style.textAlign,
              fontSize: element.style.fontSize*zoomLevel, 
            }}
          />
        )}
      </div>
    </ElementsContainer>
  );
}
