import { IoMdCheckmark } from "react-icons/io";
import ElementsContainer from "../ElementsContainer";
import { useSelector } from "react-redux";

export default function Checkmark({ index, element }) {
  const { builderStatus, activeIndex } = useSelector((state) => state.sign);
  const isActive = activeIndex === index && builderStatus === "edit";

  return (
    <ElementsContainer 
      index={index}
      element={element}
    >
      <IoMdCheckmark 
        size="100%" 
        width="100%" 
        height="100%" 
        className="pointer-events-none" 
        color={isActive?"#3CA970":"#000"}
      />
    </ElementsContainer>
  );
}
