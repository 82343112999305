import { useEffect, useRef, useState } from "react";
import styles from "./tooltip.module.css"
export default function Tooltip({ content, children }) {
  const ref = useRef();
  const [visible, setVisibility] = useState(true);

  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        setVisibility(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref])

  return (
    <div ref={ref} className="relative" onClick={() => setVisibility(!visible)}>
      {children}
      {visible && (
        <div className={`${styles.tooltip} ${styles.tooltipLeft}`}>
          {content}
        </div>
      )}
    </div>
  );
}