import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

export default function ReportsSidebar() {
  const navigate = useNavigate();
  const [activeElement, setActiveElement] = useState("in-progress");

  const handleClick = (element) => {
    setActiveElement(element);
  };

  return (
    <div className="text-black w-96 p-4 fixed h-screen">
      <h1 className="text-[30px] font-semibold">Your Reports</h1>
   
      <div className="flex flex-col gap-4 mt-4">
        <p
          className={`cursor-pointer border rounded-lg p-2 transition  ${
            activeElement === "overview" ? "font-semibold bg-gray-100" : ""
          }`}
          onClick={() => handleClick("overview")}
        >
          Overview
        </p>
      </div>
      <div className="border rounded-lg p-2 flex flex-col gap-2 my-4">
        <p className="cursor-pointer font-semibold text-[18px] ">Reports</p>
        <p
          className={`cursor-pointer mb-2 ${
            activeElement === "waiting-for-you" ? "font-semibold" : ""
          }`}
          onClick={() => handleClick("waiting-for-you")}
        >
          Agreements (2)
        </p>
        <p
          className={`cursor-pointer mb-2 ${
            activeElement === "completed" ? "font-semibold" : ""
          }`}
          onClick={() => handleClick("completed")}
        >
          Transaction consumption (2)
        </p>
      </div>
      <p
        className={`cursor-pointer border rounded-lg p-2 my-4 transition  ${
          activeElement === "exports" ? "font-semibold bg-gray-100" : ""
        }`}
        onClick={() => handleClick("exports")}
      >
        Exports
      </p>
    </div>
  );
}
