import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loader: null,
  activeTable: "in-progress",
  isDocumentSignedState : false,
};

export const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    setLoader: (state, action) => {
      state.loader = action.payload;
    },
    setActiveTable: (state, action) => {
      state.activeTable = action.payload;
    },
    setDocumentSigned: (state, action) => {
      state.isDocumentSignedState = action.payload;
    }
  },
});

export const { setLoader, setActiveTable, setDocumentSigned } = appSlice.actions;
export default appSlice.reducer;
