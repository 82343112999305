import React from "react";
import { Link, useParams } from "react-router-dom";

export default function PreviewTemplate() {
  const { templateId } = useParams();
  
  return (
    <div className="container pt-12">
      <div className="flex w-full gap-12">
        <div className="w-1/2">
          <h1 className="text-[30px]">
            You have successfully created a new template.
          </h1>
          <p className="text-[20px] pt-4">What's next</p>
          <div className="flex flex-col justify-start items-start pt-2">
            <Link to={`/send?templateId=${templateId}`} className="text-green-500">Send this template for signature</Link>
            <Link to="/template" className="text-green-500">Create another template</Link>
            <Link to="/manage?type=template&page=1" className="text-green-500">Manage your templates</Link>
          </div>
        </div>
      </div>
    </div>
  );
}
