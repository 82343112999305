import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setBuilderStatus,
  setDocument,
  setElementToAdd,
  setModalSignType,
} from "../../redux/sign.slice";
import { setLoader } from "../../redux/app.slice";
import { useParams, useNavigate } from "react-router-dom";
import api from "../../utils/api";
import DocumentBuilder from "../../components/Builder/DocumentBuilder";
import { DynamicSvg, ManageBar } from "../../components";
import SignType from "../../components/Dropdowns/SignType";
import Button from "../../components/Buttons/Button";
import moment from "moment";
import toast from "react-hot-toast";

export default function FillAndSignDocument() {
  const dispatch = useDispatch();
  const { id } = useParams();
  const navigate = useNavigate();
  const [showDropDown, setShowDropDown] = useState(false);
  const { doc, builderStatus, elementToAdd, modalSignType } = useSelector(
    (state) => state.sign
  );

  useEffect(() => {
    getDocument(id);
  }, [id]);

  const getDocument = async (id) => {
    try {
      dispatch(setLoader(true));
      const { data } = await api.get(`/document/${id}`);
      dispatch(setDocument(data));
      dispatch(setBuilderStatus(data.status==="draft"?"edit":"view"));
    } catch (error) {
      toast.error("Unable to locate the specified document.");
      navigate("/manage");
    }
    dispatch(setLoader(null));
  };

  const updateDocument = async () => {
    try {
      dispatch(setLoader({ loading: true, message: "Submitting..." }));
      await api.post(`/document/${doc._id}`, doc);
      navigate(`/fill-sign/${doc._id}/view`);
    } catch (error) {
      toast.error("Unable to update the document. Please try again.");
    }
    dispatch(setLoader(null));
  };

  const handleApply = (values, type="") => {
    updateElementToAdd(type?type:modalSignType, {
      responses: [{
        type: values.input,
        value: values[values.input],
        fullName: values.input==="type"?values[values.input]:values.fullName,
        signedAt: moment().format()
      }]
    });
    dispatch(setModalSignType(null));
  };

  const updateElementToAdd = (type, data = null) => {
    dispatch(setElementToAdd({ type, data }));
  };

  const handleSignTypeClick = (type, element, event) => {
    if (!element || event) {
      dispatch(setModalSignType(type));
      event && event.stopPropagation();
    } else {
      handleApply(
        {
          input: element.responses[0]?.type,
          [element.responses[0]?.type]: element.responses[0]?.value,
          fullName: element.responses[0]?.fullName,
        },
        type
      );
    }
  };

  return (
    <>
      <DocumentBuilder
        handleApply={handleApply}
        headerComponent={
          <>
            {builderStatus === "edit" ? (
              <div className="border-2 rounded-[20px] mt-24 flex justify-between items-center py-4 px-8">
                <h1 className="text-[25px] font-semibold">
                  {doc?.agreementName}
                </h1>
                <div
                  className={`flex gap-2 items-center ml-8 cursor-pointer relative $`}
                >
                  <DynamicSvg
                    name="icon30"
                    onClick={() => updateElementToAdd("text")}
                    className={`cursor-pointer mx-2 ${
                      elementToAdd?.type === "text"
                        ? "fill-[#3CA970] opacity-[1]"
                        : ""
                    } `}
                  />
                  <DynamicSvg
                    name="icon34"
                    onClick={() => updateElementToAdd("error")}
                    className={`cursor-pointer mx-2 ${
                      elementToAdd?.type === "error"
                        ? "fill-[#3CA970] opacity-[1]"
                        : ""
                    } `}
                  />
                  <DynamicSvg
                    name="icon33"
                    onClick={() => updateElementToAdd("checkmark")}
                    className={`cursor-pointer mx-2 ${
                      elementToAdd?.type === "checkmark"
                        ? "fill-[#3CA970] opacity-[1]"
                        : ""
                    } `}
                  />
                  <DynamicSvg
                    name="icon31"
                    onClick={() => updateElementToAdd("bullet")}
                    className={`cursor-pointer mx-2 ${
                      elementToAdd?.type === "bullet"
                        ? "fill-[#3CA970] opacity-[1]"
                        : ""
                    } `}
                  />
                  <div
                    className={`flex gap-2 items-center mx-2 cursor-pointer relative `}
                    onClick={() => {
                      setShowDropDown(!showDropDown);
                    }}
                  >
                    <DynamicSvg
                      name="icon32"
                      className={
                        showDropDown ? "fill-[#3CA970] opacity-[1]" : ""
                      }
                    />
                    <p className="text-xs text-gray-300">Sign</p>
                    {showDropDown && (
                      <SignType
                        setVisibility={setShowDropDown}
                        handleSignTypeClick={handleSignTypeClick}
                      />
                    )}
                  </div>
                </div>
                <Button title="Done" onClick={updateDocument} />
              </div>
            ) : (
              <div className="w-full mt-24">
                {doc && <ManageBar data={doc} classes="border-2" />}
              </div>
            )}
          </>
        }
      />
    </>
  );
}
