import { useSelector } from "react-redux";
import ElementsContainer from "../ElementsContainer";
import { TbPointFilled } from "react-icons/tb";

export default function Bullet({ index, element }) {
  const { builderStatus, activeIndex } = useSelector((state) => state.sign);
  const isActive = activeIndex === index && builderStatus === "edit";

  return (
    <ElementsContainer 
      index={index}
      element={element}
    >
      <div className="p-0">
        <TbPointFilled 
          size="100%" 
          width="100%" 
          height="100%" 
          className="pointer-events-none" 
          color={isActive?"#3CA970":"#000"}
        />
      </div>
    </ElementsContainer>
  );
}
