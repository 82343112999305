import React from "react";
import ReportsSidebar from "./ReportsSidebar";
import ReportsMainContainer from "./ReportsMainContainer";

export default function ReportsPageLayout () {
  return (
    <div className="container flex h-screen pt-8">
      <ReportsSidebar />
      <ReportsMainContainer />
    </div>
  );
}
