import React, { useEffect, useMemo } from "react";
import { useParams, useNavigate, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setDocument } from "../../redux/sign.slice";
import { setLoader } from "../../redux/app.slice";
import api from "../../utils/api";
import { Document, Page } from "react-pdf";
import { API_URL } from "../../utils/api";
import IconButton from "../../components/Buttons/IconButton";
import { PiCopySimpleLight } from "react-icons/pi";
import { WEB_URL } from "../../utils/config";
import toast from "react-hot-toast";

export default function WebFormSuccess() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { documentId } = useParams();
  const { doc } = useSelector((state) => state.sign);

  useEffect(() => {
    getDocument(documentId);
  }, [documentId]);

  const getDocument = async () => {
    try {
      dispatch(setLoader(true));
      const { data } = await api.get(`/document/${documentId}`);
      if(data.type!=="webform")navigate("/manage?type=webform&page=1");
      dispatch(setDocument(data));
    } catch (error) {
      navigate("/");
    }
    dispatch(setLoader(null));
  };

  const LINK = useMemo(() => {
    if(doc){
      const recipient = doc.recipients.find((rec) => rec.systemRecipient);
      return WEB_URL + "/esign/" + doc._id + "/" + recipient._id;
    }
    return "";
  }, [doc])

  const copyLink = () => {
    navigator.clipboard.writeText(LINK);
    toast.success("Link copied to clipboard");
  }

  return (
    <div className="container pt-12">
      <div className="flex w-full gap-12">
        <div className="w-1/2">
          <h1 className="text-[26px]">Your {`${doc?.agreementName}`} web form is ready</h1>
          <div className="mt-3">
            <h6 className="text-md font-semibold">Start using your form</h6>
            <p className="text-sm font-normal">
              Share the form URL with others to complete or use the code below to embed your form in a website.
            </p>
          </div>

          <div className="mt-5">
            <h6 className="text-sm font-semibold">Share the URL</h6>
            <p className="text-sm font-normal text-[#6e6e6e]">
              Copy the URL to share the form with others.
            </p>

            <div className="mt-1 flex items-center border border-gray-300 relative h-[36px] rounded-sm">
              <span className="px-3 inline-block max-w-[90%] overflow-hidden text-sm">{LINK}</span>
              <IconButton type="button" onClick={copyLink} classes="absolute top-[0] right-[0] bg-gray-300 !rounded-none">
                <PiCopySimpleLight />
              </IconButton>
            </div>  
          </div>

          <div className="mt-5">
            <p className="text-sm font-normal text-[#6e6e6e]">
              Each participant will receive a PDF copy once the form is completed.
            </p>
          </div>

          <p className="text-[18px] mt-5">Recommended actions</p>
          <div className="flex flex-col justify-start items-start pt-2 gap-1">
            <Link to={`/webform/${document._id}`} className="text-green-500 text-sm">Preview this web form</Link>
            <Link to="/manage?type=webform&page=1" className="text-green-500 text-sm">View your web forms</Link>
            <Link to="/webform" className="text-green-500 text-sm">Create another web form</Link>
          </div>
        </div>
      </div>
    </div>
  );
}
