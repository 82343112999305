import React, { useEffect, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { userLogout } from "../../redux/auth.slice";
import Button from "../../components/Buttons/Button";

export default function Navbar() {
  const { user } = useSelector((state) => state.auth);
  const location = useLocation();
  const currentPage = location.pathname;
  const isCurrentPage = (path) => currentPage === path;

  const hideNavbar = useMemo(() => {
    const hideOn = ["/login", "/register"];
    if (hideOn.includes(currentPage)) return true;
    return false;
  }, [currentPage]);

  const handleLogout = () => {
    localStorage.removeItem("token");
    window.location.reload();
  };

  return (
    !hideNavbar && (
      <div className="mb-16">
        <div className="border-b borderColor-color fixed top-0 w-full bg-white z-10">
          <div className="container">
            <div className="flex justify-between items-center py-2 min-h-[65px]">
              <Link to="">
                <img
                  src="/assets/images/logo.png"
                  alt="logo"
                  className="w-[140px] h-[40px]"
                />
              </Link>
              {user && (
                <>
                  <ul className="flex gap-4">
                    <li className="relative group cursor-pointer">
                      <Link to="/home">
                        <span
                          className={`${
                            isCurrentPage("/home")
                              ? "text-black"
                              : "text-gray-400"
                          }`}
                        >
                          Home
                        </span>
                      </Link>
                      <div
                        className={`absolute left-0 w-full h-[2px] bg-gray-300 transition-all duration-300 transform scale-x-0 group-hover:scale-x-100 ${
                          isCurrentPage("/home") ? "scale-x-100" : ""
                        }`}
                      />
                    </li>
                    <li className="relative group cursor-pointer">
                      <Link to="/send">
                        <span
                          className={`${
                            isCurrentPage("/send")
                              ? "text-black"
                              : "text-gray-400"
                          }`}
                        >
                          Send
                        </span>
                      </Link>
                      <div
                        className={`absolute left-0 w-full h-[2px] bg-gray-300 transition-all duration-300 transform scale-x-0 group-hover:scale-x-100 ${
                          isCurrentPage("/send") ? "scale-x-100" : ""
                        }`}
                      />
                    </li>
                    <li className="relative group cursor-pointer">
                      <Link to="/manage">
                        <span
                          className={`${
                            isCurrentPage("/manage")
                              ? "text-black"
                              : "text-gray-400"
                          }`}
                        >
                          Manage
                        </span>
                      </Link>
                      <div
                        className={`absolute left-0 w-full h-[2px] bg-gray-300 transition-all duration-300 transform scale-x-0 group-hover:scale-x-100 ${
                          isCurrentPage("/manage") ? "scale-x-100" : ""
                        }`}
                      />
                    </li>
                    <li className="relative group cursor-pointer">
                      <Link to="/reports">
                        <span
                          className={`${
                            isCurrentPage("/reports")
                              ? "text-black"
                              : "text-gray-400"
                          }`}
                        >
                          Reports
                        </span>
                      </Link>
                      <div
                        className={`absolute left-0 w-full h-[2px] bg-gray-300 transition-all duration-300 transform scale-x-0 group-hover:scale-x-100 ${
                          isCurrentPage("/reports") ? "scale-x-100" : ""
                        }`}
                      />
                    </li>
                  </ul>
                  <div className="cursor-pointer flex items-center gap-2">
                    <Button
                      title="Logout"
                      classes="w-fit"
                      onClick={handleLogout}
                    />

                    <img
                      className="cursor-pointer"
                      src="/assets/images/ellipseLogo.svg"
                      alt="ellipse"
                    />
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    )
  );
}
