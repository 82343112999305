import set from "lodash/fp/set";
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { setActiveIndex, setElements } from "../redux/sign.slice";
import { useState } from "react";
import { v4 as uuidv4 } from 'uuid';
import { ALLELEMENTS } from "../utils/elements";

const useElementCustomize = (index, element) => {
  const dispatch = useDispatch();
  const [form, setForm] = useState({});
  const { doc } = useSelector(state => state.sign);

  const updateElement = () => {
    dispatch(setActiveIndex(null));
  }

  function getObjectKeyValuePairs(obj, parentKey = '') {
    return _.transform(obj, (result, value, key) => {
      const currentKey = parentKey ? `${parentKey}.${key}` : key;
  
      if (_.isObject(value)) {
        _.assign(result, getObjectKeyValuePairs(value, currentKey));
      } else {
        result[currentKey] = value;
      }
    });
  }
  
  const cancelCustomize = () => {
    const keys = getObjectKeyValuePairs(form);
    if(Object.keys(keys).length < 1) return;
    let newElement = {...element};
    if(newElement.cloned) cancelCloneUpdates(newElement, keys);
    else {
      for(const key in keys){
        newElement = set(key, keys[key], newElement);
      }
      dispatch(setElements({ index, element: newElement }))
    }
    dispatch(setActiveIndex(null));
  }
  
  const cloneElement = () => {
    let newElement = {...element};
    newElement.cloned = true;
    dispatch(setElements({ index, element: newElement }));
    newElement = {
      ...newElement,
      position: { x: newElement.position.x + 15, y: newElement.position.y + 15 }
    }
    dispatch(setElements({ index: -1, element: newElement }));
    dispatch(setActiveIndex(doc.elements.length));
  }

  const copyElement = () => {
    let newElement = {...element};
    newElement = {
      ...newElement,
      cloned: false,
      uuid: uuidv4(),
      position: { x: newElement.position.x + 15, y: newElement.position.y + 15 }
    }
    dispatch(setElements({ index: -1, element: newElement }));
    dispatch(setActiveIndex(doc.elements.length));
  }
  
  const deleteElement = () => {
    dispatch(setElements({ index, element: null }));
    dispatch(setActiveIndex(null));
  }

  const updateElementFields = (key, value) => {
    if(Array.isArray(key)) {
      for(const k of key) {
        if(!_.has(form, k)) {
          setForm(set(k, _.get(element, k), form));
        }
      }
    } else {
      if(!_.has(form, key)) {
        setForm(set(key, _.get(element, key), form));
      }
    }

    let newElement = {...element};
    if(Array.isArray(key)) {
      key.forEach((k, index) => {
        newElement = set(k, value[index], newElement);
      })
    } else newElement = set(key, value, newElement);
    if(newElement.cloned) updateClones(newElement);
    else {
      dispatch(setElements({ index, element: newElement }))
    }
    return newElement;
  }

  const updateClones = (elm) => {
    doc.elements.forEach((element, index) => {
      if(element.uuid === elm.uuid && element.cloned){
        let newElement = {
          ...elm,
          position: {
            ...element.position
          }
        };
        dispatch(setElements({ index, element: newElement }))
      }
    })
  }
  
  const cancelCloneUpdates = (elm, keys) => {
    doc.elements.forEach((element, index) => {
      if(element.uuid === elm.uuid && element.cloned){
        let newElement = { ...element };
        for(const key in keys){
          newElement = set(key, keys[key], newElement);
        }
        dispatch(setElements({ index, element: newElement }))
      }
    })
  }

  const changeElementType = (type) => {
    const newElement = {
      ...ALLELEMENTS[type],
      page: element.page,
      uuid: uuidv4(),
      position: {
        ...element.position
      }
    }
    dispatch(setElements({ index, element: newElement }));
  }
 
  return {
    updateElement,
    cancelCustomize,
    cloneElement,
    copyElement,
    deleteElement,
    changeElementType,
    updateElementFields,
  }
};

export default useElementCustomize;