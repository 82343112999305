import React, { useState } from "react";
import { AiOutlineUp, AiOutlineDown } from "react-icons/ai";
import DynamicSvg from "../DynamicsSvg/DynamicsSvg";
import moment from "moment";
import { Link, useSearchParams } from "react-router-dom";
import api from "../../utils/api";
import { useDispatch } from "react-redux";
import DeclineNNote from "../../components/Modals/DeclineNNote";
import { setLoader } from "../../redux/app.slice";
import CancelAgreementModal from "../Modals/CancelAgreementModal";
import PdfFilesModal from "../Modals/PdfFilesModal";
import toast from "react-hot-toast";
import { GoHorizontalRule } from "react-icons/go";
import { FaCode } from "react-icons/fa6";

export default function ManageBar({ data, classes }) {
  const dispatch = useDispatch();
  const [search, setSearchParams] = useSearchParams();
  const [showDropdown, setShowDropdown] = useState(false);
  const [modalVisibility, setModalVisibility] = useState(false);
  const [cancelModalVisibility, setCancelModalVisibility] = useState(false);
  const [donwloadSingleFile, setDonwloadSingleFile] = useState(false);
  
  const handleSvgClick = (e) => {
    e.stopPropagation();
  };
  
  const toggleShowDropDown = () => {
    setShowDropdown(!showDropdown);
  };
  
  const formattedCreatedDate = data ? moment(data.createdAt).format("L") : "";
  const formattedModifiedDate = data
    ? moment(data.modifiedDate).format("L")
    : "";
  const numberOfCompletedRecipients =
    data && data.recipients
      ? data.recipients.filter((recipient) => recipient.status === "completed")
          .length
      : 0;

  const downloadPdf = async () => {
    try {
      const response = await api.get(`/pdf/${data._id}/download`, {
        responseType: "blob",
      });
      const blob = new Blob([response.data]);
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = data.agreementName + ".pdf";
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
    } catch (error) {
      toast.error("Unable to download the PDF file for the document.");
    }
  };

  const handleSubmitNote = async (note) => {
    try {
      dispatch(setLoader(true));
      await api.post(`/document/${data._id}/note`, { note: note.message });
      toast.success("Document notes updated successfully.");
      setModalVisibility(false);
    } catch (error) {
      toast.error("Unable to add a note to the document due to an error.");
    }
    dispatch(setLoader(false));
  };

  const handleCancelDocument = async () => {
    try {
      dispatch(setLoader(true));
      await api.post(`/document/${data._id}/cancel`);
      search.set("reload", true);
      setSearchParams(search);
      setCancelModalVisibility(false);
      toast.success("Document canceled successfully.");
    } catch (error) {
      toast.error("An error occurred while canceling the document.");
    }
    dispatch(setLoader(false));
  };

  const handleFileClick = async (index) => {
    try {
      const response = await api.get(
        `/pdf/downloadSingleDocumentFile/${data._id}/${index}`,
        {
          responseType: "blob",
        }
      );

      const blob = new Blob([response.data]);
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = data.files[index];
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
    } catch (error) {
      toast.error("Unable to download the PDF file for the document.");
    }
  };

  const handleFetchLogs = async () => {
    try {
      const response = await api.get(`/pdf/${data._id}/audit`, {
        responseType: "blob",
      });

      const blob = new Blob([response.data]);
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = "test.pdf";
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
    } catch (error) {
      toast.error("Error downloading document logs.");
    }
  };

  const docType = (type) => {
    if(type==="bulk") return "Bulk";
    else if(type==="single") return "Agreement";
    else if(type==="webform") return "Web Form";
    else if(type==="template") return "Template";
    else return "Fill";
  }

  const linkToOpen = () => {
    if(data.type === "fill") return `/fill-sign/${data._id}`;
    else if(data.type === "bulk") return `/send/bulk/${data._id}`;
    else if(data.type === "single") return `/send/single/${data._id}`;
    else if(data.type === "template") return `/template/${data._id}`;
    else if(data.type === "webform") return `/webform/${data._id}`;
  }

  const openReminder = (id) => {
    search.set("reminder", true);
    if(id) search.set("documentId", id);
    setSearchParams(search);
  }

  return (
    <>
      <div
        className={`border ${
          showDropdown === true
            ? "rounded-t-lg bg-gray-200 mb-0 "
            : "rounded-lg mb-4"
        } w-full p-4 flex flex-wrap justify-between items-center cursor-pointer bg-white ${classes} `}
        onClick={toggleShowDropDown}
      >
        <div className="flex w-1/2">
          <p className="w-[60%] truncate mr-2">{data?.recipients?.length===1?data.recipients[0]?.email:<GoHorizontalRule />}</p>
          <div className="w-[40%] mr-2">
            <p className="truncate">{data.agreementName}</p>
            <p className="truncate text-xs text-[#9CA3AF]">{docType(data.type)}</p>
          </div>
        </div>

        <div className="flex w-1/2 items-center">
          <div className="w-[40%] flex gap-2  " onClick={handleSvgClick}>
            <Link to={linkToOpen()}>
              <DynamicSvg name="icon22" className="w-4 h-4" />
            </Link>
            {((data.status === "draft" || data.status === "inprogress") && data.type !== "webform") && (
              <DynamicSvg name="icon24" className="w-4 h-4" onClick={() => openReminder(data._id)} />
            )}
            {data.type !== "template" && (
              <DynamicSvg
                name="icon23"
                className="w-4 h-4 "
                onClick={() => setModalVisibility(true)}
              />
            )}
          </div>
          <p className="w-[35%] capitalize">{data.status}</p>
          <p className="w-[20%]  ">
            {formattedModifiedDate
              ? formattedModifiedDate
              : formattedCreatedDate}
          </p>

          {showDropdown === true ? (
            <AiOutlineUp className="w-3 h-3 ml-3 " data-aos="zoom-in" />
          ) : (
            <AiOutlineDown className="w-3 h-3 ml-3" data-aos="zoom-out" />
          )}
        </div>
      </div>
      {showDropdown === true && (
        <div
          className={`border rounded-b-lg border-t-0  p-4 mb-4 flex text-[14px] bg-white`}
          data-aos="zoom-in"
        >
          <div className="flex w-1/2">
            <div className="w-[50%]">
              <p className="font-bold ">{data.documentName}</p>
              <ul className="text-[12px]">
                <li className="py-1">Created {formattedCreatedDate}</li>
                {data.type !== "template" && (
                  <>
                    <li className="py-1">
                      <span className="font-bold">Status:</span>{" "}
                      {data.outOfSignature}
                    </li>
                    <li className="py-1 text-gray-400 text-[10px]">
                      {numberOfCompletedRecipients} of {data.recipients?.length}{" "}
                      Completed
                    </li>
                  </>
                )}
              </ul>
            </div>
            <div className="w-[50%]">
              <p>
                <span className="font-bold">Recipients</span>
                {data.type !== "template" && (
                  <span className="text-sm">({" "} {numberOfCompletedRecipients} Completed )</span>
                )}
              </p>
              {data.recipients &&
                data.recipients?.map((recipient) => (
                  <ul>
                    <li className="py-1 flex items-center gap-2">
                      {recipient.email}
                      {recipient.status === "completed" && (
                        <DynamicSvg
                          name="icon19"
                          className="Checked  w-4 h-4"
                        />
                      )}
                    </li>
                  </ul>
                ))}
            </div>
          </div>
          <div className="flex w-1/2">
            <div className="w-[50%]">
              <p className="font-bold">Actions</p>
              <ul className="text-gray-400 cursor-pointer">
                <Link to={linkToOpen()}>
                  <li className="py-1 flex items-center gap-1">
                    <DynamicSvg name="icon1" className="Checked w-4 h-4" />
                    Open document
                  </li>
                </Link>
                {data.type === "webform" && (
                  <Link to={`/webform/view/${data._id}`}>
                    <li className="py-1 flex items-center gap-1">
                      <FaCode color="#3CA970" size={16} />
                      Get Code
                    </li>
                  </Link>
                )}
                {((data.status === "draft" || data.status === "inprogress") && data.type !== "webform") && (
                  <button
                    className="py-1 flex items-center gap-1"
                    onClick={() => openReminder(data._id)}
                  >
                    <DynamicSvg name="icon6" className="Checked w-4 h-4" />
                    Remind
                  </button>
                )}
                {data.status !== "completed" && data.status !== "canceled" && (
                  <button
                    className="py-1 flex items-center gap-1"
                    onClick={() => setCancelModalVisibility(true)}
                  >
                    <DynamicSvg name="icon2" className="Checked w-4 h-4" />
                    Cancel
                  </button>
                )}
                { data.type !== "template" && (
                  <button
                    onClick={downloadPdf}
                    className="py-1 flex items-center gap-1"
                  >
                    <DynamicSvg name="icon3" className="Checked w-4 h-4" />
                    Download PDF
                  </button>
                )}
                {data.type !== "template" && data.type !== "webform" && (
                  <button
                    className="py-1 flex items-center gap-1"
                    onClick={handleFetchLogs}
                  >
                    <DynamicSvg name="icon4" className="Checked w-4 h-4" />
                    Download Audit Report
                  </button>
                )}
              </ul>
            </div>
            <div className="w-[50%] mt-5">
              <ul className="text-gray-400 cursor-pointer">
                {data.type !== "template" && (
                  <button
                    className="py-1 flex items-center gap-1"
                    onClick={() => setModalVisibility(true)}
                  >
                    <DynamicSvg name="icon16" className="Checked w-4 h-4" />
                    Add Notes
                  </button>
                )}
                <button
                  className="py-1 flex items-center gap-1"
                  onClick={() => setDonwloadSingleFile(true)}
                >
                  <DynamicSvg name="icon17" className="Checked w-4 h-4" />
                  Download Individual Files ({data.files.length})
                </button>
              </ul>
            </div>
          </div>
        </div>
      )}{" "}
      <DeclineNNote
        isOpen={modalVisibility}
        label="Leave a note"
        onClose={() => setModalVisibility(false)}
        onSubmit={handleSubmitNote}
        initialValues={data.note}
      />
      <CancelAgreementModal
        isOpen={cancelModalVisibility}
        label="Cancel document"
        labelParagraph="Are you sure you want to cancel this document?"
        onClose={() => setCancelModalVisibility(false)}
        onSubmit={handleCancelDocument}
      />
      <PdfFilesModal
        label="File List"
        labelParagraph="Select a file to download"
        files={data.files}
        isOpen={donwloadSingleFile}
        onSubmit={() => setDonwloadSingleFile(false)}
        onClose={() => setDonwloadSingleFile(false)}
        onFileClick={handleFileClick}
      />
    </>
  );
}
