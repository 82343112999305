
import React from "react";
import SignatureCustomize from "./SignatureCustomize";
import HyperlinkCustomize from "./HyperlinkCustomize";
import StampCustomize from "./StampCustomize";
import InfoFieldsCustomize from "./InfoFieldsCustomize";
import TextInputFieldCustomize from "./TextInputFieldCustomize";
import DropdownFieldCustomize from "./DropdownFieldCustomize";
import CheckboxFieldCustomize from "./CheckboxFieldCustomize";
import RadioFieldCustomize from "./RadioFieldCustomize";
import ImageFieldCustomize from "./ImageFieldCustomize";
import FileAttachmentCustomize from "./FileAttachmentCustomize";

const ElementCustomize = ({ index, element }) => {
  const props = { index, element };
  const getElement = (index, element) => {
    switch(element.type) {
      case "signature":
      case "signatureBlock":
      case "initials": return <SignatureCustomize {...props} /> 
      case "stamp": return <StampCustomize {...props} /> 
      case "hyperlink": return <HyperlinkCustomize {...props} />

      case "title":
      case "company":
      case "name":
      case "email":
      case "date": return <InfoFieldsCustomize {...props} />
      case "textInput": return <TextInputFieldCustomize {...props} />
      case "dropdown": return <DropdownFieldCustomize {...props} />
      case "checkbox": return <CheckboxFieldCustomize {...props} />
      case "radio": return <RadioFieldCustomize {...props} />
      case "fileAttachment": return <FileAttachmentCustomize {...props} />
      case "image": return <ImageFieldCustomize {...props} />
    }
  } 

  return (
    <div 
      onMouseDown={(e) => e.stopPropagation()}
      className="absolute top-[0] right-[calc(100%+5px)] element-actions bg-white rounded-lg cursor-default" 
    >
      <div className="border-1 border-gray-300 px-4 py-2 bg-white flex flex-col rounded-lg w-[250px]">
        {getElement(index, element)}
      </div>
    </div>
  );
};

export default ElementCustomize;
