import React from "react";
import { ReactComponent as Agreement } from "../../assets/Agreement.svg";
import { ReactComponent as Cancel } from "../../assets/Cancel.svg";
import { ReactComponent as Download } from "../../assets/Download.svg";
import { ReactComponent as DownloadAudit } from "../../assets/DownloadAudit.svg";
import { ReactComponent as Group } from "../../assets/Group.svg";
import { ReactComponent as Reminder } from "../../assets/Reminder.svg";
import { ReactComponent as Check } from "../../assets/check.svg";
import { ReactComponent as FilterIcon } from "../../assets/filterIcon.svg";
import { ReactComponent as DownArrow } from "../../assets/DownArrow.svg";
import { ReactComponent as Pen } from "../../assets/pen.svg";
import { ReactComponent as ActionOthers } from "../../assets/ActionOthers.svg";
import { ReactComponent as NoVisibility } from "../../assets/NoVisibility.svg";
import { ReactComponent as Share } from "../../assets/Share.svg";
import { ReactComponent as AddNotes } from "../../assets/AddNotes.svg";
import { ReactComponent as DownloadIndividualFiles } from "../../assets/DownloadIndividualFiles.svg";
import { ReactComponent as UploadSignedDocument } from "../../assets/UploadSignedDocument.svg";
import { ReactComponent as Checked } from "../../assets/Checked.svg";
import { ReactComponent as SmallDownArrow } from "../../assets/SmallDownArrow.svg";
import { ReactComponent as Finder } from "../../assets/Finder.svg";
import { ReactComponent as SquareArrowUp } from "../../assets/squareArrowUp.svg";
import { ReactComponent as AddNote } from "../../assets/addNote.svg";
import { ReactComponent as ReminderBig } from "../../assets/ReminderBig.svg";
import { ReactComponent as UpArrow } from "../../assets/upArrow.svg";
import { ReactComponent as Search } from "../../assets/search-lg.svg";
import { ReactComponent as SmallerDropDown } from "../../assets/SmallerDropDown.svg";
import { ReactComponent as GrayCheck } from "../../assets/GrayCheck.svg";
import { ReactComponent as DocumentWithPen } from "../../assets/DocumentWithPen.svg";
import { ReactComponent as Aa } from "../../assets/firstSignAssets/aa.svg";
import { ReactComponent as BulletPoint } from "../../assets/firstSignAssets/bullet.svg";
import { ReactComponent as PenSign } from "../../assets/firstSignAssets/penSign.svg";
import { ReactComponent as Tick } from "../../assets/firstSignAssets/tick.svg";
import { ReactComponent as X } from "../../assets/firstSignAssets/x.svg";
import { ReactComponent as Keyboard } from "../../assets/firstSignAssets/keyboard.svg";
import { ReactComponent as ImageImport } from "../../assets/firstSignAssets/image.svg";
import { ReactComponent as SignPenModal } from "../../assets/firstSignAssets/sign.svg";


const DynamicSvg = ({ name, ...props }) => {
  const svgComponents = {
    icon1: Agreement,
    icon2: Cancel,
    icon3: Download,
    icon4: DownloadAudit,
    icon5: Group,
    icon6: Reminder,
    icon9: Check,
    icon10: FilterIcon,
    icon11: DownArrow,
    icon12: Pen,
    icon13: ActionOthers,
    icon14: NoVisibility,
    icon15: Share,
    icon16: AddNotes,
    icon17: DownloadIndividualFiles,
    icon18: UploadSignedDocument,
    icon19: Checked,
    icon20: SmallDownArrow,
    icon21: Finder,
    icon22: SquareArrowUp,
    icon23: AddNote,
    icon24: ReminderBig,
    icon25: UpArrow,
    icon26: Search,
    icon27: SmallerDropDown,
    icon28: GrayCheck,
    icon29: DocumentWithPen,
    icon30: Aa,
    icon31: BulletPoint,
    icon32: PenSign,
    icon33: Tick,
    icon34: X,
    keyboard: Keyboard,
    imageImport : ImageImport,
    signPenModal : SignPenModal,

  };

  const SvgComponent = svgComponents[name] || null;

  return SvgComponent ? <SvgComponent {...props} /> : null;
};

export default DynamicSvg;
