
import React from "react";
import Button from "../../../Buttons/Button"

const ElementFooter = ({ deleteElement, updateElement, cancelCustomize }) => {
  return (
    <>
      <div className="mb-2">
        <hr className="my-2 mt-0.5 w-2/3" />
        <Button 
          title="Delete Field" 
          onClick={deleteElement} 
          classes="!p-0 text-[12px] bg-transparent border-0 !text-[#3CA970] hover:bg-transparent" 
        />
      </div>
      <div className="flex gap-1">
        <Button title="Ok" classes="text-[10px] !py-1.5" onClick={updateElement} />
        <Button 
          title="Cancel" 
          onClick={cancelCustomize} 
          classes="text-[10px] bg-white !text-black border-black hover:bg-transparent !py-1.5" 
        />
      </div>
    </>
  );
};

export default ElementFooter;
