import { configureStore } from "@reduxjs/toolkit";
import signReducer from "./sign.slice.js";
import authReducer from "./auth.slice.js";
import appSlice from "./app.slice.js";

export const store = configureStore({
  reducer: {
    app: appSlice,
    sign: signReducer,
    auth: authReducer
  }
});