import React from "react";
import ManageSidebar from "./ManageSidebar";
import ManageMainContent from "./ManageMainContent";

export default function ManagePageLayout() {
  return (
    <div className="container flex h-screen pt-8">
      <ManageSidebar />
      <ManageMainContent />
    </div>
  );
}
