import React from "react";
import { FileImport } from "../../components";
import { Formik } from "formik";
import * as Yup from 'yup';
import { checkIfFilesAreCorrectType, checkIfFilesAreTooBig } from "../../utils/functions";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Input from "../../components/Inputs/Input";
import Button from "../../components/Buttons/Button";
import api from "../../utils/api";
import _ from "lodash";
import toast from "react-hot-toast";

export default function FillAndSignForm() {
  const navigate = useNavigate();
  const { user } = useSelector(state => state.auth);

  const AgreementSchema = Yup.object().shape({
    agreementName: Yup.string()
      .min(2, 'Document name too short!')
      .max(50, 'Document name to long!')
      .required('Document name is required!'),
    files: Yup.array()
      .min(1, "At least one file is required!")
      .required("At least one file is required!")
      .test('is-big-file', 'PDF file size is to big.', checkIfFilesAreTooBig)
      .test(
        'is-correct-file',
        'Only PDF formats are accepted.',
        checkIfFilesAreCorrectType
      )
  });

  const handleSubmit = async (values) => {
    try {
      const formData = new FormData();
      values.files.forEach(file => formData.append("files", file));
      formData.append("data", JSON.stringify(_.omit(values, 'files')));
      const { data } = await api.post("/document", formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      navigate(`/fill-sign/${data._id}`);
    }catch(error){
      toast.error("Unable to create the document. Please try again.");
    }
  }

  return (
    <div className="sendcontainer">
      <div className="py-4 mb-4">
        <h1 className="text-[30px]"> 
          Upload or choose files to sign and fill out yourself.
        </h1>
        <p className="text-sm text-gray-500">
          After signing, you will be able to save, download or send them to
          others.
        </p>
      </div>
      <div className="mt-12">
        <Formik
          initialValues={{ 
            type: "fill",
            files: [],
            recipients: [{
              type: "signer",
              email: user.email
            }],
            agreementName: ""
          }}
          validationSchema={AgreementSchema}
          onSubmit={handleSubmit}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleSubmit,
            setFieldValue
          }) => (
            <form onSubmit={handleSubmit}>
              <FileImport 
                error={errors.files && touched.files?errors.files:""}
                files={values.files} 
                setFiles={setFieldValue} 
              />
              <div className="mt-4">
                <h1 className="text-[25px]">Document name</h1>
                <Input 
                  type="text" 
                  id="agreementName"
                  name="agreementName"
                  placeholder="Enter Document Name" 
                />
                <div className="flex justify-center mt-10">
                  <Button 
                    type="submit"
                    title="Next"
                    classes="px-4"
                  />
                </div>
              </div>
            </form>
          )}
        </Formik>
      </div>
    </div>
  );
}
