import React from "react";
import useElementCustomize from "../../../hooks/useElementCustomize";
import { MdOpenInNew } from "react-icons/md";
import { Link } from "react-router-dom"
import UpdateRecipient from "./atoms/UpdateRecipient";
import ElementName from "./atoms/ElementName";
import ElementAppearance from "./atoms/ElementAppearance";
import ElementFooter from "./atoms/ElementFooter";

const HyperlinkCustomize = ({ index, element }) => {
  const {
    updateElement, 
    deleteElement, 
    cancelCustomize,
    updateElementFields 
  } = useElementCustomize(index, element);

  const onInputChange = (event, key) => {
    const { id, value } = event.target;
    updateElementFields(`${key}.${id}`, value);
  }
  return (
    <>
      <ElementName 
        element={element}
        updateElementFields={updateElementFields}
      />

      <UpdateRecipient 
        element={element} 
        updateElementFields={updateElementFields} 
      />

      <div className="my-2">
        <label htmlFor="website" className="block mb-1 text-xs font-normal text-gray-900">Website</label>
        <div className="relative">
          <Link to={element.responses[0]?.href} className="cursor-pointer absolute inset-y-0 end-0 flex items-center pe-2 pointer-events-none">
            <MdOpenInNew size={13} />
          </Link>
          <input 
            id="href" 
            type="text" 
            value={element.value.href}
            onChange={(e) => onInputChange(e, "value")}
            placeholder="https://example.com" 
            className="bg-white border border-gray-400 text-gray-900 text-[12px] rounded-lg block w-full p-1 pe-7" 
          />
        </div>
      </div>
      
      <div className="my-2">
        <label htmlFor="website" className="block mb-1 text-xs font-normal text-gray-900">Page within document</label>
        <div className="relative">
          <Link to={element.responses[0]?.href} className="absolute inset-y-0 end-0 flex items-center pe-2 pointer-events-none">
            <MdOpenInNew size={13} />
          </Link>
          <input 
            type="number" 
            id="page" 
            value={element.value.page}
            onChange={(e) => onInputChange(e, "value")}
            placeholder="ex. 1" 
            className="bg-white border border-gray-400 text-gray-900 text-[12px] rounded-lg block w-full p-1 pe-7" 
          />
        </div>
      </div>

      <div className="my-2">
        <label htmlFor="label" className="block mb-1 text-xs font-normal text-gray-900">Display Label</label>
        <div className="relative">
          <input 
            id="label" 
            type="text" 
            onChange={(e) => onInputChange(e, "value")}
            value={element.value.label}
            className="bg-white border border-gray-400 text-gray-900 text-[12px] rounded-lg block w-full p-1" 
          />
        </div>
      </div>

      <ElementAppearance 
        element={element}
        updateElementFields={updateElementFields}
      />

      <ElementFooter 
        deleteElement={deleteElement}
        updateElement={updateElement}
        cancelCustomize={cancelCustomize}
      />
    </>
  );
};

export default HyperlinkCustomize;
