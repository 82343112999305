import { useSelector } from "react-redux";
import styles from "./loader.module.css";

const Loader = ({ loading=false }) => {
  const { loader } = useSelector(state => state.app)

  return (loader || loading) && (
    <div className={styles.loaderContainer}>
      <p>{loader?.message || "Loading..."}</p>
      <span className={styles.loader}></span>
    </div>
  );
};

export default Loader;