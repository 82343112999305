import React from "react";

const Checkbox = ({ id, value="", checked, label, onChange, labelClass="", inputClass="" }) => {
  return (
    <div className="flex items-center me-4">
      <input 
        id={id}
        name={id}
        value={value}
        type="checkbox" 
        checked={checked}
        onChange={onChange}
        className={`w-3 h-3 text-blue-600 bg-gray-100 border-gray-100 rounded ${inputClass}`}
      />
      <label htmlFor={id} className={`ms-1.5 text-xs font-light text-gray-400 ${labelClass}`}>{label}</label>
    </div>
  );
};

export default Checkbox;
