import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { FcDocument } from "react-icons/fc";
import { Link } from "react-router-dom";
import api from "../../utils/api";

export default function StartFromLibraryModal({handleCloseLibraryModal}) {
  const [documents, setDocuments] = useState(null);

  async function fetchCompletedDocuments() {
    try {
      const response = await api.get(`/document?type=template&status=completed`);
      setDocuments(response.data);
    } catch (error) {
      toast.error("Unable to fetch templates at the moment. Please try again.");
    }
  }

  useEffect(() => {
    fetchCompletedDocuments();
  }, []);

  return (
    <div className="fixed top-0 left-0 w-full h-full flex justify-center items-center bg-gray-800 bg-opacity-50 z-50">
      <div className="bg-white w-[40%] h-[460px] flex flex-col rounded-sm">
        <p className="border-b-1 p-4 font-semibold">Start from library</p>
        <div className="h-full relative flex">
          <div className="w-[25%] border-r-1">
            <ul className="flex flex-col h-[100%]">
              <li className="border-b-1 px-4 py-2 cursor-pointer text-sm">
                Library
              </li>
              <li className="border-b-1 px-4 py-2 cursor-pointer bg-green text-white text-sm">
                Templates
              </li>
            </ul>
          </div>
          <div className="relative w-[75%] h-full">
            <ul className="relative">
              {documents && documents.length > 0 ? documents.map((template, index) => (
                <li key={index} className="border-b-1 cursor-pointer text-gray-400 text-xs">
                  <Link to={`/send?templateId=${template._id}`} className="px-4 py-2 flex items-center gap-2">
                    <FcDocument /> {template.agreementName}
                  </Link>
                </li>
              )): (
                <li className="border-b-1 cursor-pointer text-gray-400 text-xs">
                  {!documents ? <span className="px-4 py-2 flex justify-center">Loading...</span>: (
                    <Link to="/template" className="px-4 py-2 flex justify-center">
                      No templates to display,<span className="text-green-500"> click here to create one.</span>
                    </Link>
                  )}
                </li>
              )}
            </ul>

            <div className="absolute bottom-0 p-4 border-t-1 w-full flex justify-end text-xs">
              <button
                className="border border-black text-black ml-4 py-2 px-4 rounded-lg cursor-pointer hover-bg-green-hover transition"
                onClick={handleCloseLibraryModal}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
