import React, { useState } from "react";
import Dropdown from "./Dropdown";
import styles from "./documentesign.module.css"
import Button from "../Buttons/Button";
import { BsChevronDown } from "react-icons/bs"

const DocumentESign = ({ title, children, isDisabled }) => {
  const [isVisible, setVisibility] = useState()
  
  return (
    <Dropdown setVisibility={setVisibility}>
      <Button 
        title={title}
        isDisabled={isDisabled}
        onClick={()=>setVisibility(!isVisible)}
        classes="bg-transparent border-0 !text-black hover:bg-transparent w-full"
        endIcon={<BsChevronDown color="#000" className="ml-3" style = {{ transform: `rotate(${isVisible?180:0}deg)` }} />}
      />
      {isVisible && (
        <div className={`${styles.tooltip} ${styles.tooltipArrow}`} onClick={() => setVisibility(false)}>
          <div className="py-3">
            {children ? children : (
              <p className="px-3 text-sm">No elements found.</p>
            )}
          </div>
        </div>
      )}
    </Dropdown>
  );
};

export default DocumentESign;
